import React, {useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";

import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpDown } from "@fortawesome/free-solid-svg-icons";
import * as api from "../../config/api"
function Unit() {
    //유저정보
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();

    const [category, setCategory] = useState(0);
    const [inputValue, setValue] = useState();
    const [list, setList] = useState([])
    const [unit_list, setUnitList] = useState([])
    const dragItem = useRef(); // 드래그할 아이템의 인덱스
    const dragOverItem = useRef(); // 드랍할 위치의 아이템의 인덱스


    // 드래그 시작될 때 실행
    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    // 드래그중인 대상이 위로 포개졌을 때
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    // 드랍 (커서 뗐을 때)
    const drop = (val,seq) => {
        const startSort = dragOverItem.current;
        const endSort = dragItem.current;
        const newList = [...list];
        const dragItemValue = newList[dragItem.current];
        newList.splice(dragItem.current, 1);       
        newList.splice(dragOverItem.current, 0, dragItemValue);
        dragItem.current = null;
        dragOverItem.current = null;
        
        if(startSort != endSort){
            setList(
                newList.map(val =>
                    val.unitLevelSeq === seq ? { ...val, seq: "move" } : val
                )
            );
            //드래그 순간 name이 같아져서 셀렉트 박스 체크 풀림 이동시 move처리 놓는 순간 다시 자기 번호로 변경
            setTimeout(()=>{
                setList(
                    newList.map(val =>
                        val.unitLevelSeq === seq ? { ...val, unitLevelSeq: seq } : val
                    )
                );
                
                api.putData("/admin/dictionary/unit/levelSort", {},{
                    ssort:startSort,
                    esort:endSort,
                    id:val.unitLevelSeq,
                    sort:(startSort+1)
                },user.token).then(res=>{
                    if(res){

                    }
                });
            },100)
        }
    };




    const onCategory = (e) => {
        setCategory(e);
        onList(e);
    }

    const onList = (e) => {
        api.getData("/admin/dictionary/unit/level", {
            id:e
        },user.token).then(res=>{
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data);
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });
    }


    useEffect(() => {
        api.getData("/dictionary/unit", {
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            setUnitList(res.data.data);
        });
    }, []);

    const changeValue = (e,seq) => {
        setList(
            list.map(val =>
                val.unitLevelSeq === seq ? { ...val, name: e.target.value } : val
            )
        );
    }
    const onSave = (category) => {

        api.postData("/admin/dictionary/unit/level", {
            name:inputValue,
            unitSeq:category,
            sort:(list.length+1)
        },user.token).then(res=>{
            setList([...list,res.data.data]);
            setValue("");
        });
    }
    const onModify = (val,seq) => {
        api.putData("/admin/dictionary/unit/level", {
            name:val.name,
            sort:val.sort
        },{
            id:seq
        },user.token).then(res=>{
            if(res.data.status == "SUCCESS"){
                window.alert("수정되었습니다.");
            }
        });        
    }
    const onRemove = (seq) => {
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/dictionary/unit/level", {
                id:seq
            },user.token).then(res=>{
                if(res.data.status == "SUCCESS"){
                    setList(list.filter(val => val.unitLevelSeq !== seq));
                    window.alert("삭제되었습니다.");
                }    
                
            });
        }        
    }

    return (
        <div>
            <h1 className="head">수업고민사전 단원 관리</h1>

            <div className="tab_box">
                <div className="tab_category dragndrop">                   
                    <dl>
                        <dt>
                            <select onChange={(e)=>onCategory(e.target.value)} value={category}>
                                <option value="">카테고리</option>
                                {unit_list.map((val, index) => (
                                    <option value={val.unitSeq}>{val.name}</option>
                                ))}
                                
                            </select>
                        </dt>
                        {list.map((val, index) => (
                        <dd 
                            className="show"
                            key={index}
                            draggable
                            onDragStart={(e) => dragStart(e, index,val.unitLevelSeq)}
                            onDragEnter={(e) => dragEnter(e, index,val.unitLevelSeq)}
                            onDragEnd={(e) => drop(val, val.categorySeq)}

                            onDragOver={(e) => e.preventDefault()}
                        >
                            <i><FontAwesomeIcon icon={faUpDown} /></i>
                            <div>                       
                                <input type="text" value={val.name} onChange={(e)=>changeValue(e,val.unitLevelSeq)}/>
                                <button type="button" className="btn-info" onClick={()=>onModify(val,val.unitLevelSeq)}>수정</button>       
                                <button type="button" className={!val.del ? "btn-danger" : "btn-warning"} onClick={()=>onRemove(val.unitLevelSeq)}>{val.del ? '취소' : '삭제'}</button>       
                            </div>
                        </dd>
                        ))}
                        <dd className="show">
                            <i className="bean"></i>
                            <div>
                            <input type="text" placeholder="단원명을 입력해주세요." value={inputValue} onChange={(e)=>setValue(e.target.value)} />
                            <button type="button" className="btn-primary" onClick={()=>onSave(category)}>저장</button>
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    );
}

export default Unit;