import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import * as api from "../../config/api"
import Pagination from 'react-js-pagination';
import FileAdd from "../../component/FileAdd";

function DictionaryForm() {   
    //유저정보
    const user = useSelector((state) => state.user);
    const location = useLocation();
    const navigate = useNavigate();

    const [unit_list, setUnitList] = useState([]);
    const [unit_level_list, setUnitLevelList] = useState([]);
    const [unit_add, setUnitAdd] = useState([]);
    const [unit, setUnit] = useState(0);
    const [unit_level, setUnitLevel] = useState(0);


    //상세
    const [form_contents, setFormContents] = useState({view:1,title:"",type:"말하기/듣기"});
    
    //url파라미터
    const { id } = useParams();


    const params = new URLSearchParams(location.search);
    const goList = () => {
        navigate("/class/dictionary" , {state:{page:params.get("page")}}); 
    }

    const onRemove = () =>{
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/dictionary",{
                id:id
            },user.token).then(res=>{
                if(res.data.status == "SUCCESS"){
                    goList();
                }
            });
        }
    }

    const onChangeUnit = (e) => {
        setUnit(e.target.value);
        setUnitLevel("");
    }
    const onChangeUnitLevel = (e) => {
        setUnitLevel(e.target.value);
    }
    const onUnitAdd = (e) => {
        const unitName = unit_list.find(par => par.unitSeq == unit )?.name
        const unitLevelName = unit_level_list.find(par => par.unitLevelSeq == unit_level )?.name

        setUnitAdd([...unit_add, {unitSeq:unit,unitName:unitName, unitLevelSeq:unit_level, unitLevelName:unitLevelName}])
    }
    const onUnitRemove = (e) => {

        setUnitAdd(unit_add.filter(val => e.unitLevelSeq !== val.unitLevelSeq));
    }
    

    const  onSubmit = (preview) => {
        let saveurl = "/admin/dictionary";
        //if(preview){
        //    saveurl = "/admin/dictionary/preview";
        //}
        const formData =  new FormData();
        if(!form_contents?.name){
            window.alert("용어명을 입력하세요.");
            return;
        }
        if(form_contents.dictionarySeq > 0){
            formData.append("dictionarySeq",form_contents.dictionarySeq);
        }
        if(id == 0 ){
            const dd = new Date();
            formData.append("registDate",
            dd.getFullYear()+"-"+
            ("0" + (dd.getMonth() + 1)).slice(-2)+"-"+
            ("0" + dd.getDate()).slice(-2) +" " + 
            ("0" + dd.getHours()).slice(-2)+":"+
            ("0" + dd.getMinutes()).slice(-2)+":"+
            ("0" + dd.getSeconds()).slice(-2));
        } else {
            formData.append("registDate",form_contents.registDate);
        }
        formData.append("name",form_contents.name);
        formData.append("unitJson",JSON.stringify(unit_add));
        formData.append("view",form_contents.view);
        formData.append("type",form_contents.type);  
        formData.append("file",form_contents.file);

        formData.append("subject",form_contents.subject);
        formData.append("subject0",(form_contents.subject0 ? 1 : 0));
        formData.append("subject1",(form_contents.subject1 ? 1 : 0));
        formData.append("subject2",(form_contents.subject2 ? 1 : 0));
        formData.append("subject3",(form_contents.subject3 ? 1 : 0));
        formData.append("subject4",(form_contents.subject4 ? 1 : 0));
        formData.append("subject5",(form_contents.subject5 ? 1 : 0));
        formData.append("subject6",(form_contents.subject6 ? 1 : 0));
        formData.append("type0",(form_contents.type0 ? 1 : 0));
        formData.append("type1",(form_contents.type1 ? 1 : 0));
        formData.append("type2",(form_contents.type2 ? 1 : 0));
        formData.append("type3",(form_contents.type3 ? 1 : 0));
        formData.append("type4",(form_contents.type4 ? 1 : 0));


        api.fileUpload(saveurl,formData,user.token).then(res=>{
            goList();
        });
    }
    
    useEffect(() => {
        setFormContents(location?.state);
    

        if( location?.state?.unitJson ){
            setUnitAdd(JSON.parse(location?.state?.unitJson))            
        }
    
        api.getData("/dictionary/unit/level", {
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            setUnitLevelList(res.data.data);
        });
        api.getData("/dictionary/unit", {
        },user.token).then(res=>{
            setUnitList(res.data.data);
        });
    }, [id]);
    const onClassCheck = (cl,chk) => {
        setFormContents({...form_contents, [cl]:(chk ? 1 : 0)});
    }
    return (
        <div className="normal">
            <div >
                <h1 className="head">수업고민사전 상세보기
                    {id > 0 && <button type="text" className="btn-danger btn-small" onClick={(e)=>onRemove()}>삭제하기</button>}
                    {/*<button type="text" className="btn-warning btn-small" onClick={(e)=>onSubmit(1)}>미리보기</button>*/}
                </h1>
                <table className="detail">
                    <colgroup>
                        <col width="15%"/>
                        <col width="85%"/>
                    </colgroup>
                    <tbody>
                        {form_contents?.dictionarySeq > 0 && 
                        <tr>
                            <th>등록일시</th>
                            <td>{form_contents?.registDate?.substr(0,16)}</td>
                        </tr>
                        }
                        <tr>
                            <th>용어명</th>
                            <td><input type="text" placeholder="제목을 입력하세요"  value={form_contents?.name} onChange={(e)=>setFormContents({...form_contents, name:e.target.value})}/></td>
                        </tr>
                        <tr>
                            <th>교과선택</th>
                            <td>
                            <label className="radiobox"><input type="radio" name="subject" onClick={(e)=>setFormContents({...form_contents, subject:"국어"})} checked={form_contents?.subject=='국어'} /><span>국어</span></label>
                            <label className="radiobox"><input type="radio" name="subject" onClick={(e)=>setFormContents({...form_contents, subject:"사회"})} checked={form_contents?.subject=='사회'} /><span>사회</span></label>
                            <label className="radiobox"><input type="radio" name="subject" onClick={(e)=>setFormContents({...form_contents, subject:"미술"})} checked={form_contents?.subject=='미술'} /><span>미술</span></label>
                            </td>
                        </tr>
                        <tr>
                            <th>과목선택</th>
                            <td>
                                {form_contents?.subject === '국어' ? 
                                <>
                                <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('subject0',!form_contents?.subject0)} checked={form_contents?.subject0} /><span>중학교1</span></label>
                                <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('subject1',!form_contents?.subject1)} checked={form_contents?.subject1} /><span>중학교2</span></label>
                                <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('subject2',!form_contents?.subject2)} checked={form_contents?.subject2} /><span>중학교3</span></label>
                                <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('subject3',!form_contents?.subject3)} checked={form_contents?.subject3} /><span>공통국어</span></label>
                                <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('subject4',!form_contents?.subject4)} checked={form_contents?.subject4} /><span>문학</span></label>
                                <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('subject5',!form_contents?.subject5)} checked={form_contents?.subject5} /><span>독서와 작문</span></label>
                                <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('subject6',!form_contents?.subject6)} checked={form_contents?.subject6} /><span>화법과 언어</span></label>
                                </>
                                : form_contents?.subject === '사회' ? 
                                <>
                                    <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('subject0',!form_contents?.subject0)} checked={form_contents?.subject0} /><span>통합사회</span></label>
                                    <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('subject1',!form_contents?.subject1)} checked={form_contents?.subject1} /><span>사회와 문화</span></label>
                                </>
                                :
                                <>
                                </>
                                }
                            </td>
                        </tr>

                        {/*
                        <tr>
                            <th>단원</th>
                            <td>
                                <div className="flex">
                                    <select onChange={(e)=>onChangeUnit(e)}>
                                        <option value="">카테고리</option>
                                        {unit_list.map((val, index) => (
                                            <option value={val.unitSeq}>{val.name}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e)=>onChangeUnitLevel(e)}>
                                        <option value="">단원 선택</option>
                                        {unit_level_list.map((val, index) => (
                                            val.unitSeq == unit && (
                                                <option value={val.unitLevelSeq}>{val.name}</option>
                                            )
                                        ))}
                                    </select>
     
                                    
                                    {unit > 0 && (
                                    <button type="button" className="btn-success" onClick={(e)=>onUnitAdd(e)}>추가</button>
                                    )}
                                </div>
                                <ul className="categorybox">
                                    {unit_add.map((val, index) => (
                                    <li>{val.unitName} &gt; {val.unitLevelName} <button type="button" onClick={(e)=>onUnitRemove(val)}>삭제</button></li>
                                    ))}

                                </ul>
                            </td>
                        </tr>
                        */}
                        <tr>
                            <th>영역</th>
                            <td>
                                {form_contents?.subject === '국어' ? 
                                <>                                
                                    <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('type0',!form_contents?.type0)} checked={form_contents?.type0} /><span>문학</span></label>
                                    <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('type1',!form_contents?.type1)} checked={form_contents?.type1} /><span>문법</span></label>
                                    <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('type2',!form_contents?.type2)} checked={form_contents?.type2} /><span>듣기·말하기</span></label>
                                    <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('type3',!form_contents?.type3)} checked={form_contents?.type3} /><span>읽기·쓰기</span></label>
                                    <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('type4',!form_contents?.type4)} checked={form_contents?.type4} /><span>매체</span></label>
                                </>
                                : form_contents?.subject === '사회' ? 
                                <>
                                    <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('type0',!form_contents?.type0)} checked={form_contents?.type0} /><span>일반사회</span></label>
                                    <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('type1',!form_contents?.type1)} checked={form_contents?.type1} /><span>윤리</span></label>
                                    <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('type2',!form_contents?.type2)} checked={form_contents?.type2} /><span>지리</span></label>
                                </>
                                : form_contents?.subject === '미술' ? 
                                <>
                                    <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('type0',!form_contents?.type0)} checked={form_contents?.type0} /><span>인물</span></label>
                                    <label className="checkbox"><input type="checkbox" onChange={(e)=>onClassCheck('type1',!form_contents?.type1)} checked={form_contents?.type1} /><span>용어</span></label>
                                </>
                                :<></>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>첨부파일</th>
                            <td>
                                <FileAdd  form_file={form_contents?.addFile  } setFormFile={(e)=>setFormContents({...form_contents,file:e})} form_orifile={form_contents?.addFileOri } id={id ? id : -1}/>
                            </td>
                        </tr>
                        <tr>
                            <th>게재여부</th>
                            <td>
                                <label className="radiobox"><input type="radio" onClick={(e)=>setFormContents({...form_contents, view:1})} checked={form_contents?.view==1}/><span>노출</span></label>
                                <label className="radiobox"><input type="radio" onClick={(e)=>setFormContents({...form_contents, view:0})} checked={form_contents?.view==0}/><span>비노출</span></label>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2">
                                <button type="button" className="btn-primary" onClick={(e)=>onSubmit(0)}>저장</button>
                                <button type="button" className="btn-success" onClick={(e)=>goList()}>목록</button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

export default DictionaryForm;