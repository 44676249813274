import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import Editor from "../../component/Editor";
import ImageAdd from "../../component/ImageAdd";
import FileAdd from "../../component/FileAdd";
import * as api from "../../config/api"
import Pagination from 'react-js-pagination';
import { navi } from '../../component/Config';
import EditorCk from '../../component/EditorCk';

function MonthForm() {
    const { id } = useParams();
    //유저정보
    const user = useSelector((state) => state.user);
    const location = useLocation();
    const navigate = useNavigate();

    //상세
    const [form_contents, setFormContents] = useState({subject:"국어",school:"중학",viewMain:1,view:1,month:1});
    //에디터
    const [editor_contents, setEditorContents] = useState("");


    const  onSubmit = (preview) => {
        let saveurl="/admin/innovation";
        const formData =  new FormData();
        if(preview){
            saveurl="/admin/innovation/preview";
        }

        if(id > 0){
            formData.append("innovationSeq",id);
        }

        if(id == 0 ){
            const dd = new Date();
            formData.append("registDate",
            dd.getFullYear()+"-"+
            ("0" + (dd.getMonth() + 1)).slice(-2)+"-"+
            ("0" + dd.getDate()).slice(-2) +" " + 
            ("0" + dd.getHours()).slice(-2)+":"+
            ("0" + dd.getMinutes()).slice(-2)+":"+
            ("0" + dd.getSeconds()).slice(-2));
        }

        formData.append("title",form_contents.title);
        formData.append("subject",form_contents.subject);
        formData.append("school",form_contents.school);
        formData.append("month",form_contents.month);
        formData.append("view",form_contents.view);
        formData.append("viewMain",form_contents.viewMain);
        formData.append("contents",editor_contents);
   

        formData.append("image",form_contents.image);
        formData.append("thumbnail",form_contents.thumbnail);
        if(form_contents.pdf1 == ''){
            formData.append("filePdf1",form_contents.pdf1);
        } else {
            formData.append("pdf1",form_contents.pdf1);
        }
        if(form_contents.hwp1 == ''){
            formData.append("fileHwp1",form_contents.hwp1);
        } else {
            formData.append("hwp1",form_contents.hwp1);
        }            
        if(form_contents.hwp2 == ''){
            formData.append("fileHwp2",form_contents.hwp2);
        } else {
            formData.append("hwp2",form_contents.hwp2);
        }
        
        if(form_contents.pdf2 == ''){
            formData.append("filePdf2",form_contents.pdf2);
        } else {
            formData.append("pdf2",form_contents.pdf2);
        }
        
        if(form_contents.zip3 == ''){
            formData.append("fileZip3",form_contents.zip3);
        } else {
            formData.append("zip3",form_contents.zip3);
        }

        api.fileUpload(saveurl,formData,user.token).then(res=>{
            if(preview){
                console.log(res.data.data);
                const re = res.data.data;
                let link = "0";
                if(re.subject==="사회"){
                    link = "1";
                }else if(re.subject==="미술"){
                    link = "2";
                }
                window.open(api.front + "/preview/calendar/"+link+"/"+re.innovationSeq,"","");
            }else{
                goList();
            }
        });
    }
    
    const params = new URLSearchParams(location.search);
    const goList = () => {
        navigate("/class/month" , {state:{page:params.get("page")}}); 
    }
    const onRemove = () =>{
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/innovation",{
                id:id
            },user.token).then(res=>{
                if(res.data.status == "SUCCESS"){
                    goList();
                }
            });
        }
    }
    useEffect(() => {
        
        
        if(!location?.state?.innovationSeq && id > 0){
            window.alert("리스트에서 입장해야 상세정보가 나옵니다.");
            navigate("/class/month");           
        }

        
        if(id > 0){
            setFormContents(location?.state);
            setEditorContents(location?.state?.contents);
        } else {
            setFormContents({subject:"국어",school:"중학",viewMain:1,view:1,month:1,title:""});        
        }
    }, [id]);


    return (
        <div className="normal">
            <div>
                <h1 className="head">이달의 수업 상세보기
                    {id > 0 && <button type="text" className="btn-danger btn-small" onClick={(e)=>onRemove()}>삭제하기</button>}
                    <button type="text" className="btn-warning btn-small"  onClick={(e)=>onSubmit(1)}>미리보기</button>
                </h1>
                <form name="frm">
                    <table className="detail">
                        <colgroup>
                            <col width="15%"/>
                            <col width="85%"/>
                        </colgroup>
                        <tbody>
                            { id > 0 &&
                            <tr>
                                <th>등록일시</th>
                                <td>{form_contents?.registDate?.substr(0,16)}</td>
                            </tr>
                            }
                            <tr>
                                <th>제목</th>
                                <td><input type="text" placeholder="제목을 입력하세요" value={form_contents.title} onChange={(e)=>setFormContents({...form_contents, title:e.target.value})}/></td>
                            </tr>
                            <tr>
                                <th>과목</th>
                                <td>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setFormContents({...form_contents, subject:"국어"})} checked={form_contents.subject==="국어"}/><span>국어</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setFormContents({...form_contents, subject:"사회"})} checked={form_contents.subject==="사회"}/><span>사회</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setFormContents({...form_contents, subject:"미술"})} checked={form_contents.subject==="미술"} /><span>미술</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>학년</th>
                                <td>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setFormContents({...form_contents, school:"중학"})} checked={form_contents.school=="중학"}/><span>중학</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setFormContents({...form_contents, school:"고등"})} checked={form_contents.school=="고등"}/><span>고등</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>월</th>
                                <td className="flex">
                                    <select onChange={(e)=>setFormContents({...form_contents, month:e.target.value})} value={form_contents.month}>
                                        <option value="1">1월</option>
                                        <option value="2">2월</option>
                                        <option value="3">3월</option>
                                        <option value="4">4월</option>
                                        <option value="5">5월</option>
                                        <option value="6">6월</option>
                                        <option value="7">7월</option>
                                        <option value="8">8월</option>
                                        <option value="9">9월</option>
                                        <option value="10">10월</option>
                                        <option value="11">11월</option>
                                        <option value="12">12월</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>대표 이미지<br/><small>1000px * 400px</small></th>
                                <td>
                                    <ImageAdd folder="dictionary" form_thumbnail={form_contents?.fileImage} setFormThumbnail={(e)=>setFormContents({...form_contents, image:e})} form_orithumbnail={form_contents?.fileImageOri}  width={1000} height={400} resize={2} id={id ? id : -1}/>
                                </td>
                            </tr>
                            <tr>
                                <th>썸네일 이미지<br/><small>800px * 800px</small></th>
                                <td>
                                    <ImageAdd folder="dictionary" form_thumbnail={form_contents?.fileThumbnail} setFormThumbnail={(e)=>setFormContents({...form_contents, thumbnail:e})} form_orithumbnail={form_contents?.fileThumbnailOri}  width={800} height={800} resize={4} id={id ? id : -1}/> 
                                </td>
                            </tr>
                            <tr>
                                <th>메인노출</th>
                                <td>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setFormContents({...form_contents, viewMain:1})} checked={form_contents.viewMain==1}/><span>노출</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setFormContents({...form_contents, viewMain:0})} checked={form_contents.viewMain==0}/><span>비노출</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>게재여부</th>
                                <td>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setFormContents({...form_contents, view:1})} checked={form_contents.view==1} /><span>노출</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setFormContents({...form_contents, view:1})} checked={form_contents.view==0} /><span>비노출</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>상세내용</th>
                                <td>
                                    <Editor 
                                        placeholder="내용을 입력하세요."
                                        value={editor_contents}
                                        setFormContent={setEditorContents}
                                        folder="/board"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h1 className="head">수업 제안 자료</h1>
                    <table className="detail">
                        <colgroup>
                            <col width="15%"/>
                            <col width="28.33%"/>
                            <col width="28.33%"/>
                            <col width="28.33%"/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>제목</th>
                                <th>HWP</th>
                                <th>PDF</th>
                                <th>ZIP</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>1. 수업 지도안</th>
                                <td>
                                    <div className="filebox">
                                        <FileAdd form_file={form_contents.fileHwp1} setFormFile={(e)=>setFormContents({...form_contents,hwp1:e})} form_orifile={form_contents.fileHwpOri1} id={id ? id : -1}/>
                                    </div>
                                </td>
                                <td>
                                    <div className="filebox">
                                        <FileAdd form_file={form_contents.filePdf1} setFormFile={(e)=>setFormContents({...form_contents,pdf1:e})} form_orifile={form_contents.filePdfOri1}  id={id ? id : -1}/>   
                                    </div>                               
                                </td>
                                <td>
                                </td>
                            </tr>
                            <tr>
                                <th>2. 활동지</th>
                                <td>
                                    <FileAdd form_file={form_contents.fileHwp2} setFormFile={(e)=>setFormContents({...form_contents,hwp2:e})} form_orifile={form_contents.fileHwpOri2}  id={id ? id : -1}/>   
                                </td>
                                <td>
                                    <div className="filebox">
                                        <FileAdd form_file={form_contents.filePdf2} setFormFile={(e)=>setFormContents({...form_contents,pdf2:e})} form_orifile={form_contents.filePdfOri2} id={id ? id : -1} />   
                                    </div>
                                </td>
                                <td>
                                </td>
                            </tr>
                            <tr>
                                <th>3. 한번에 받기</th>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <div className="filebox">
                                        <FileAdd form_file={form_contents.fileZip3} setFormFile={(e)=>setFormContents({...form_contents,zip3:e})} form_orifile={form_contents.fileZipOri3} id={id ? id : -1} />   
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="4">
                                    <button type="button" className="btn-primary" onClick={(e)=>onSubmit(0)}>저장</button>
                                    <button type="button" className="btn-success" onClick={(e)=>goList()}>목록</button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </form>
            </div>
        </div>
    );
}

export default MonthForm;