import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

import * as api from "../../config/api"
import Pagination from 'react-js-pagination';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Info() {  
    const navigate = useNavigate();
    const location = useLocation();

    const { type, id } = useParams();
    const types = ["회원목록","탈퇴회원"]
   
    //유저정보
    const user = useSelector((state) => state.user);


    //등록
    let [form_member, setFormMember] = useState();


    const removeMember = () => {
        if(window.confirm("삭제 하시겠습니까?")){
            api.deleteData("/admin/member",{
                id:form_member?.id
            },user.token).then(res=>{
                if(res && res?.code === 'ERR_NETWORK'){
                    window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                    navigate("/")
                    return;
                }
                if(res && res.data.status==='SUCCESS'){
                    window.alert("삭제 되었습니다.")
                    onBoard();
                }
            });
        }
    } 

    const saveMember = (seq) => {
        if(window.confirm("회원정보를 수정 하시겠습니까?")){      

                form_member.name = api.b64DecodeUnicode(form_member?.name);
                form_member.phone = api.b64DecodeUnicode(form_member?.phone);
                form_member.school = form_member?.school ? api.b64DecodeUnicode(form_member?.school) : '';
                form_member.marketing = form_member?.marketing ? 1 : 0;

            api.putData("/admin/member",form_member,{id:form_member?.id}, user.token).then(res=>{
                if(res && res.data.status==='SUCCESS'){
                    window.alert("수정 되었습니다.");
                    navigate(-1);
                    //setFormMember({...form_member, newPassword:""});                    
                }
            });
        
        }
    } 

    const onClassCheck = (cl,chk) => {
        setFormMember({...form_member, [cl]:(chk ? 1 : 0)});
    }
    const onBoard = () =>{

        navigate("/member/"+type);
    }
    useEffect(() => {
        if(user?.grade === 0){
            if(id == 0){

            }
            if(id > 0){
                if(location?.state?.userSeq > 0){
                    setFormMember(location.state);
                }else{
                    window.alert("회원 목록에서 입장해주세요.");
                    navigate("/member/"+type);
                }
            }   
        }else{
            window.alert("접근 불가능한 페이지입니다.");
            navigate("/curriculum/native");
        }
    }, [type, id ]);
    
    return (
        <div className="normal">
            <div>
                <h1 className="head">{api.b64DecodeUnicode(form_member?.name)}  {form_member?.grade >= 0 ? api.memberGrade[form_member?.grade] : ''} <span> <strong className="text-success">{form_member?.grade === 1 && form_member?.teacherDate ? form_member?.teacherDate?.substr(0,16)+' 선생님 인증' : ''} </strong></span>
                    <button type="text" className="btn-danger btn-small" onClick={()=>removeMember()}>삭제하기</button>
                </h1>
                <table className="detail">
                    <colgroup>
                        <col width="15%"/>
                        <col width="35%"/>
                        <col width="15%"/>
                        <col width="35%"/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>가입유형</th>
                            <td>{api.registType[form_member?.registType]}</td>
                            <th>이름</th>
                            <td><input type="text" value={api.b64DecodeUnicode(form_member?.name)} onChange={(e)=>setFormMember({...form_member, name:e.target.value})} /></td>
                        </tr>
                        <tr>
                            <th>아이디</th>
                            <td>{form_member?.account}</td>
                            <th>비밀번호</th>
                            <td><input type="password" onChange={(e)=>setFormMember({...form_member, newPassword:e.target.value})}  placeholder="변경시 입력하세요."/></td>
                        </tr>
                        <tr>
                            <th>이메일</th>
                            <td><input type="text" value={form_member?.email} onChange={(e)=>setFormMember({...form_member, email:e.target.value})} /></td>
                            <th>휴대폰번호</th>
                            <td><input type="text" value={api.b64DecodeUnicode(form_member?.phone)} onChange={(e)=>setFormMember({...form_member, phone:e.target.value})} /></td>
                        </tr>
                        <tr>
                            <th>근무지역</th>
                            <td><input type="text"  value={form_member?.area} onChange={(e)=>setFormMember({...form_member, area:e.target.value})}/></td>
                            <th>학교명</th>
                            <td><input type="text" value={api.b64DecodeUnicode(form_member?.school)} onChange={(e)=>setFormMember({...form_member, school:e.target.value})} /></td>
                        </tr>
                        <tr>
                            <th>학교주소</th>
                            <td colSpan="3"><input type="text" value={form_member?.address} onChange={(e)=>setFormMember({...form_member, address:e.target.value})}  /></td>
                        </tr>
                        <tr>
                            <th>담당과목</th>
                            <td><input type="text" value={form_member?.subject} onChange={(e)=>setFormMember({...form_member, subject:e.target.value})} /></td>
                            <th>학년</th>
                            <td>
                                <label className="checkbox"><input type="checkbox" onClick={(e)=>onClassCheck('class1',!form_member?.class1)} checked={form_member?.class1} /><span>1학년</span></label>
                                <label className="checkbox"><input type="checkbox" onClick={(e)=>onClassCheck('class2',!form_member?.class2)} checked={form_member?.class2} /><span>2학년</span></label>
                                <label className="checkbox"><input type="checkbox" onClick={(e)=>onClassCheck('class3',!form_member?.class3)} checked={form_member?.class3} /><span>3학년</span></label>
                            </td>
                        </tr>
                        <tr>
                            <th>회원등급</th>
                            <td className="flex">
                                <select onChange={(e)=>setFormMember({...form_member, grade:e.target.value})} value={form_member?.grade}>
                                    {api.memberGrade.map((val,ind)=>(
                                        (val!=="" &&
                                        <option value={ind}>{val}</option>
                                        )
                                    ))}
                                    
                                </select>
                            </td>
                            <th>마케팅수신동의</th>
                            <td>
                                <label className="checkbox"><input type="checkbox" onChange={(e)=>setFormMember({...form_member, marketingSms:(e.target.checked ? 1 : 0)})} checked={form_member?.marketingSms} /><span>SMS 수신동의</span></label>
                                <label className="checkbox"><input type="checkbox" onChange={(e)=>setFormMember({...form_member, marketingEmail:(e.target.checked ? 1 : 0)})} checked={form_member?.marketingEmail} /><span>메일 수신동의</span></label>
                            </td>
                        </tr>
                        <tr className={type==1 ? "show" : "hide"}>
                            <th>탈퇴일자</th>
                            <td>
                            {form_member?.outDate?.substr(0,16)}
                            </td>
                            <th></th>
                            <td></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4">
                                <button type="button" className="btn-off" onClick={()=>onBoard()}>목록보기</button>
                                <button type="button" className="btn-primary" onClick={()=>saveMember(id)}>수정</button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

export default Info;