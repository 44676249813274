import React from 'react';
import { BrowserRouter  } from 'react-router-dom';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import './App.css';

import Main from './Main';
function App() {
  return (
			<BrowserRouter>
        <Provider store={store}>
          <Main />		
        </Provider>
			</BrowserRouter>
  );
}

export default App;
