import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import * as api from "../../config/api"
import Pagination from 'react-js-pagination';
import FileAdd from "../../component/FileAdd";

function Dictionary() {   
    //유저정보
    const user = useSelector((state) => state.user);
    const location = useLocation();
    const navigate = useNavigate();

    const [unit_list, setUnitList] = useState([]);
    const [unit_level_list, setUnitLevelList] = useState([]);
    const [unit_add, setUnitAdd] = useState([]);
    const [unit, setUnit] = useState(0);
    const [unit_level, setUnitLevel] = useState(0);

    //리스트 
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(20);

    //검색
    let [search, setSearch] = useState({startDate:"",endDate:"",view:-1,unitSeq:0,unitLevelSeq:0,sub:"",subject:-1,type:-1});
        
    //url파라미터
    const { id } = useParams();

    const onStartDate = (date) => {
        setSearch(
            {
                ...search, startDate: (date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : '')
            }
        );
    }
    const onEndDate = (date) => {
        setSearch(
            {
                ...search, endDate: (date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : '')
            }
        );
    }


    const searchReset = () => {
        setSearch({startDate:"",endDate:"",view:-1,unitSeq:0,unitLevelSeq:0});
        search.startDate="";
        search.endDate="";
        search.view=-1;
        search.unitSeq=0;
        search.unitLevelSeq=0;
        search.name="";
        search.sub="";
        search.subject=-1;
        search.type=-1;
        onList(1);
    }

    const  onList = (page) => {
        api.getData("/admin/dictionary",{
            page:page-1,
            size:limit,
            //unit:search.unitSeq,
            name:search.name,
            sdate:search.startDate,
            edate:search.endDate,
            sub:search.sub,
            subject:search.subject,
            type:search.type,
            view:(search.view == -1 ? '' : search.view)
        },user.token).then(res=>{
            if(res && res.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);   
            }
        });
    }

    const goList = () => {
        navigate("/class/dictionary" )
    }
    const onRemove = () =>{
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/dictionary",{
                id:id
            },user.token).then(res=>{
                if(res.data.status == "SUCCESS"){
                    goList();
                }
            });
        }
    }
    const onUnit = (e) =>{
        setSearch({...search, unitSeq: e});
    }    
    useEffect(() => {
        onList(location?.state?.page ? location?.state?.page : 1);    

        api.getData("/dictionary/unit/level", {
        },user.token).then(res=>{
            if(res && res.data.status == "SUCCESS"){
                setUnitLevelList(res.data.data);
            }
        });
        api.getData("/dictionary/unit", {
        },user.token).then(res=>{
            if(res && res.data.status == "SUCCESS"){
                setUnitList(res.data.data);
            }
        });
    }, []);

    return (
        <div className="normal">
            <div >
                <h1 className="head">수업고민사전<Link to="/class/dictionary/0" className="btn-info btn-small">등록하기</Link></h1>        
                <div className="search" name="searc" method="get">
                    <form>
                        <div className="wrap_search">
                            <div className="search_group">
                                <label className="title">등록일</label>
                                <div className="box_search">
                                    <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={search.startDate} onChange={(date) => onStartDate(date)} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>
                                    <span className="box_wave">~</span>
                                    <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={search.endDate} onChange={(date) => onEndDate(date)} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>                                
                                </div>
                            </div>
                            <div className="search_group">
                                <label className="title">용어명</label>
                                <div className="box_search"><input type="text" placeholder="용어명"  onChange={(e)=>setSearch({...search, name:e.target.value})}/></div>
                            </div>
                            <div className="search_group">
                                <label className="title">검색필터</label>
                                <div className="box_search _flex">
                                    <select onChange={(e)=>setSearch({...search, sub:e.target.value})} >
                                        <option value="">교과</option>
                                        <option value="국어">국어</option>
                                        <option value="사회">사회</option>
                                        <option value="미술">미술</option>
                                    </select>
                                    {search.sub !== '미술' ? 
                                    <select onChange={(e)=>setSearch({...search, subject:e.target.value})} >
                                        <option value="-1">과목</option>
                                        {search.sub === '국어' ?
                                        <>
                                            <option value="0">중학교1</option>
                                            <option value="1">중학교2</option>
                                            <option value="2">중학교3</option>
                                            <option value="3">공통국어</option>
                                            <option value="4">문학</option>
                                            <option value="5">독서와 작문</option>
                                            <option value="6">화법과 언어</option>
                                        </> 
                                        : search.sub === '사회' ? 
                                        <>
                                            <option value="0">통합사회</option>
                                            <option value="1">사회와 문화</option>
                                        </>
                                        :
                                        <></>
                                        }
                                    </select>
                                    :
                                    <></>
                                    }
                                    <select onChange={(e)=>setSearch({...search, type:e.target.value})} >
                                        <option value="-1">영역</option>
                                        {search.sub === '국어' ?
                                        <>
                                            <option value="0">문학</option>
                                            <option value="1">문법</option>
                                            <option value="2">듣기·말하기</option>
                                            <option value="3">읽기·쓰기</option>
                                            <option value="4">매체</option>
                                        </> 
                                        : search.sub === '사회' ? 
                                        <>
                                            <option value="0">일반사회</option>
                                            <option value="1">윤리</option>
                                            <option value="2">지리</option>
                                        </>
                                        : search.sub === '미술' ? 
                                        <>
                                            <option value="0">인물</option>
                                            <option value="1">용어</option>
                                        </>
                                        :
                                        <></>
                                        }


                                    </select>
                                </div>
                            </div>
                            {/*}
                            <div className="search_group">
                                <label className="title">카테고리</label>
                                <div className="box_search _flex">
                                    <select onChange={(e)=>onUnit(e.target.value)} >
                                    <option value="">카테고리</option>
                                    {unit_list.map((val, index) => (
                                        <option value={val.unitSeq}>{val.name}</option>
                                    ))}
                                    </select>
                                </div>
                            </div>
                            */}
                            <div className="search_group">
                                <label className="title">게재여부</label>
                                <div className="box_search">
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, view:-1})} checked={search.view==-1} /><span>전체</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, view:1})} checked={search.view==1}  /><span>노출</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, view:0})} checked={search.view==0}  /><span>비노출</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="reset" className="btn-off" onClick={(e)=>searchReset()}>초기화</button>
                            <button type="button" className="btn-primary" onClick={(e)=>onList(1)}>검색</button>
                        </div>    
                    </form>        
                </div>
                <h1 className="head">목록<span>검색결과<strong className="text-danger">{list?.length}</strong>개</span><button type="button" className="btn-success btn-small">엑셀다운로드</button></h1>        
                <table>
                    
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>등록일</th>
                            <th>카테고리</th>
                            <th>용어명</th>
                            <th>게재여부</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((val,index)=> 
                        <tr>
                            <td>{(total-index-(page*limit))}</td>
                            <td>{val.registDate?.substr(0,16)}</td>
                            <td className="text-small">
                                {JSON.parse(val.unitJson).map(par=> (
                                    <><small>{par.unitName +" > " + par.unitLevelName}</small><br/></>
                                ))}
                            </td>
                            <td><Link to={"/class/dictionary/"+val.dictionarySeq+"?page="+(page+1)} state={val}>{val.name ? val.name : '제목없음'}</Link></td>
                            <td><strong className="o">●</strong></td>
                        </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="10">
                                <div className="paging">
                                    <Pagination
                                        activePage={page+1}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={10}
                                        prevPageText={"◀"}
                                        nextPageText={"▶"}
                                        onChange={onList}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

export default Dictionary;