
function Footer() {
    return (
        <footer>
           
        </footer>
    );
  }
  
  export default Footer;
  