import axios from 'axios';
export const url = "https://api.changbiedu.com";
export const front = "https://class.changbiedu.com";
//export const url = "http://localhost:8081";
//export const front = "http://localhost:3001";


const dd = new Date();
export const today = dd.getFullYear()+"-"+("0" + (dd.getMonth() + 1)).slice(-2)+"-"+("0" + dd.getDate()).slice(-2) +" " + ("0" + dd.getHours()).slice(-2)+":"+("0" + dd.getMinutes()).slice(-2)+":"+("0" + dd.getSeconds()).slice(-2);
export const registType = ["이메일","네이버","카카오"];
export const memberGrade = ["일반","선생님","편집자","영업","","","","","관리자","최고관리자"];

export const b64DecodeUnicode = (str)  => {
  if(str){
    const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    if(base64regex.test(str)){
      return decodeURIComponent(window.atob(str).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }else{
      return str;
    }
  }else{
    return str;
  }
}

export const getFirstData = async (link,values,token) => {
  try {
    return await axios.get(url + link,{
      params: values,
      headers: {
        'accept': 'application/json;charset=UTF-8',
        'Refresh-Token': token?.refreshToken,
        'Authorization': 'Bearer '+localStorage.getItem("access"),
        'Content-Type': 'application/json;charset=UTF-8'
      } 
    });
  } catch (error) {
    return error;
    //console.error(error); // 에러 처리
  }
}



export const getData = async (link,values,token) => {
  
  try {
    return await axios.get(url + link,{
      params: values,
      headers: {
        'accept': 'application/json;charset=UTF-8',
        'Refresh-Token': token?.refreshToken,
        'Authorization': 'Bearer '+localStorage.getItem("access"),
        'Content-Type': 'application/json;charset=UTF-8'
      }
    });
  } catch (error) {
    return error;
    //console.error(error); // 에러 처리
  }
}

export const putData = async (link,values,params,token) => {

  try {
    return await axios.put(url + link,
      values,{
        params:params,
        headers: {
          'accept': 'application/json;charset=UTF-8',
          'Refresh-Token': token?.refreshToken,
          'Authorization': 'Bearer '+localStorage.getItem("access"),
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
    );
  } catch (error) {
    return error;
    //console.error(error); // 에러 처리
  }
}
export const postData = async (link,values,token) => {
  try {
    return await axios.post(url + link,
      values
      ,{
        headers: {
          'accept': 'application/json;charset=UTF-8',
          'Refresh-Token': token?.refreshToken,
          'Authorization': 'Bearer '+localStorage.getItem("access"),
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
    );
  } catch (error) {
    return error;
    //console.error(error); // 에러 처리
  }
}
export const deleteData = async (link,values,token) => {
  try {
    return await axios.delete(url + link,{
      params: values,
      headers: {
        'accept': 'application/json;charset=UTF-8',
        'Refresh-Token': token?.refreshToken,
        'Authorization': 'Bearer '+localStorage.getItem("access"),
        'Content-Type': 'application/json;charset=UTF-8'
      }
    });
  } catch (error) {
    return error;
    //console.error(error); // 에러 처리
  }
}
export const blobData = async (link,values,token) => {
  try {
    return await axios.get(url + link,{
      params: values,
      headers: {
        'accept': 'application/json;charset=UTF-8',
        'Refresh-Token': token.refreshToken,
        'Authorization': 'Bearer '+localStorage.getItem("access"),
        'Content-Type': 'application/json;charset=UTF-8'
      },
      responseType: 'blob'
    });
  } catch (e) {
    return e;
  }
}

/* 멀티파일업로드 */
export const fileUpload = async (link,formData,token) => {
  try {
    return await axios.post(url + link, formData, {
        headers: {
          'accept': 'application/json;charset=UTF-8',
          'Refresh-Token': token?.refreshToken,
          'Authorization': 'Bearer '+localStorage.getItem("access"),
          'content-type': 'multipart/form-data'
        }
      }
    );
  } catch (error) {
    return error;
    //console.error(error); // 에러 처리
  }
}


export const signin = async (formid,formpw) => {
  try {
    return await axios.post(url + "/admin/signin",{
      account:formid,
      pw:formpw
    });
  } catch (error) {
    return error;
    //console.error(error); // 에러 처리
  }
}

