import React, {useEffect} from 'react';
import { useSelector } from "react-redux";

import * as api from "../config/api"

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function ExcelDownload(props) {  
  

    //유저정보
    const user = useSelector((state) => state.user);
    
    const datas  = (i,data) => {
        let redata = [];
        if(props.name === "회원목록" ){
            redata=[i, data.registDate, data.account,  api.b64DecodeUnicode(data.name),  api.b64DecodeUnicode(data.phone), api.memberGrade[data.grade], api.b64DecodeUnicode(data.school), (data.class1 ? 'O' : ''), (data.class2 ? 'O' : ''), (data.class3 ? 'O' : ''), data.subject, data.area, (data.marketingSms ? 'O' : ''), (data.marketingEmail ? 'O' : '')];
        }
        if(props.name === "이달의 수업" ){
            redata=[i, data.registDate, data.subject,  data.school,  data.month, data.title];
        }
        if(props.name === "이벤트 댓글 리스트" ){
            redata=[i, data.registDate, data.account,  api.b64DecodeUnicode(data.name),  api.b64DecodeUnicode(data.phone), api.memberGrade[data.grade], api.b64DecodeUnicode(data.school), data.reply, (data.view ? '노출' : '미노출')];
        }
        if(props.name === "교과자료" ){
            redata=[i, data.registDate, data.school,  data.subjectText,  data.subjectText, data.unitText, data.typeText, data.name, (data.view ? '노출' : '미노출')];
        }
        return redata;

    }
    
    const onDownload = () => {
        api.getData("/admin"+props.api, props.get,user.token).then(res=>{
            if(res && res?.data?.status==='SUCCESS'){
                const excelData = res.data.data;
                
                const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const excelFileExtension = '.xlsx';
                const excelFileName = props.name;
            
                const ws = XLSX.utils.aoa_to_sheet([
                    props.thead
                ]);
                let i=1;
                excelData.map((data) => {
                    XLSX.utils.sheet_add_aoa(
                    ws,
                    [
                        datas(i, data)
                    ],
                    {origin: -1}
                    );  
                    i++;        
                    return false;
                });
                const wb = {Sheets: { data: ws }, SheetNames: ['data']};
                const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
                const excelFile = new Blob([excelButter], { type: excelFileType});
                FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
            
            }
           
        });
    }

    
    return (
        <button type="button" onClick={(e)=>onDownload()} className="btn-success btn-small">엑셀다운로드</button>
    );
}

export default ExcelDownload;