import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import Editor from "./Editor";
import FileAdd from "./FileAdd";
import ImageAdd from "./ImageAdd";
import * as api from "../config/api"
import Pagination from 'react-js-pagination';


function Board(props) {
    //유저정보
    const user = useSelector((state) => state.user);
    //리스트 
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(20);

    //검색
    const today = new Date();    
    let [startDate, setStartDate] = useState();
    let [endDate, setEndDate] = useState();
    let [search_text, setSearchText] = useState();
    let [search_view, setSearchView] = useState(-1);

    //등록
    const [form_title, setFormTitle] = useState("");
    const [form_tag, setFormTag] = useState("");
    const [form_content, setFormContent] = useState("");
    const [form_file, setFormFile] = useState("");
    const [form_orifile, setFormOriFile] = useState("");
    const [form_thumbnail, setFormThumbnail] = useState("");
    const [form_orithumbnail, setFormOriThumbnail] = useState("");
    const [form_view, setFormView] = useState(true);
    const [form_main_view, setFormMainView] = useState(true);
    const [form_date, setFormDate] = useState();
    
    //url파라미터
    const {category_type, category_seq, board_seq } = useParams();
    


    const navigate = useNavigate();
    const location = useLocation();

    const onStartDate = (date) => {
        setStartDate(date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : '');
    }
    const onEndDate = (date) => {
        setEndDate(date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : '');
    }
    const searchReset = () => {
        setStartDate();
        setEndDate();    
        setSearchText();    
        setSearchView(-1);        
        startDate = "";
        endDate = "";
        search_view = "";
        search_text = "";
        onList(1);
    }

    const  onList = (page) => {
        api.getData("/admin/board",{
            page:page-1,
            size:limit,
            type:category_type,
            cseq:category_seq,
            sdate:startDate,
            edate:endDate,
            text:search_text,
            view:(search_view == -1 ? '' : search_view)
        },user.token).then(res=>{
            if(res && res?.data?.status==='SUCCESS'){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);   
            }
        });
    }
    
    useEffect(() => {
        
        if(board_seq == 0){
            setFormDate("");
            setFormTitle("");
            setFormContent("");
            setFormFile("");
            setFormOriFile("");
            setFormThumbnail("");
            setFormOriThumbnail("");
            setFormView(true);
            setFormMainView(true);
            setFormTag("");
        }
        if(location?.state?.boardSeq > 0){
            setFormDate(location.state.registDate);
            setFormTitle(location.state.title);
            setFormContent(location.state.contents);
            setFormFile(location.state.file);
            setFormOriFile(location.state.oriFile);
            setFormThumbnail(location.state.thumbnail);
            setFormOriThumbnail(location.state.oriThumbnail);

            setFormView(location.state.view);
            setFormMainView(location.state.mainView);
            setFormTag(location.state.tag);
        }else{
            onList(location?.state?.page ? location?.state?.page : 1); 
        }
    }, [category_seq, board_seq]);

    

    let h1 = "";
    
    if(props?.submenu){
        let lll = props.submenu?.filter((val=>
            val.categorySeq == category_seq
        ))
        h1 = lll[0]?.name;
    } else {
        h1 = location?.state?.title;
    }
    

    
    const onRemove = () =>{
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/board",{
                id:board_seq
            },user.token).then(res=>{
                if(res?.data?.status == "SUCCESS"){
                    goList();
                }
            });
        }
    }
    
    const params = new URLSearchParams(location.search);
    const goList = () => {
        //navigate("/board/"+category_type+"/"+category_seq, { state: e.target.value }); 
        navigate("/board/"+category_type+"/"+category_seq+"/" , {state:{page:params.get("page")}}); 
    }
    const onSubmit = (preview) => {
        let saveurl = "/admin/board";
        let formData = new FormData();
        if(preview){
            saveurl="/admin/preview";
        }

        if(board_seq > 0 ){
            formData.append("boardSeq",board_seq);
            formData.append("registDate",form_date);
        }
        if(board_seq == 0 ){
            const dd = new Date();
            formData.append("registDate",
            dd.getFullYear()+"-"+
            ("0" + (dd.getMonth() + 1)).slice(-2)+"-"+
            ("0" + dd.getDate()).slice(-2) +" " + 
            ("0" + dd.getHours()).slice(-2)+":"+
            ("0" + dd.getMinutes()).slice(-2)+":"+
            ("0" + dd.getSeconds()).slice(-2));
        }
        formData.append("type",category_type);
        formData.append("categorySeq",category_seq);
        formData.append("title",form_title);
        formData.append("tag",form_tag);
        formData.append("view",(form_view ? 1 : 0));
        formData.append("mainView",(form_main_view ? 1 : 0));
        formData.append("contents",form_content);
        formData.append("files",form_file);
        formData.append("thumbnails",form_thumbnail);
        
        api.fileUpload(saveurl,formData,user.token).then(res=>{
            if(preview){
                const re = res.data.data;
                let link = "/creative/view/"+re.categorySeq+"/"+re.boardSeq;
                if( re.type === 1){
                    link = "/book/view/"+re.categorySeq+"/"+re.boardSeq;
                }else if( re.type === 2){
                    link = "/teacher/view/"+re.categorySeq+"/"+re.boardSeq;
                }else{
                    link = "/creative/view/"+re.categorySeq+"/"+re.boardSeq;
                }

                window.open(api.front + "/preview"+link,"","");
            }else{
                goList();
            }
        });
    }
    
    return (
        <div className="normal">
            <div className={board_seq==null ? "show" : "hide"}>

                <h1 className="head">{h1}<Link to={"/board/"+category_type+"/"+category_seq+"/0"} state={{title:h1,boardSeq:0}} className="btn-info btn-small">등록하기</Link></h1>
                
                <div className="search" name="searc" method="get">
                    <form>
                        <div className="wrap_search">
                                <div className="search_group">
                                    <label className="title">등록일</label>
                                    <div className="box_search">
                                        <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date) => onStartDate(date)} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>
                                        <span className="box_wave">~</span>
                                        <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={endDate} onChange={(date) => onEndDate(date)} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>                                
                                    </div>
                                </div>
                            
                                <div className="search_group">
                                    <label className="title">검색</label>
                                    <div className="box_search">
                                        <input type="text" placeholder="제목, 태그" onChange={(e)=>setSearchText(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="search_group">
                                    <label className="title">게재여부</label>
                                    <div className="box_search">
                                        
                                        <label className="radiobox"><input type="radio" name="view" value="-1" onClick={(e)=>setSearchView(-1)} checked={search_view==-1} /><span>전체</span></label>
                                        <label className="radiobox"><input type="radio" name="view" value="1" onClick={(e)=>setSearchView(1)} checked={search_view==1}/><span>노출</span></label>
                                        <label className="radiobox"><input type="radio" name="view" value="0" onClick={(e)=>setSearchView(0)} checked={search_view==0}/><span>비노출</span></label>
                                        
                                    </div>
                                </div>
                        </div>
                        <div className="text-center">
                            <button type="reset" className="btn-off" onClick={(e)=>searchReset()}>초기화</button>
                            <button type="button" className="btn-primary" onClick={(e)=>onList(1)}>검색</button>
                        </div>  
                    </form>          
                </div>
                <h1 className="head">목록<span>검색결과<strong className="text-danger">{total}</strong>개</span></h1>        

                <table>
                    
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>등록일</th>
                            <th>제목</th>
                            <th>태그</th>
                            <th>게재여부</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((val,index)=>                     
                        <tr>
                            <td>{(total-index-(page*limit))}</td>
                            <td>{val.registDate}</td>
                            <td><Link to={"/board/"+category_type+"/"+category_seq+"/"+val.boardSeq+"?page="+(page+1)} state={val}>{val.title ? val.title : '제목 없음'}</Link></td>
                            <td>{val.tag}</td>
                            <td><strong className={val.view ? 'o' : 'x'}>{val.view ? '●' : 'x'}</strong></td>
                        </tr>
                        )}
                        
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="10">
                                <div className="paging">
                                    <Pagination
                                        activePage={page+1}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={10}
                                        prevPageText={"◀"}
                                        nextPageText={"▶"}
                                        onChange={onList}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className={board_seq>=0 ? "show" : "hide"}>
                <h1 className="head">{category_seq > 0 ? '신규등록' : '수정하기'}
                    {board_seq > 0 && (
                        <button type="text" className="btn-danger btn-small" onClick={()=>onRemove()}>삭제하기</button>
                    )}

                    <button type="text" className="btn-warning btn-small" onClick={()=>onSubmit(1)}>미리보기</button>
                </h1>
                <table className="detail">
                    <colgroup>
                        <col width="15%"/>
                        <col width="85%"/>
                    </colgroup>
                    <tbody>
                        {board_seq > 0 &&
                        <tr>
                            <th>등록일시</th>
                            <td>{location?.state?.registDate?.substr(0,16)}</td>
                        </tr>
                        }

                        <tr>
                            <th>제목</th>
                            <td><input type="text" placeholder="제목을 입력하세요" value={form_title} onChange={(e)=>setFormTitle(e.target.value)}/></td>
                        </tr>
                        <tr>
                            <th>태그</th>
                            <td><input type="text" placeholder="예)학교,우리,사랑" value={form_tag} onChange={(e)=>setFormTag(e.target.value)}/></td>
                        </tr>
                        <tr>
                            <th>썸네일 이미지<br/><small>{category_type == 0 || category_type==2 ? "800px * 800px" : "600px * 800px"}</small></th>
                            <td>
                                {category_type == 0 || category_type==2
                                ? <ImageAdd folder="board" form_thumbnail={form_thumbnail} setFormThumbnail={setFormThumbnail} form_orithumbnail={form_orithumbnail}  width={800} height={800} resize={3} id={board_seq ? board_seq : -1}/>
                                : <ImageAdd folder="board" form_thumbnail={form_thumbnail} setFormThumbnail={setFormThumbnail} form_orithumbnail={form_orithumbnail}  width={600} height={800} resize={2} id={board_seq ? board_seq : -1}/>
                                }
                                
                            </td>
                        </tr>
                        <tr>
                            <th>파일</th>
                            <td>
                                <FileAdd form_file={form_file} setFormFile={setFormFile} form_orifile={form_orifile} id={board_seq ? board_seq : -1} />
                            </td>
                        </tr>
                        <tr>
                            <th>게재여부</th>
                            <td>
                                <label className="radiobox"><input type="radio" name="view" checked={form_view} onClick={(e)=>setFormView(!form_view)}/><span>노출</span></label>
                                <label className="radiobox"><input type="radio" name="view" checked={!form_view} onClick={(e)=>setFormView(!form_view)}/><span>비노출</span></label>
                            </td>
                        </tr>
                        <tr>
                            <th>메인게재여부</th>
                            <td>
                                <label className="radiobox"><input type="radio" name="mview" checked={form_main_view} onClick={(e)=>setFormMainView(!form_main_view)}/><span>노출</span></label>
                                <label className="radiobox"><input type="radio" name="mview" checked={!form_main_view} onClick={(e)=>setFormMainView(!form_main_view)}/><span>비노출</span></label>
                            </td>
                        </tr>
                        <tr>
                            <th>상세내용</th>
                            <td>
                                <Editor 
                                    placeholder="내용을 입력하세요."
                                    value={form_content}
                                    setFormContent={setFormContent}
                                    folder="/board"
                                />
           
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2">
                                <button type="button" className="btn-off" onClick={()=>goList()}>목록보기</button>
                                <button type="button" className="btn-primary" onClick={()=>onSubmit(0)}>{board_seq > 0 ? '수정' : '저장'}</button>
                            </td>
                        </tr>
                    </tfoot>                    
                </table>
    
            </div>
        </div>
    );
}

export default Board;