import React, {useEffect, useState, useRef } from 'react';
import { useSelector, } from "react-redux";
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import * as api from "../../config/api"

function Pdf() {
    //유저정보
    const user = useSelector((state) => state.user);
    const [list, setList] = useState([]);
    const navigate = useNavigate();

    const onFileUpload = (e,seq) => {
        const {files} = e.target;
        const uploadFile = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onloadend = ()=> {
            const formData = new FormData();
            formData.append("unitSeq",seq);    
            formData.append("files",uploadFile);

            api.fileUpload("/admin/dictionary/pdf",formData,user.token).then(res=>{
                if(res.data.status=="SUCCESS"){
                    setList(list.map(val=>
                        val.unitSeq == seq ? {...val, oriFile:uploadFile.name} : val
                    ));
                }
            });
        }
    }
    
    useEffect(() => {
        api.getData("/dictionary/unit", {
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data);
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });
    }, []);

    const removeFile = (seq) => {
        if(window.confirm("파일을 삭제하시겠습니까?")){

            const formData = new FormData();
            formData.append("unitSeq",seq);    
            
            api.fileUpload("/admin/dictionary/pdf",formData,user.token).then(res=>{
                if(res.data.status=="SUCCESS"){
                    setList( list.map(val=>
                        val.unitSeq ===seq ? {...val, oriFile:''} : val
                    ));
                }
            });

        }
    } 
    return (
        <div>
            <h1 className="head">수업고민사전 한 번에 받기 자료</h1>
            <table className="detail">
                <colgroup>
                    <col width="15%"/>
                    <col width="85%"/>
                </colgroup>
                <thead>
                    <tr>
                        <th>카테고리</th>
                        <th>PDF</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map(val => 
                        val.unitSeq >= 12 ?
                        <tr>
                            <th>{val.name}</th>
                            <td>
                                <div className="filebox">
                                    <label>
                                        <input type="file" onChange={(e)=>{onFileUpload(e,val.unitSeq)}} />
                                        <span>파일첨부</span>
                                    </label>
                                    <p>{val.oriFile}</p>
                                    <button className={val.oriFile!=null && val.oriFile!='' ? 'show' : 'hide'} type="button" onClick={(e)=>removeFile(val.unitSeq)}>삭제</button>
                                </div>
                            </td>
                        </tr>
                        :
                        <></>
                    )}
                </tbody>
            </table>

        </div>
    );
}

export default Pdf;