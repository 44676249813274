import React, {useEffect, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";

import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpDown } from "@fortawesome/free-solid-svg-icons";

import * as api from "../../config/api"

function Set1() {
    
    //유저정보
    const user = useSelector((state) => state.user);

    const navigate = useNavigate();
    const [tab, setTab] = useState('국어');
    const [school, setSchool] = useState('초등');
    const [addYear, setYear] = useState();
    const [addView, setView] = useState(1);
    
    const [list, setList] = useState([ ])

    const dragItem = useRef(); // 드래그할 아이템의 인덱스
    const dragOverItem = useRef(); // 드랍할 위치의 아이템의 인덱스

    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    // 드랍 (커서 뗐을 때)
    const drop = (par) => {
        const startSort = dragOverItem.current;
        const endSort = dragItem.current;
        const newList = [...list];
        const dragItemValue = newList[dragItem.current];
        newList.splice(dragItem.current, 1);       
        newList.splice(dragOverItem.current, 0, dragItemValue);
        dragItem.current = null;
        dragOverItem.current = null;
        if(startSort != endSort){
            setList(
                newList.map(val =>
                    val.seq === par.seq ? { ...val, seq: "move" } : val
                )
            );
            //드래그 순간 name이 같아져서 셀렉트 박스 체크 풀림 이동시 move처리 놓는 순간 다시 자기 번호로 변경
            setTimeout(()=>{
                setList(
                    newList.map(val =>
                        val.seq === par.seq ? { ...val, seq: par.seq } : val
                    )
                );
                let seqs = '';
                newList.map(val => {
                    seqs += ","+val.seq;
                })
                
                api.putData("/admin/curriculum/year/sort", {},{
                    seqs:seqs
                },user.token).then(res=>{
                    if(res){

                    }
                });
            },100)
        }
    };

    const onList = (tab,school) => {
        api.getData("/admin/curriculum/year", {
            subject:tab,
            school:school
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data);
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });
    }

    useEffect(() => {
        onList(tab,school);
    }, []);

    const onRemove = (seq) => {
        if(window.confirm("해당 개정년로 등록된 교과관리가 모두 삭제됩니다.\n정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/curriculum/year", {
                id:seq
            },user.token).then(res=>{
                
                if(res.data.status == "SUCCESS"){
                    setList(list.filter(val => val.seq !== seq));
                    window.alert("삭제되었습니다.");
                }                
            });
        }
    } 
    const onSave = () => {
        if(!addYear){
            window.alert("연도를 입력하세요.");
            return;
        }
        
        const sort =  list.filter((par,key) => par.subject == tab && par.school == school ).length;


        api.postData("/admin/curriculum/year", {
            subject:tab,
            school:school,
            year:addYear,
            view:addView,
            sort:(sort+1)
        },user.token).then(res=>{
            if(res.data.status == "SUCCESS"){
                setList([...list, res.data.data]);
                setYear("");
            }
        });
    }
    const onModify = (val) => {
        if(window.confirm("해당 개정년로 등록된 교과관리가 모두 수정됩니다.\n정말로 수정하시겠습니까?")){
            api.putData("/admin/curriculum/year", {
                year:val.year,
                view:val.view
            },{
                id:val.seq
            },user.token).then(res=>{
                if(res.data.status == "SUCCESS"){
                    window.alert("수정되었습니다.");
                }
            });
        }
    }


    const onView = (seq,chk) => {
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, view: chk } : val
            )
        );
    }  

    const onChangeYear = (e,seq) => {
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, year: e.target.value } : val
            )
        );
    }
    const onTab = (val) => {
        setTab(val);
        onList(val,school);
    }
    const onSchool = (val) => {
        setSchool(val);
        onList(tab,val);
    }

    return (
        <div>
            <h1 className="head">개정 연도 설정</h1>
            <div className="tab">
                <button type="button" className={tab=='국어' ? "active" : ""}  onClick={()=>onTab('국어')}>국어</button>
                <button type="button" className={tab=='사회' ? "active" : ""} onClick={()=>onTab('사회')}>사회</button>
                <button type="button" className={tab=='미술' ? "active" : ""}  onClick={()=>onTab('미술')}>미술</button>
            </div>
            <div className="tab_box">
                <div className="tab_category dragndrop">
                    <dl>
                        <dt>
                            <select onChange={(e)=>onSchool(e.target.value)} value={school}>
                                <option value="">학교급</option>
                                <option value="초등">초등</option>
                                <option value="중학">중학</option>
                                <option value="고등">고등</option>
                                <option value="인정">인정</option>
                            </select>
                        </dt>

                        {list.map((val, index) => (
                        <dd 
                            className={tab==val.subject && school==val.school ? 'show' : 'hide'}
                            key={index}
                            draggable
                            onDragStart={(e) => dragStart(e, index)}
                            onDragEnter={(e) => dragEnter(e, index)}
                            onDragEnd={(e) => drop(val)}
                            onDragOver={(e) => e.preventDefault()}
                        >
                            <span>[{val.seq}]</span>
                            <i><FontAwesomeIcon icon={faUpDown} /> </i>
                            
                            <div className="radio">
                            <input type="text" value={val.year} onChange={(e)=>onChangeYear(e,val.seq)} />
                            <label className="radiobox"><input type="radio" onClick={()=>onView(val.seq,1)} checked={val.view} /><span>노출</span></label>
                            <label className="radiobox"><input type="radio" onClick={()=>onView(val.seq,0)} checked={!val.view} /><span>비노출</span></label>                       
                            <button type="button" className="btn-info" onClick={()=>onModify(val)}>수정</button>   
                            <button type="button" className="btn-danger" onClick={()=>onRemove(val.seq)}>삭제</button>
                            </div>
                        </dd>
                        ))}

                        <dd className="show">
                            <i className="bean"></i>
                            <div className="radio">
                            <input type="text" value={addYear} onChange={(e)=>setYear(e.target.value)} />
                            <label className="radiobox"><input type="radio" name="add" onClick={()=>setView(1)} value="1" defaultChecked/><span>노출</span></label>
                            <label className="radiobox"><input type="radio" name="add" onClick={()=>setView(0)} value="0" /><span>비노출</span></label>                                              
                            <button type="button" className="btn-primary" onClick={()=>onSave()}> 추가</button>
                            </div>
                        </dd>
                    </dl>
                </div>                
            </div>


        </div>
    );
}

export default Set1;