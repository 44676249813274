import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import noimage from '../../asset/image/noimage300.jpg'
import ImageAdd from "../../component/ImageAdd";
import * as api from "../../config/api"

function Bottom() {
    //유저정보
    const user = useSelector((state) => state.user);
    const [list, setList] = useState([ ]);
    const navigate = useNavigate();


    const oncImageUpload = (e,seq,type) => {
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, [type]: e } : val
            )
        );  
    }

    const saveBanner = (val) => {
        const formData = new FormData();
        if(val.seq > 0 ){
            formData.append("seq",val.seq);
        }
        
        formData.append("url",val.url);
        formData.append("view",val.view);
        formData.append("type",2);
        formData.append("pcs",val.pc);
        formData.append("mobiles",val.mobile);

        api.fileUpload("/admin/setting/banner",formData,user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status === "SUCCESS"){
                if(val.seq > 0){
                    window.alert("수정 되었습니다.");
                }else{
                    window.alert("저장 되었습니다.");
                }
            }            
            if(val.seq<0){
                const dd = res.data.data;
                setList(
                    list.map(par =>
                        par.seq === val.seq ? { ...par, 
                            mobile:  dd.mobile,
                            oriMobile:  dd.oriMobile,
                            oriPc:  dd.oriPc,
                            pc:  dd.pc,
                            seq:  dd.seq,
                            type:  dd.type,
                            url:  dd.url,
                            view:  dd.view,
                        } : par
                    )
                );
            }
        });
    }
    const removeBanner = (par) =>{
        if(par.seq > 0 ){
            if(window.confirm("정말로 삭제하시겠습니까?")){
                
                api.deleteData("/admin/setting/banner",{
                    id:par.seq
                },user.token).then(res=>{
                    setList(
                        list.filter(val =>
                            val.seq !== par.seq
                        )
                    );
                });
            }
        }else{
            setList(
                list.filter(val =>
                    val.seq !== par.seq
                )
            );
        }            
    }
    const urlTitle = (e,seq) => {
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, url: e.target.value } : val
            )
        );
    }
    const showRadio = (e,seq,chk) => {
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, view: chk } : val
            )
        );
    }
    const add = () => {
        const num =  Math.floor(Math.random() * 100);
        const newItem = {
            seq:-num,
            pc:'',
            mobile:'',
            url:'',
            view:1
        }
        setList([newItem, ...list]);
    }
    
    useEffect(() => {
        api.getData("/admin/setting/banner",{
            type:2
        },user.token).then(res=>{
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data)
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });
    }, []);
    return (
        <div className="normal">
            <h1 className="head">하단배너관리<button type="button" className="btn-info btn-small" onClick={add}>추가</button></h1>

                {list.map((val, index) => (
                <table className="detail">
                    <colgroup>
                        <col width="15%"/>
                        <col width="85%"/>
                    </colgroup>                       
                    <tbody>
                        <tr>
                            <th>URL</th>
                            <td className="flex">
                                <input type="text" value={val.url} onChange={(e)=>urlTitle(e,val.seq)} />
                                <div>
                                    <button type="button" className={val.seq > 0 ? 'btn-success' : 'btn-primary'} onClick={(e)=>saveBanner(val)}>{val.seq > 0 ? '수정' : '저장'}</button>      
                                    <button type="button" className="btn-danger" onClick={(e)=>removeBanner(val)}>삭제</button>     
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>배너</th>
                            <td className="flex">
                                <div>
                                    <h2>PC<small>720 * 400px</small></h2>
                                    <ImageAdd folder="banner" form_thumbnail={val.pc} setFormThumbnail={(e)=>{oncImageUpload(e,val.seq,'pc')}} form_orithumbnail={val.oriPc}  width={720} height={400} resize={3}/>
                                </div>
                                <div>
                                    <h2>Mobile<small>720 * 400px</small></h2>
                                    <ImageAdd folder="banner" form_thumbnail={val.mobile} setFormThumbnail={(e)=>{oncImageUpload(e,val.seq,'mobile')}} form_orithumbnail={val.oriMobile}  width={720} height={400} resize={3}/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>노출</th>
                            <td>
                                <label className="radiobox"><input type="radio" value="1" checked={val.view===1} onChange={(e)=>showRadio(e,val.seq,1)}/><span>노출</span></label>
                                <label className="radiobox"><input type="radio" value="0" checked={val.view===0} onChange={(e)=>showRadio(e,val.seq,0)}/><span>비노출</span></label>                       
                            </td>
                        </tr>
                    </tbody>
                </table>
                ))} 
        </div>
    );
}

export default Bottom;