import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, useLocation  } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import './App.css';
import Login from "./page/Login"
import Member from "./page/Member/List"
import MemberPrev from "./page/Member/Prev"
import MemberInfo from "./page/Member/Info"
import Layout from './component/Layout';
import Component from './page/Component';
import NotFound from './page/NotFound';
import Banner from './component/Banner';
/* 교과관리 */
import CurriculumData from './page/Curriculum/Data';
import CurriculumDataForm from './page/Curriculum/DataForm';
import CurriculumSet1 from './page/Curriculum/Set1';
import CurriculumSet2 from './page/Curriculum/Set2';
import CurriculumSet3 from './page/Curriculum/Set3';
import CurriculumDatatype from './page/Curriculum/Datatype';
import CurriculumExcel from './page/Curriculum/Excel';
/* 수업 혁신 */
import ClassDictionary from './page/Class/Dictionary';
import ClassDictionaryForm from './page/Class/DictionaryForm';
import ClassMonth from './page/Class/Month';
import ClassMonthForm from './page/Class/MonthForm';
import ClassDictionaryExcel from './page/Class/DictionaryExcel';

import ClassPdf from './page/Class/Pdf';
import ClassUnit from './page/Class/Unit';
/* 창의적,수업연계,선생님맞춤 공통 카테고리별 메뉴 생성 게시판임. */
import Board from './component/Board';
import Category from './component/Category';
/* 고객센터 */
import CustomerNotice from './page/Customer/Notice';
import CustomerAlarm from './page/Customer/Alarm';
import CustomerEvent from './page/Customer/Event';
import CustomerQna from './page/Customer/Qna';
import CustomerFaq from './page/Customer/Faq';
import CustomerCategory from './page/Customer/Category';
/* 운영관리 */
import SettingMain from './page/Setting/Main';
import SettingTop from './page/Setting/Top';
import SettingBottom from './page/Setting/Bottom';
import SettingPopup from './page/Setting/Popup';
import SettingAgree from './page/Setting/Agree';
import SettingAgree1 from './page/Setting/Agree1';
import SettingAgree2 from './page/Setting/Agree2';
import SettingAgree3 from './page/Setting/Agree3';
import SettingAgree4 from './page/Setting/Agree4';
import SettingAgree5 from './page/Setting/Agree5';
import SettingManager from './page/Setting/Manager';

import * as api from "./config/api"
import TextEditor  from './page/TextEditor';

function Main() {
  const [submenu, setSubMenu] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [qna_count, setQnaCount] = useState(0);
  
  const [link_class, setLinkClass] = useState("/category/1");  
  const user = useSelector((state) => state.user);

  const onStrat = (token ,grade) => {
    //if(token){
      api.getFirstData("/admin/board/category", {
        type:-1
      },token).then(res=>{
        if(res && res?.data?.status==='SUCCESS'){

          if(res?.data?.data){
            setSubMenu(res.data.data);    
            const link_check = res.data.data?.filter((val)=>val.type === 1);
            if(link_check && link_check[0]?.categorySeq > 0){
                setLinkClass("/board/1/"+link_check[0]?.categorySeq+"/");
            }
            
          }
          api.getData("/admin/customer/qnatotal", {
          },token).then(res2=>{
            
            if(res2 && res2?.data?.status==='SUCCESS'){
              if(res2?.data?.data > 0){
                setQnaCount(res2.data.data);
              }
            }
          });
          if(grade === 0){
            navigate("/member/0");
          }else{
            navigate("/curriculum/native");
          }
         
        }
      });
      
    //}
    
  }

  useEffect(() => {
    
    if(!submenu){
      onStrat(user.token, user.grade);
    }
    
  }, []);

  return (
		<Routes>
          <Route path="/" element={<Login onStrat={onStrat}  />} />
          <Route path="/login" element={<Login onStrat={onStrat} />} />
          
          <Route element={<Layout submenu={submenu} setSubMenu={setSubMenu}  qna_count={qna_count} setQnaCount={setQnaCount} link_class={link_class}   />} >
            <Route path="/component" element={<Component />}></Route>
            <Route path="/member/:type" element={<Member />}  ></Route>
            <Route path="/member/:type/:id" element={<MemberInfo />}  ></Route>
            <Route path="/member_prev/:id" element={<MemberPrev />}  ></Route>
            <Route path="/curriculum/:type" element={<CurriculumData />}  ></Route>
            <Route path="/curriculum/:type/:id" element={<CurriculumDataForm />}  ></Route>
            <Route path="/curriculum/set1" element={<CurriculumSet1 />}  ></Route>
            <Route path="/curriculum/set2" element={<CurriculumSet2 />}  ></Route>
            <Route path="/curriculum/set3" element={<CurriculumSet3 />}  ></Route>
            <Route path="/curriculum/datatype" element={<CurriculumDatatype />}  ></Route>
            <Route path="/curriculum/excel" element={<CurriculumExcel />}  ></Route>

            <Route path="/class/month" element={<ClassMonth />}  ></Route>
            <Route path="/class/month/:id" element={<ClassMonthForm />}  ></Route>
            <Route path="/class/dictionary" element={<ClassDictionary />}  ></Route>
            <Route path="/class/dictionary/:id" element={<ClassDictionaryForm />}  ></Route>
            <Route path="/class/pdf" element={<ClassPdf />}  ></Route>
            <Route path="/class/unit" element={<ClassUnit />}  ></Route>
            <Route path="/class/excel" element={<ClassDictionaryExcel />}  ></Route>
            
            {/* creative, link, teacher 창의적체험, 수업연계, 선생님 맞춤 */}
    
            <Route path="/board/:category_type/:category_seq" element={<Board submenu={submenu} setSubMenu={setSubMenu} />}  ></Route>
            <Route path="/board/:category_type/:category_seq/:board_seq" element={<Board />}  ></Route>
            <Route path="/banner/:type" element={<Banner />}  ></Route>
            <Route path="/category/:category_type" element={<Category submenu={submenu} setSubMenu={setSubMenu} />}  ></Route>


            <Route path="/customer/notice" element={<CustomerNotice />}  ></Route>
            <Route path="/customer/alarm" element={<CustomerAlarm />}  ></Route>
            <Route path="/customer/event" element={<CustomerEvent />}  ></Route>
            <Route path="/customer/qna" element={<CustomerQna />}  ></Route>
            <Route path="/customer/faq" element={<CustomerFaq />}  ></Route>

            <Route path="/customer/notice/:id" element={<CustomerNotice />}  ></Route>
            <Route path="/customer/alarm/:id" element={<CustomerAlarm />}  ></Route>
            <Route path="/customer/event/:id" element={<CustomerEvent />}  ></Route>
            <Route path="/customer/qna/:id" element={<CustomerQna qna_count={qna_count} setQnaCount={setQnaCount}/>}  ></Route>
            <Route path="/customer/faq/:id" element={<CustomerFaq />}  ></Route>
            <Route path="/customer/category" element={<CustomerCategory />}  ></Route>

            <Route path="/setting/top" element={<SettingTop />}  ></Route>
            <Route path="/setting/main" element={<SettingMain />}  ></Route>
            <Route path="/setting/bottom" element={<SettingBottom />}  ></Route>
            <Route path="/setting/popup" element={<SettingPopup />}  ></Route>
            <Route path="/setting/agree" element={<SettingAgree />}  ></Route>
            <Route path="/setting/agree1" element={<SettingAgree1 />}  ></Route>
            <Route path="/setting/agree2" element={<SettingAgree2 />}  ></Route>
            <Route path="/setting/agree3" element={<SettingAgree3 />}  ></Route>
            <Route path="/setting/agree4" element={<SettingAgree4 />}  ></Route>
            <Route path="/setting/agree5" element={<SettingAgree5 />}  ></Route>
            <Route path="/setting/manager" element={<SettingManager />}  ></Route>
          </Route>
          <Route path="*" element={<NotFound />}></Route>        
		</Routes>
  );
}

export default Main;
