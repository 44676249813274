import React, { useEffect, useState, useRef } from 'react';
import { Routes, useNavigate, useLocation, Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import logo from '../asset/image/logo.png';
import * as api from "../config/api"
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../redux/userSlice.js";
import { clearUser } from "../redux/userSlice.js";

import Editor from "../component/Editor";

function Login(props) {
  const user = useSelector((state) => state.user);
  const [form_content, setFormContent] = useState("");
  const [form_id, setFormId] = useState("");
  const [form_pw, setFormPw] = useState("");
  const dispatch = useDispatch();

  const onLogin = () =>{
    api.signin(form_id,form_pw).then(res=>{
        let grade = 1;
        if(res?.data?.status === "SUCCESS"){

          dispatch(loginUser({
            name: res.data.data.name,
            id: form_id,
            type:res.data.data.type,
            token:{
              accessToken: res.data.data.accessToken,
              refreshToken: res.data.data.refreshToken
            },
            grade:res.data.data.grade
          }));
          grade = res.data.data.grade;
          const token =  {
            accessToken: res.data.data.accessToken,
            refreshToken: res.data.data.refreshToken
          };
          localStorage.setItem("access", res.data.data.accessToken);
          localStorage.setItem("grade", grade);
          props.onStrat(token,grade);
   
        }else{
          window.alert("아이디/비밀번호를 확인해주세요.");        
        }
    });
  }


  useEffect(()=>{
    localStorage.clear();
    dispatch(clearUser(user));
  },[])

  return (
    <>
    
         
    <div className="login">

        <h1><img src={logo} height="100"/></h1>
        <div>
            <label>ID</label>
            <input type="text" name="id" value={form_id} onChange={(e)=>setFormId(e.target.value)}/>
        </div>
        <div>
            <label>PASSWORD</label>
            <input type="password" name="pw" value={form_pw} onChange={(e)=>setFormPw(e.target.value)} />
        </div>
        
        <button type="button" onClick={()=>onLogin()}><FontAwesomeIcon icon={faRightToBracket} /> 로그인</button>

    </div>         
    </>
  );
}

export default Login;

