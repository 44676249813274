import React, { useEffect, useState, useRef } from 'react';
import { Outlet, BrowserRouter, Route, Routes, useNavigate, useLocation  } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import Footer from './Footer.js'
import Menu from './Menu.js'
import { navi } from './Config.js';

const Layout = (props) => {
    
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  useEffect(() => {

    if(user.token.accessToken == ''){
      navigate("/login");
    }
  
  }, []);

  return (
    <div>
        <Menu submenu={props.submenu} setSubMenu={props.setSubMenu} qna_count={props.qna_count} link_class={props.link_class}/>
        <div className="container">
            <main className="wrap">
                <div className="box">
                <Outlet />
                </div>
            </main>
            <Footer />
        </div>
    </div>
  );
};

export default Layout;