import React, {useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpDown } from "@fortawesome/free-solid-svg-icons";
import * as api from "../../config/api"

function Category() {
    //유저정보
    const user = useSelector((state) => state.user);

    const navigate = useNavigate();
    const [inputValue, setValue] = useState();
    const [list, setList] = useState([])

    const dragItem = useRef(); // 드래그할 아이템의 인덱스
    const dragOverItem = useRef(); // 드랍할 위치의 아이템의 인덱스


    // 드래그 시작될 때 실행
    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    // 드래그중인 대상이 위로 포개졌을 때
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    // 드랍 (커서 뗐을 때)
    const drop = (val,seq) => {
        const startSort = dragOverItem.current;
        const endSort = dragItem.current;
        const newList = [...list];
        const dragItemValue = newList[dragItem.current];
        newList.splice(dragItem.current, 1);       
        newList.splice(dragOverItem.current, 0, dragItemValue);
        dragItem.current = null;
        dragOverItem.current = null;
        
        if(startSort != endSort){
            setList(
                newList.map(val =>
                    val.faqCategorySeq === seq ? { ...val, seq: "move" } : val
                )
            );
            //드래그 순간 name이 같아져서 셀렉트 박스 체크 풀림 이동시 move처리 놓는 순간 다시 자기 번호로 변경
            setTimeout(()=>{
                setList(
                    newList.map(val =>
                        val.faqCategorySeq === seq ? { ...val, faqCategorySeq: seq } : val
                    )
                );
                
                api.putData("/admin/customer/faq/categorySort", {},{
                    ssort:startSort,
                    esort:endSort,
                    id:val.faqCategorySeq,
                    sort:(startSort+1)
                },user.token).then(res=>{
                    if(res){

                    }
                });
            },100)
        }
    };







    useEffect(() => {
        api.getData("/faq/category", {
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data);
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }            
            
            
        });

    }, []);

    const changeValue = (e,seq) => {
        setList(
            list.map(val =>
                val.faqCategorySeq === seq ? { ...val, name: e.target.value } : val
            )
        );
    }
    const onSave = (seq) => {
        api.postData("/admin/customer/faq/category", {
            name:inputValue,
            sort:(list.length+1)
        },user.token).then(res=>{
            setList([...list,res.data.data]);
            setValue("");
        });
    }
    const onModify = (val,seq) => {
        api.putData("/admin/customer/faq/category", {
            name:val.name,
            sort:val.sort
        },{
            id:seq
        },user.token).then(res=>{

        });        
    }

    const onRemove = (seq) => {
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/customer/faq/category", {
                id:seq
            }).then(res=>{
                setList(list.filter(val => val.faqCategorySeq !== seq));
            });
        }        
    }
    return (
        <div>
            <h1 className="head">FAQ 분류관리<button type="button" className="btn-primary">저장</button></h1>
            <div className="tab_box">
                <div className="tab_category dragndrop">
                    <dl>
                        <dt>
                            카테고리
                        </dt>

                        {list.map((val, index) => (
                        <dd 
                            className='show'
                            key={index}
                            draggable
                            onDragStart={(e) => dragStart(e, index,val.faqCategorySeq)}
                            onDragEnter={(e) => dragEnter(e, index,val.faqCategorySeq)}
                            onDragEnd={(e) => drop(val, val.faqCategorySeq)}

                            onDragOver={(e) => e.preventDefault()}
                        >
                            <i><FontAwesomeIcon icon={faUpDown} /></i>
                            <div>                       
                                <input type="text" value={val.name} onChange={(e)=>changeValue(e,val.faqCategorySeq)}/>
                                <button type="button" className="btn-info" onClick={()=>onModify(val,val.faqCategorySeq)}>수정</button>       
                                <button type="button" className="btn-danger" onClick={()=>onRemove(val.faqCategorySeq)}>삭제</button>       
                            </div>
                        </dd>
                        ))}

                        <dd className="show">
                            <i className="bean"></i>
                            <div>
                                <input type="text" value={inputValue} onChange={(e)=>setValue(e.target.value)} />                                   
                                <button type="button" className="btn-info" onClick={()=>onSave()}> 추가</button>
                            </div>
                        </dd>
                    </dl>
                </div>                
            </div>


        </div>
    );
}

export default Category;