import React, {useState, useEffect} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import * as api from "../../config/api"
import Pagination from 'react-js-pagination';
import Editor from "../../component/Editor";

function Notice() {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    //유저정보
    const user = useSelector((state) => state.user);

    //리스트 
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(20);

    //등록
    const [form_title, setFormTitle] = useState("");
    const [form_content, setFormContent] = useState("");
    const [form_option, setFormOption] = useState(false);
    const [form_date, setFormDate] = useState();

    const  onList = (page) => {

        api.getData("/notice",{
            page:page-1,
            size:limit
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);   
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });

    }

    const onSubmit = () => {
        if(id > 0 ){
            api.putData("/admin/customer/notice",{
                title:form_title,
                type:(form_option ? 1 : 0),
                contents:form_content,
            },{id:id},user.token).then(res=>{
                //console.log(res);
                if(res.data.status == "SUCCESS"){
                    goList();
                }
            });
        }else{
            api.postData("/admin/customer/notice",{
                title:form_title,
                type:(form_option ? 1 : 0),
                contents:form_content,
            },user.token).then(res=>{
                //console.log(res);
                if(res.data.status == "SUCCESS"){
                    goList();
                }
            });
        }
    }
    const onRemove = () =>{
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/customer/notice",{
                id:id
            },user.token).then(res=>{
                if(res.data.status == "SUCCESS"){
                    goList();
                }
            });
        }
    }
    const goList = () => {
        navigate("/customer/notice"); 
    }
    useEffect(() => {    
        if(id == 0){
            setFormDate("");
            setFormTitle("");
            setFormContent("");
            setFormOption("");
        }
        if(location?.state?.noticeSeq > 0){
            setFormDate(location.state.registDate);
            setFormTitle(location.state.title);
            setFormContent(location.state.contents);
            setFormOption(location.state.type);
        }else{
            onList(1)
        }
    }, [id]);
    return (
        <div className="normal">
            <div className={id==null ? "show" : "hide"}>
                <h1 className="head">공지사항<Link to="/customer/notice/0" className="btn-info btn-small">등록하기</Link></h1>           
                <table>
                    
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>등록일</th>
                            <th>제목</th>
                            <th>조회수</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((val,index)=>                     
                        <tr>
                            <td>{(total-index-(page*limit))}</td>
                            <td>{val.registDate.substr(0,16)}</td>
                            <td>{val.type ? '[필독]' : ''} <Link to={"/customer/notice/"+val.noticeSeq} state={val}>{val.title}</Link></td>
                            <td>{val.hit}</td>
                        </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4">
                                <div className="paging">
                                    <Pagination
                                        activePage={page+1}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={10}
                                        prevPageText={"◀"}
                                        nextPageText={"▶"}
                                        onChange={onList}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className={id>=0 ? "show" : "hide"}>
                <h1 className="head">공지사항 상세보기
                    {id > 0 && (
                        <button type="text" className="btn-danger btn-small" onClick={()=>onRemove()}>삭제하기</button>
                    )}
                </h1>
                <table className="detail">
                    <colgroup>
                        <col width="15%"/>
                        <col width="85%"/>
                    </colgroup>
                    <tbody>
                        {id > 0 && 
                        <tr>
                            <th>등록일시</th>
                            <td>{form_date}</td>
                        </tr>
                        }
                        <tr>
                            <th>제목</th>
                            <td><input type="text" placeholder="제목을 입력하세요" value={form_title} onChange={(e)=>setFormTitle(e.target.value)}/></td>
                        </tr>
                        <tr>
                            <th>옵션</th>
                            <td>
                                <label className="checkbox"><input type="checkbox" checked={form_option} onChange={(e)=>setFormOption(!form_option)} /><span>필독</span></label>
                            </td>
                        </tr>
                        <tr>
                            <th>상세내용</th>
                            <td>
                                <Editor 
                                    placeholder="내용을 입력하세요."
                                    value={form_content}
                                    setFormContent={setFormContent}
                                    folder="/board"
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2">
                                <button type="button" className="btn-primary" onClick={()=>onSubmit()}>{id > 0 ? '수정' : '저장'}</button>
                            </td>
                        </tr>
                    </tfoot>                    
                </table>
            </div>
        </div>
    );
}

export default Notice;