import React, {useState, useEffect} from 'react';
import noimage from '../asset/image/noimage300.jpg'
import * as api from "../config/api"

const ImageAdd = (props) => {
    const [file, setFile] = useState("");
    const _URL = window.URL || window.webkitURL;    
    const onChangeImageUpload = (e,seq) => {
        
        const {files} = e.target;
        const uploadFile = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onloadend = ()=> {
            var img = new Image();
            var objectUrl = _URL.createObjectURL(uploadFile);
            img.onload = function () {
                if(this.width==props.width && this.height == props.height){
                    setFile(reader.result);
                    props.setFormThumbnail(uploadFile);
                } else {
                    window.alert("이미지 사이즈는 "+props.width+"px * "+props.height+"px 으로 올려주세요.");
                }
                //window.alert(this.width + " " + this.height);
                _URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
            //window.alert(img.width);
 
            /*
            const lists = list.map(val => 
                val.seq === seq ? {...val, image: reader.result} : val
            )
            setList(lists);
            */
        }
    }
    
    useEffect(() => {
        
        if(props.form_orithumbnail && props.form_orithumbnail != ''){
            setFile(api.url+"/image/"+props.folder+"?file="+encodeURIComponent(props.form_thumbnail));
        }else{
            setFile("");
        }
    }, [props.id, props.folder, props.form_orithumbnail]);
    return (
        
        
        <label className="fileimgbox">
             <img src={file!=null && file!='' ? file : noimage} className={props.max ? 'max'+props.max : ''} width={props.width/props.resize} height={props.height/props.resize}/><input type="file" accept="image/*" onChange={(e)=>{onChangeImageUpload(e,0)}}/>
        </label>

    );
}
export default ImageAdd;