import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

function NotFound() {
    return (
      <div className="notfound">
        <div>
            <h1><FontAwesomeIcon icon={faTriangleExclamation} /> Oops!</h1>
            <p>404 - Page not found.</p>
        </div>
      </div>
    );
  }
  
  export default NotFound;
  