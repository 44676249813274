import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import SunEditor, {buttonList} from "suneditor-react"; 
import 'suneditor/dist/css/suneditor.min.css'; 

import * as api from "../config/api"


function Editor(props) {
    const user = useSelector((state) => state.user);
    const [imageFile, setImageFile] = useState("");
    const handleImageUploadBefore = (files, info, uploadHandler) => {
      setImageFile(files[0]);
        
      
      const formData = new FormData();
      formData.append("multipartFiles", files[0]);
  
      api.fileUpload("/admin/image",formData,user.token).then(res=>{
        const response = {
            result: [
              {
                url: res.data.data.body,
                name: files[0].name,
                size: files[0].size
              },
            ],
          };
    
          uploadHandler(response);
      });
      

  
      // return true를 반환해야지 함수가 종료된다.
      return undefined;
    }
    const  handleImageUpload = (targetImgElement, index, state, imageInfo) => {
      // targetImgElement : 업로드 이미지 데이터가 포함된 img 태그
      // index : 해당 이미지의 업로드 count
      // state : 이미지 업로드 상태 (create, update, delete)
      // imageInfo : 이미지 file 데이터
      console.log(imageInfo);
  
      
      if(state !== "delete") return;
      // 이미지 삭제 로직 추가
    }
    const editor = useRef();
  
    // The sunEditor parameter will be set to the core suneditor instance when this function is called
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };
    //http://suneditor.com/sample/html/options.html 옵션 추가 확인 
    return (
      <div className="">
        <SunEditor setOptions={{
          strictMode: false,
          addTagsWhitelist: "input|map|area",
          buttonList: [
          ["undo", "redo"],
          ["font", "fontSize", "formatBlock"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["fontColor", "hiliteColor"],
          ["outdent", "indent"],
          ["align", "horizontalRule", "list", "table"],
          ["link", "image", "video"],
          ["fullScreen", "showBlocks", "codeView"],
          ["preview", "print"]
        ],font: ["맑은고딕","굴림","바탕","Aria"]}}
            setContents={props.value}
            
            onChange={props.setFormContent}
          getSunEditorInstance={getSunEditorInstance}
          onImageUploadBefore={handleImageUploadBefore}
          height="500"
        />
      </div>
    );
  }
  
  export default Editor;
  