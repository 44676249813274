import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ImageAdd from "../../component/ImageAdd";
import * as api from "../../config/api"

function Top() {
    /*
    const [view, setView] = useState({
        url:'',
        show:1
    });
    <input type="text" value={view.url} onChange={(e)=>setView({...view, url:e.target.value})} />
    */
    //유저정보
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    
    //리스트 
    const [form_url, setFormUrl] = useState("");
    const [form_view, setFormView] = useState("");
    const [form_pc, setFormPc] = useState("");
    const [form_ori_pc, setFormOriPc] = useState("");
    const [form_mobile, setFormMobile] = useState("");
    const [form_ori_mobile, setFormOriMobile] = useState("");

    const saveTopBanner = () => {

        const formData = new FormData();
        
        formData.append("seq","1");
        formData.append("url",form_url);
        formData.append("view",form_view);
        formData.append("type",0);
        formData.append("pcs",form_pc);
        formData.append("mobiles",form_mobile);

        api.fileUpload("/admin/setting/banner",formData,user.token).then(res=>{
            if(res && res.data.status === "SUCCESS"){
                window.alert("저장 되었습니다.");
            }
        });
    }
    const removeTopBanner = () =>{
        if(window.confirm("정말로 삭제하시겠습니까?")){
            const formData = new FormData();
        
            formData.append("seq","1");
            formData.append("type","0");
            formData.append("url","");
            formData.append("view",0);
            formData.append("pc","");
            formData.append("mobile","");
    
            api.fileUpload("/admin/setting/banner",formData,user.token).then(res=>{
                setFormUrl("");
                setFormPc("");
                setFormOriPc("");
                setFormMobile("");
                setFormOriMobile("");
                setFormView(0);
            });
        }
    }
    
    useEffect(() => {
        api.getData("/admin/setting/banner",{
            type:0
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status==='SUCCESS'){
                setFormUrl(res.data.data[0]?.url);;
                setFormPc(res.data.data[0]?.pc);
                setFormOriPc(res.data.data[0]?.oriPc);
                setFormMobile(res.data.data[0]?.mobile);
                setFormOriMobile(res.data.data[0]?.oriMobile);
                setFormView(res.data.data[0]?.view);
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });
    }, []);
    return (
        <div className="normal">
            <h1 className="head">최상단 띠배너</h1>
            <table className="detail">

                <colgroup>
                    <col width="15%"/>
                    <col width="85%"/>
                </colgroup>                
                <tbody>
                    <tr>
                        <th>URL</th>
                        <td>
                            <input type="text" value={form_url} onChange={(e)=>setFormUrl(e.target.value)} />
                        </td>
                    </tr>
                    <tr>
                        <th>PC<br/><small>1920 * 60px</small></th>
                        <td>
                            <ImageAdd folder="banner" form_thumbnail={form_pc} setFormThumbnail={setFormPc} form_orithumbnail={form_ori_pc} width={1920} height={60} resize={1} max={1920} />
                        </td>
                    </tr>
                    <tr>
                        <th>Mobile<br/><small>390 * 60px</small></th>
                        <td>
                            <ImageAdd folder="banner" form_thumbnail={form_mobile} setFormThumbnail={setFormMobile} form_orithumbnail={form_ori_mobile}  width={390} height={60} resize={1}/>
                        </td>
                    </tr>
                    <tr>
                        <th>노출</th>
                        <td>
                            <label className="radiobox"><input type="radio" checked={form_view} onChange={(e)=>setFormView(1)}/><span>노출</span></label>
                            <label className="radiobox"><input type="radio" checked={!form_view} onChange={(e)=>setFormView(0)}/><span>비노출</span></label>                       
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="2">
                            <button type="button" className="btn-primary" onClick={(e)=>saveTopBanner()}>저장</button>      
                            <button type="button" className="btn-danger" onClick={(e)=>removeTopBanner()}>삭제</button>                            
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}

export default Top;