import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

import { clearUser } from "../../redux/userSlice.js";
import * as api from "../../config/api"

function Manager() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [password, setPassword] = useState("");
    const [new1_password, setNew1Password] = useState("");
    const [new2_password, setNew2Password] = useState("");
    const user = useSelector((state) => state.user);

    const onSave = () => {
        if(password==""){
            window.alert("현재 비밀번호를 작성해주세요.");
            return;
        }
        if(new1_password==""){
            window.alert("변경할 비밀번호를 작성해주세요.");
            return;
        }
        if(new1_password!=new2_password){
            window.alert("변경할 비밀번호를 확인해주세요.")
        }else{

            api.putData("/admin/pw",{
                pw:password,
                newPassword:new1_password,
                newPasswordChk:new2_password,
            },{id:user.id},user.token).then(res=>{
                if(res && res?.code === 'ERR_NETWORK'){
                    window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                    navigate("/")
                    return;
                }
                if(res && res.data.status == "SUCCESS"){       
                    window.alert("변경되었습니다. 다시 로그인해주세요.");
                    dispatch(clearUser(user));
                    navigate("/");
                }else{
                    window.alert("기존 비밀번호를 확인해주세요.");
                }
            });
        }
    }
    return (
        <div className="manager">
            <h1 className="head">관리자 비밀번호</h1>
            <table className="detail">
                <colgroup>
                    <col width="30%"/>
                    <col width="70%"/>
                </colgroup>
                <tbody>
                    <tr>
                        <th>현재 비밀번호</th>
                        <td><input type="password" onChange={(e)=>setPassword(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <th>변경할 비밀번호</th>
                        <td><input type="password" onChange={(e)=>setNew1Password(e.target.value)} /></td>
                    </tr>
                    <tr>
                        <th>변경할 비밀번호 재입력</th>
                        <td><input type="password" onChange={(e)=>setNew2Password(e.target.value)} /></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="2"><button type="button" className="btn-primary" onClick={()=>onSave()}>저장</button></td>
                    </tr>
                </tfoot>
                
            </table>
        </div>
    );
}

export default Manager;