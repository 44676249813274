import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

import * as api from "../../config/api"
import ExcelDownload from '../../component/ExcelDownload'
import Pagination from 'react-js-pagination';


import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function List() {  
    const navigate = useNavigate();
    const location = useLocation();

    const { type, id } = useParams();
    const types = ["회원목록","탈퇴회원"]
   
    //유저정보
    const user = useSelector((state) => state.user);
    //리스트 
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(20);

    //검색
    const today = new Date();    
    let [startDate, setStartDate] = useState();
    let [endDate, setEndDate] = useState();
    let [search_text, setSearchText] = useState();
    let [search_rtype, setSearchRtype] = useState(-1);
    let [search_grade, setSearchGrade] = useState(-1);

    const onStartDate = (date) => {
        setStartDate(date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : '');
    }
    const onEndDate = (date) => {
        setEndDate(date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : '');
    }
    const searchReset = () => {
        setStartDate();
        setEndDate();    
        setSearchText();    
        setSearchRtype(-1);        
        setSearchGrade(-1);
        search_rtype = "";
        startDate = "";
        endDate = "";
        search_text = "";
        search_grade = "";
        onList(1);
    }

    const  onList = (page) => {
        if(!isNaN(search_text)){
            if(search_text.length === 11 ){
                search_text = search_text.substring(0,3)+"-"+search_text.substring(3,7)+"-"+search_text.substring(7,11);
            }
            if(search_text.length === 10 ){
                search_text = search_text.substring(0,3)+"-"+search_text.substring(3,6)+"-"+search_text.substring(6,10);
            }
        }
        api.getData("/admin/members",{
            page:page-1,
            size:limit,
            status:type,
            rtype:(search_rtype == -1 ? "" : search_rtype),
            sdate:startDate,
            edate:endDate,
            text:search_text,
            grade:(search_grade == -1 ? "" : search_grade)
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res?.data?.status==='SUCCESS'){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);   
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });
    }

    
    useEffect(() => {
        if(user?.grade === 0){
            onList(1)    
        }else{
            window.alert("접근 불가능한 페이지입니다.");
            navigate("/curriculum/native");
        }
               
    }, [type]);
    
    const onName = (txt) => {
        const name = api.b64DecodeUnicode((txt));
        
        if (name.length === 1) {
            return name;
        } else if (name.length === 2) {
            return name.slice(0, 1) + '*';
        } else {
            return name.slice(0, 1) + '*'.repeat(name.length - 2) + name.slice(-1);
        }
        
    }
    const onPhone = (txt) => {
        const phone =api.b64DecodeUnicode((txt));
        return phone.substring(0, 3) + '-****-' + phone.substring(9);
    }
    const onSchool = (txt) => {
        const school =api.b64DecodeUnicode((txt));
        let star= '';
        for(let i=0; i<school.length;i++){
            star += '*';
        }
        return star;
    }

    return (
        <div className="normal">
            <div >
                <h1 className="head">{types[type]}</h1>        
                <div className="search" name="searc">
                    <form>
                        <div className="wrap_search">
                            <div className="search_group">
                                <label className="title">등록일</label>
                                <div className="box_search">
                                    <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date) => onStartDate(date)} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>
                                    <span className="box_wave">~</span>
                                    <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={endDate} onChange={(date) => onEndDate(date)} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>                                
                                </div>
                            </div>
                            <div className="search_group">
                                <label className="title">검색</label>
                                <div className="box_search"><input type="text" placeholder="이메일, 이름, 핸드폰번호, 담당과목"  onChange={(e)=>setSearchText(e.target.value)}/></div>
                            </div>
                            <div className="search_group">
                                <label className="title">가입유형</label>
                                <div className="box_search">
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearchRtype(-1)} checked={search_rtype==-1} defaultChecked  /><span>전체</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearchRtype(1)} checked={search_rtype==1}/><span>네이버</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearchRtype(2)} checked={search_rtype==2}/><span>카카오톡</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearchRtype(0)} checked={search_rtype==0}/><span>이메일</span></label>
                                </div>
                            </div>
                            <div className="search_group">
                                <label className="title">회원등급</label>
                                <div className="box_search">
                                    <select onChange={(e)=>setSearchGrade(e.target.value)}>
                                        <option value="-1">전체</option>
                                        <option value="0">일반</option>
                                        <option value="1">선생님</option>
                                        <option value="2">편집자</option>
                                        <option value="3">영업</option>
                                        <option value="8">관리자</option>
                                        <option value="9">최고관리자</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="reset" className="btn-off" onClick={(e)=>searchReset()}>초기화</button>
                            <button type="button" className="btn-primary" onClick={(e)=>onList(1)}>검색</button>
                        </div>
                    </form>            
                </div>
                <h1 className="head">목록<span>검색결과<strong className="text-danger">234</strong>개</span>
                <ExcelDownload  
                    thead={['No','등록일','계정','이름','핸드폰','회원등급','학교명','1학년','2학년','3학년','담당과목','근무지역','sms마케팅 수신 동의','email마케팅 수신 동의']} 
                    api="/member/excel" 
                    name="회원목록" 
                    get={{status:0}} 
                />
                </h1>        
                <table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>등록일</th>
                            <th>가입유형</th>
                            <th>계정</th>
                            <th>이름</th>
                            <th>핸드폰</th>
                            <th>회원등급</th>
                            <th>학교명</th>
                            <th>담당학년</th>
                            <th>담당과목</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((val,index)=>        
                        <tr>
                            <td>{(total-index-(page*limit))}</td>
                            <td>{val?.registDate?.substr(0,16)}</td>
                            <td>{api.registType[val.registType]}</td>
                            <td><Link to={"/member/"+type+"/"+val.userSeq} state={val}>{val.account ? val.account : '이메일 없음'}</Link></td>
                            <td>{onName(val.name)}</td>
                            <td>{val.phone ? onPhone(val.phone) : '-'}</td>
                            <td>{api.memberGrade[val.grade]}</td>
                            <td>{val.school ? onSchool(val.school) : '-'}</td>
                            <td>
                                {!val.class1 && !val.class2 && !val.class3 ? '-' :
                                val.class1 ? '1학년' : ''} {(val.class1 && (val.class2 || val.class3)) ? ' / ' : ''} {val.class2 ? '2학년' : ''} {(val.class2 && val.class3) ? ' / ' : ''} {val.class3 ? '3학년' : ''}
                            </td>
                            <td>{val.subject ? val.subject : '-'}</td>
                        </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="10">
                                <div className="paging">
                                    <Pagination
                                        activePage={page+1}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={10}
                                        prevPageText={"◀"}
                                        nextPageText={"▶"}
                                        onChange={onList}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

export default List;