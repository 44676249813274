import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import noimage from '../../asset/image/noimage300.jpg'
import ImageAdd from "../../component/ImageAdd";
import * as api from "../../config/api"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

function Popup() {
    //유저정보
    const user = useSelector((state) => state.user);
    const [list, setList] = useState([ ]);
    const navigate = useNavigate();

    const oncImageUpload = (e,seq) => {
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, image: e } : val
            )
        );  
    }

    const savePopup = (val) =>{
        const formData = new FormData();
       
            formData.append("seq",val.seq);
       
        
        formData.append("url",val.url);
        formData.append("sdate",val.sdate);
        formData.append("edate",val.edate);
        formData.append("view",val.view);
        formData.append("images",val.image);
        

        api.fileUpload("/admin/setting/popup",formData,user.token).then(res=>{
            if(val.seq <= 0){
                const dd = res.data.data;
                setList(
                    list.map(par =>
                        par.seq === val.seq ? { ...par, 
                            image:  dd.image,
                            oriImage:  dd.oriImage,
                            seq:  dd.seq,
                            url:  dd.url,
                            sdate:  dd.sdate,
                            edate:  dd.edate,
                            view:  dd.view,
                        } : par
                    )
                );
                window.alert("저장 되었습니다.")
            }else{
                window.alert("수정 되었습니다.")
            }
        });
    }
    const removePopup = (par) =>{
        if(par.seq > 0 ){
            if(window.confirm("정말로 삭제하시겠습니까?")){
                
                api.deleteData("/admin/setting/popup",{
                    id:par.seq
                },user.token).then(res=>{
                    setList(
                        list.filter(val =>
                            val.seq !== par.seq
                        )
                    );
                });
            }
        }else{
            setList(
                list.filter(val =>
                    val.seq !== par.seq
                )
            );
        }            
    }
    const urlTitle = (e,seq) => {
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, url: e.target.value } : val
            )
        );
    }
    const showRadio = (e,seq,chk) => {
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, view: chk } : val
            )
        );
    }
    const add = () => {
        const num =  Math.floor(Math.random() * 100);
        const date = new Date();
        const newItem = {
            seq:-num,
            image:'',
            images:'',
            oriImage:'',
            url:'',
            sdate:(date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2)+" 00:00:00"),
            edate:(date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2)+" 00:00:00"),
            view:1
        }
        setList([newItem, ...list]);
    }

    const onDateChange = (date,par,type) => {
        const dd = (date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2))
        setList(
            list.map(val =>
                val.seq === par.seq ? { ...val, [type]: (dd+" "+par[type].substr(11,2)+":"+par[type].substr(14,2)+":00") } : val
            )
        );
    }
    const onHourChange = (time,par,type) => { 
        setList(
            list.map(val =>
                    val.seq === par.seq ? { ...val, [type]: (par[type].substr(0,10)+" "+time+":"+par[type].substr(14,2)+":00") } : val
            )
        );
    }
    const onMinChange = (time,par,type) => {
        setList(
            list.map(val =>
                val.seq === par.seq ? { ...val, [type]: (par[type].substr(0,10)+" "+par[type].substr(11,2)+":"+time+":00") } : val
            )
        );
    }

    function hour(selected){
        let arr = [];
        for(let i=0;i<24;i++){
            let ii =("00"+i.toString()).slice(-2)
            arr.push(<option value={ii} selected={selected==ii}>{ii}</option>);
        }
        return arr;
    }
    function min(selected){
        let arr = [];
        for(let i=0;i<60;i++){
            let ii =("00"+i.toString()).slice(-2)
            arr.push(<option value={ii} selected={selected==ii}>{ii}</option>);
        }
        return arr;
    }

    useEffect(() => {
        api.getData("/admin/setting/popup",{},user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data)
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });
    }, []);

    return (
        <div className="normal">
            <h1 className="head">팝업관리<button type="button" className="btn-info btn-small" onClick={()=>add()}>추가</button></h1>

                {list.map((val, index) => (
                <table className="detail">
                    <colgroup>
                        <col width="15%"/>
                        <col width="85%"/>
                    </colgroup>                       
                    <tbody>
                        <tr>
                            <th>URL</th>
                            <td className="flex">
                                <input type="text" value={val.url} onChange={(e)=>urlTitle(e,val.seq)} />
                                <div>
                                    <button type="button" className={val.seq > 0 ? 'btn-success' : 'btn-primary'} onClick={(e)=>savePopup(val)}>{val.seq > 0 ? '수정' : '저장'}</button>      
                                    <button type="button" className="btn-danger" onClick={(e)=>removePopup(val)}>삭제</button>     
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>팝업이미지<br/><small>646 * 649px</small></th>
                            <td>
                                <ImageAdd folder="banner" form_thumbnail={val.image} setFormThumbnail={(e)=>{oncImageUpload(e,val.seq)}} form_orithumbnail={val.oriImage}  width={646} height={649} resize={3} id={val.seq ? val.seq : 0}/>
                            </td>
                        </tr>
                        <tr>
                            <th>게재시간</th>
                            <td>
                                <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={val.sdate.substr(0,10)} onChange={(date) => onDateChange(date,val,'sdate')} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>
                                
                                <select className="hour" onChange={(e) => onHourChange(e.target.value,val,'sdate')}>
                                    {hour(val.sdate.substr(11,2))}
                                </select>

                                <select className="min" onChange={(e) => onMinChange(e.target.value,val,'sdate')}>
                                    {min(val.sdate.substr(13,2))}
                                </select>
                                <span className="box_wave">~</span>
                                <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={val.edate.substr(0,10)} onChange={(date) => onDateChange(date,val,'edate')} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>
                                <select className="hour" onChange={(e) => onHourChange(e.target.value,val,'edate')}>
                                    {hour(val.edate.substr(11,2))}
                                </select>
                                <select className="min" onChange={(e) => onMinChange(e.target.value,val,'edate')}>
                                    {min(val.edate.substr(13,2))}
                                </select>                     
                            </td>
                        </tr>
                        <tr>
                            <th>노출</th>
                            <td>
                                <label className="radiobox"><input type="radio" name={"add"+val.seq} value="1" checked={val.view===1} onChange={(e)=>showRadio(e,val.seq,1)}/><span>노출</span></label>
                                <label className="radiobox"><input type="radio" name={"add"+val.seq} value="0" checked={val.view===0} onChange={(e)=>showRadio(e,val.seq,0)}/><span>비노출</span></label>                       
                            </td>
                        </tr>
                    </tbody>
                </table>
                ))} 
        </div>
    );
}

export default Popup;