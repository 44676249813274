import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import noimage from '../asset/image/noimage300.jpg'
function Component() {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    return (
        <div>   

            
            <strong className="o">●</strong><br/>
            <strong className="x">x</strong>
            <br/><br/>
            <button type="button" className="btn-primary">버튼</button>
            <button type="button" className="btn-info">버튼</button>
            <button type="button" className="btn-success">버튼</button>
            <button type="button" className="btn-warning">버튼</button>
            <button type="button" className="btn-danger">버튼</button>
            <button type="button" className="btn-off">버튼</button><br/><br/>
            <button type="button" className="btn-primary btn-small">버튼</button>
            <button type="button" className="btn-info btn-small">버튼</button>
            <button type="button" className="btn-success btn-small">버튼</button>
            <button type="button" className="btn-warning btn-small">버튼</button>
            <button type="button" className="btn-danger btn-small">버튼</button>
            <button type="button" className="btn-off btn-small">버튼</button><br/><br/>

            <label className="checkbox"><input type="checkbox" /><span>체크박스</span></label>
            <label className="radiobox"><input type="radio" /><span>라디오박스</span></label>
            <label className="togglebox"><input type="checkbox" /></label><br/><br/>

            <label className="fileimgbox"><img src={noimage} width="216" height="150"/><input type="file"  accept="image/*" /></label>


            <ul className="categorybox">
                <li>국어 &gt; 중학 국어1 <button type="button">x</button></li>
                <li>국어 &gt; 중학 국어1 <button type="button">x</button></li>
            </ul>

            <div className="filebox">
                <label>
                    <input type="file" />
                    <span>파일첨부</span>
                </label>
                <p>파일명.zip</p>
                <button type="button">x</button>
            </div>
            <div className="filebox _multi">
                <label>
                    <input type="file" />
                    <span>파일첨부</span>
                </label>
                <p>파일명.zip</p>
                <button type="button">x</button>
            </div>

            <div className="tab">
                <button type="button" className="active">국어</button>
                <button type="button">사회</button>
                <button type="button">미술</button>
            </div>

            <form className="search"> 
                <div className="wrap_search">
                    <div className="search_group">
                        <label className="title">등록일</label>
                        <div className="box_search">
                            <DatePicker dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date) => setStartDate(date)} />
                            <span className="box_wave">~</span>
                            <DatePicker dateFormat="yyyy-MM-dd" selected={endDate} onChange={(date) => setEndDate(date)} />
                        </div>
                    </div>
                    <div className="search_group">
                        <label className="title">검색</label>
                        <div className="box_search"><input type="text" /></div>
                    </div>
                    <div className="search_group">
                        <label className="title">검색</label>
                        <div className="box_search">
                            <select>
                                <option value="">전체</option>
                            </select>
                        </div>
                    </div>
                    <div className="search_group">
                        <label className="title">검색</label>
                        <div className="box_search">
                            <label className="checkbox"><input type="checkbox" /><span>체크박스1</span></label>
                            <label className="checkbox"><input type="checkbox" /><span>체크박스2</span></label>
                            <label className="checkbox"><input type="checkbox" /><span>체크박스2</span></label>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <button type="button" className="btn-off">초기화</button>
                    <button type="button" className="btn-primary">검색</button>
                </div>
            </form>
            <h1 className="head">테이블 제목<span>검색결과<strong>234</strong></span><button type="button" className="btn-success">엑셀다운로드</button></h1>
            <table>
                <thead>
                    <tr>
                        <th>제목1</th>
                        <th>제목2</th>
                        <th>제목2</th>
                        <th>제목2</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>내용 1</td>
                        <td>내용 2</td>
                        <td>내용 2</td>
                        <td>내용 2</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="4">
                            <a class="prev">◀</a>
                            <a class="active">1</a>
                            <a>2</a>
                            <a>3</a>
                            <a>4</a>                      
                            <a class="next">▶</a>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    
    );
}

export default Component;