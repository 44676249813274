import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

import * as api from "../../config/api"
import ExcelDownload from '../../component/ExcelDownload'
import Pagination from 'react-js-pagination';


import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function List() {  
    const navigate = useNavigate();
    const location = useLocation();

    const { type, id } = useParams();
    const types = ["회원목록","탈퇴회원"]
   
    //유저정보
    const user = useSelector((state) => state.user);
    //리스트 
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(20);

    //검색
    const today = new Date();    
    let [startDate, setStartDate] = useState();
    let [endDate, setEndDate] = useState();
    let [search_text, setSearchText] = useState();
    let [search_rtype, setSearchRtype] = useState(-1);
    let [search_grade, setSearchGrade] = useState(-1);

    const onStartDate = (date) => {
        setStartDate(date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : '');
    }
    const onEndDate = (date) => {
        setEndDate(date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : '');
    }
    const searchReset = () => {
        setStartDate();
        setEndDate();    
        setSearchText();    
        setSearchRtype(-1);        
        setSearchGrade(-1);
        search_rtype = "";
        startDate = "";
        endDate = "";
        search_text = "";
        search_grade = "";
        onList(1);
    }

    const  onList = (page) => {
        if(!isNaN(search_text)){
            if(search_text.length === 11 ){
                search_text = search_text.substring(0,3)+"-"+search_text.substring(3,7)+"-"+search_text.substring(7,11);
            }
            if(search_text.length === 10 ){
                search_text = search_text.substring(0,3)+"-"+search_text.substring(3,6)+"-"+search_text.substring(6,10);
            }
        }
        api.getData("/admin/members_prev",{
            page:page-1,
            size:limit,
            text:search_text
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res?.data?.status==='SUCCESS'){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);   
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });
    }

    
    useEffect(() => {
        if(user?.grade === 0){
            onList(1)    
        }else{
            window.alert("접근 불가능한 페이지입니다.");
            navigate("/curriculum/native");
        }  
    }, [type]);
    
    const onMemberPrev = (seq) => {
        if(window.confirm("인증전으로 돌아갑니다.\n이전 회원이 로그인시 재인증을 받을 수 있습니다.\n가입이 이미 된 경우는 이전이 불가능합니다.\n\n인증전으로 수정하시겠습니까?")){
            api.putData("/admin/member_prev", {
            },{
                seq:seq
            },user.token).then(res=>{

                if(res && res?.data?.status==='SUCCESS'){
                    setList(
                        list.map(val =>
                            val.seq === seq ? { ...val, check: 0 } : val
                        )
                    );
                }
            });
        }
    }
    const onMemberPrev2 = (seq) => {
        if(window.confirm("실제 회원이 등록되거나 본인인증이 되진 않습니다.\n\n인증한 회원으로 변경하시겠습니까?")){
            api.putData("/admin/member_prev2", {
            },{
                seq:seq
            },user.token).then(res=>{
    
                if(res && res?.data?.status==='SUCCESS'){
                    setList(
                        list.map(val =>
                            val.seq === seq ? { ...val, check: 1 } : val
                        )
                    );
                }
            });
        }
        

    }
    const onMemberPrevModify = (val) => {
        api.putData("/admin/member_prev3", {
            name:val.name,
            phone:val.phone
        },{
            seq:val.seq
        },user.token).then(res=>{
            if(res?.data?.status == "SUCCESS"){
                window.alert("수정되었습니다.");
            }   
        });
    }

    const onNameChange = (e,seq) => {
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, name: e.target.value } : val
            )
        );
    }
    const onPhoneChange = (e,seq) => {
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, phone: e.target.value } : val
            )
        );
    }
    return (
        <div className="normal">
            <div >
                <h1 className="head">{types[type]}</h1>        
                <div className="search" name="searc">
                    <form>
                        <div className="wrap_search">
                            <div className="search_group">
                                <label className="title">검색</label>
                                <div className="box_search"><input type="text" placeholder="이메일"  onChange={(e)=>setSearchText(e.target.value)}/></div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="reset" className="btn-off" onClick={(e)=>searchReset()}>초기화</button>
                            <button type="button" className="btn-primary" onClick={(e)=>onList(1)}>검색</button>
                        </div>
                    </form>            
                </div>
                <h1 className="head">목록<span>검색결과<strong className="text-danger">234</strong>개</span></h1>        
                <table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>등록일</th>
                            <th>등급</th>
                            <th>계정</th>
                            <th>이름</th>
                            <th>핸드폰</th>
                            <th>학교</th>
                            <th width="140">이전</th>
                            <th width="130">버튼</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((val,index)=>        
                        <tr className={val.check ? 'prev' : ''}>
                            <td>{(total-index-(page*limit))}</td>
                            <td>{val?.registDate}</td>
                            <td>{(val.grade)}</td>
                            <td>{val.account ? val.account : '계정 없음'}</td>
                            <td>
                                {val.check ? 
                                val.name :                                
                                <input type="text" value={(val.name)} onChange={(e)=>onNameChange(e,val.seq)} />
                                }
                            
                            </td>
                            <td>
                                {val.check ? 
                                val.phone :
                                <input type="text" value={(val.phone)} onChange={(e)=>onPhoneChange(e,val.seq)} />
                                }
                            </td>
                            <td>{val.school ? (val.school) : '-'}</td>
                            <td>{val.check ? 
                            <button type="button" className="btn-success btn-small" onClick={()=>onMemberPrev(val.seq)}>되돌리기</button>
                             : 
                            <button type="button" className="btn-warning btn-small" onClick={()=>onMemberPrev2(val.seq)}>인증전</button>
                            }</td>
                            <td>
                                {val.check ? 
                                <></>
                                : 
                                <button type="button" className="btn-info btn-small" onClick={()=>onMemberPrevModify(val)}>수정</button>
                                }
                            </td>
                        </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="10">
                                <div className="paging">
                                    <Pagination
                                        activePage={page+1}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={10}
                                        prevPageText={"◀"}
                                        nextPageText={"▶"}
                                        onChange={onList}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

export default List;