import React, {useState, useEffect} from 'react';

const FileAdd = (props) => {
    const [file, setFile] = useState("");
    const onFileUpload = (e,seq) => {
        const aa = e.target.name;
        const {files} = e.target;
        const uploadFile = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(uploadFile);
        reader.onloadend = ()=> {
            //setFile(uploadFile.name);
            setFile(uploadFile.name);
            props.setFormFile(uploadFile);
            /*
            const lists = list.map(val=>
                val.seq ===seq ? {...val, file:uploadFile.name} : val
            )
            setList(lists);
            */
        }
    }

    const removeFile = (seq) => {
        //setFile("");
        setFile("");
        props.setFormFile("");
        /*
        const lists = list.map(val=>
            val.seq ===seq ? {...val, file:''} : val
        )
        setList(lists);
        */
    } 
        
    useEffect(() => {
        if(props.form_orifile != ''){
            const ff = props?.form_orifile?.split("/");            
            if(ff?.length >= 1 ){
                setFile(ff[ff.length - 1]);
            }else{
                setFile(props?.form_orifile);
            }
        }else{
            setFile("");
        }
    }, [props.form_orifile,props.id]);
    return (
        <div className="filebox">
            <label>
                <input type="file" onChange={(e)=>{onFileUpload(e,0)}} />
                <span>파일첨부</span>
            </label>
            <p>{file}</p>
            <button className={file!=null && file!='' ? 'show' : 'hide'} type="button" onClick={(e)=>removeFile(0)}>삭제</button>
        </div>
    );
}
export default FileAdd;