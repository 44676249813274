import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";

import * as api from "../../config/api"

function Excel() {
  
    const user = useSelector((state) => state.user);

    const [file, setFile] = useState("");
    const [upfile, setUpFile] = useState([]);
    const [list, setList] = useState([]);
    const onFileUpload = (e) => {

        const {files} = e.target;
        const uploadFile = files[0];

        setFile(uploadFile.name);
        setUpFile(uploadFile);
        const formData = new FormData();

        formData.append("excel",uploadFile);
        formData.append("type",0);

        api.fileUpload("/admin/excel/upload",formData,user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){

            } else {
                if(res?.data){
                    setList(res.data);
                }
            }
        });
    
    }
    const onSave = () => {
        const formData = new FormData();
        formData.append("excel",upfile);
        formData.append("type",1);

        api.fileUpload("/admin/excel/upload",formData,user.token).then(res=>{
            setList([]);
            setFile("");
            setUpFile([]);
        });
    }
    
    const onFileChange = (e) => {
        setList([]);
        setFile("");
        setUpFile([]);
        e.target.value=null;
    }



    return (
        <div className="normal">
           
            <div>
                <h1 className="head _02">
                    <div className="filebox">
                        <label>
                            <input type="file" onChange={(e)=>{onFileUpload(e)}} onClick={(e)=>onFileChange(e)} />
                            <span>엑셀파일 업로드</span>
                        </label>
                        <p>{file}</p>
                    </div>                     
                </h1>    
                <div className="excel_scroll">
                    <table className="detail">
                        <thead>
                            <tr>
                                <th>과목</th>
                                <th>학급</th>
                                <th>교과설정<br/>번호</th>
                                <th>교과설정<br/>텍스트</th>
                                <th>개정연도<br/>번호</th>
                                <th>개정연도<br/>텍스트</th>
                                <th>단원<br/>번호</th>
                                <th>단원<br/>텍스트</th>
                                <th>단원_소단원<br/>번호</th>
                                <th>단원_소단원<br/>텍스트</th>
                                <th>자료유형<br/>번호</th>
                                <th>자료유형<br/>텍스트	</th>
                                <th>자료유형 소단원<br/>번호</th>
                                <th>자료유형 소단원<br/>텍스트</th>
                                <th>단원공통자료</th>
                                <th>교과서</th>
                                <th>지도서</th>
                                <th>모의평가</th>
                                <th>자료명</th>
                                <th>파일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map(val=>
                            <tr>
                                <td>{val.subject}</td>
                                <td>{val.school}</td>
                                <td>{val.subjectSeq}</td>
                                <td>{val.subjectText}</td>
                                <td>{val.yearSeq}</td>
                                <td>{val.yearText}</td>
                                <td>{val.unitSeq}</td>
                                <td>{val.unitText}</td>
                                <td>{val.unitLevelSeq}</td>
                                <td>{val.unitLevelText}</td>
                                <td>{val.typeSeq}</td>
                                <td>{val.typeText}</td>
                                <td>{val.typeLevelSeq}</td>
                                <td>{val.typeLevelText}</td>
                                <td>{val.type1}</td>
                                <td>{val.type2}</td>
                                <td>{val.type3}</td>
                                <td>{val.type4}</td>
                                <td>{val.name}</td>
                                <td>{val.file}</td>
                            </tr>
                            )}

                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="20">
                                    <button type="button" className="btn-primary" onClick={()=>onSave()}>저장</button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>   
                </div>              
            </div>
        </div>
    );
}

export default Excel;