import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import Editor from "../../component/Editor";
import FileAdd from "../../component/FileAdd";
import ImageAdd from "../../component/ImageAdd";
import * as api from "../../config/api"
import Pagination from 'react-js-pagination';

function Qna(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    //유저정보
    const user = useSelector((state) => state.user);

    //리스트 
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(20);

    //검색
    const today = new Date();    
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [search_text, setSearchText] = useState("");
    const [search_status, setSearchStatus] = useState(-1);

    //등록
    const [form_answer, setFormAnswer] = useState("");
    const [form_file1, setFormFile1] = useState("");
    const [form_orifile1, setFormOriFile1] = useState("");
    const [form_file2, setFormFile2] = useState("");
    const [form_orifile2, setFormOriFile2] = useState("");
    const [form_file3, setFormFile3] = useState("");
    const [form_orifile3, setFormOriFile3] = useState("");
    const [form_status, setFormStatus] = useState(0);
    const [form_fstatus, setFormFStatus] = useState(0);
    const [form_member, setForMember] = useState({});
    

    
    const onStartDate = (date) => {
        setStartDate(date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : '');
    }
    const onEndDate = (date) => {
        setEndDate(date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : '');
    }
    const searchReset = () => {
        setStartDate();
        setEndDate();    
        setSearchText(); 
        
        onList(1);
    }


    const  onList = (page) => {

        api.getData("/admin/customer/qna",{
            page:page-1,
            size:limit
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);   
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });

    }

    const onSubmit = () => {
        const formData = new FormData();
    
        formData.append("qnaSeq",id);
        formData.append("file1",form_orifile1);
        formData.append("file2",form_orifile2);
        formData.append("file3",form_orifile3);
        formData.append("answerStatus",form_status);
        formData.append("answer",form_answer);

        api.fileUpload("/admin/customer/qna",formData,user.token).then(res=>{
            if(form_status){
                let count = props?.qna_count - 1;
                if(count <= 0){
                    count = 0;
                }
                props?.setQnaCount(count);
            }else{
                if(form_fstatus){
                    let count = props?.qna_count + 1;
                    props?.setQnaCount(count);
                }
            }
            goList();
        });
    
    }
    const onRemove = () =>{
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/customer/qna",{
                id:id
            },user.token).then(res=>{
                if(res.data.status == "SUCCESS"){
                    goList();
                }
            });
        }
    }
    const goList = () => {
        navigate("/customer/qna"); 
    }
    useEffect(() => {    
        if(id == 0){
            setFormAnswer("");
            setFormFile1("");
            setFormOriFile1("");
            setFormFile2("");
            setFormOriFile2("");
            setFormFile3("");
            setFormOriFile3("");
            setFormStatus("");
            setFormFStatus(false);
        }
        if(location?.state?.qnaSeq > 0){

            api.getData("/admin/member",{
                memberSeq:location.state.memberSeq
            },user.token).then(res=>{
                if(res && res?.data?.status==='SUCCESS'){
                    setForMember(res.data.data);
                }
            });


            setFormAnswer(location.state.answer);
            setFormFile1(location.state.answerFile1);
            setFormOriFile1(location.state.answerOriFile1);
            setFormFile2(location.state.answerFile2);
            setFormOriFile2(location.state.answerOriFile2);
            setFormFile3(location.state.answerFile3);
            setFormOriFile3(location.state.answerOriFile3);
            setFormStatus(location.state.answerStatus);
            setFormFStatus(location.state.answerStatus);
        }else{
            onList(1);
        }
    }, [id]);

    
    const FileDownload = (file,oriFile) => {

        api.blobData("/admin/download" ,{
            filename:encodeURIComponent(file),
            folder:"board"
        },user.token).then(res=>{

            if(res && res.data){
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download",`${oriFile}`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
               

            }
        });
    }

    return (
        <div className="normal">
            <div className={id==null ? "show" : "hide"}>
                <h1 className="head">문의관리</h1> 
                <form className="search" name="searc" method="get">
                    
                    <div className="wrap_search">
                        <div className="search_group">
                            <label className="title">등록일</label>
                            <div className="box_search">
                                <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date) => onStartDate(date)} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>
                                <span className="box_wave">~</span>
                                <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={endDate} onChange={(date) => onEndDate(date)} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>                                
                            </div>
                        </div>
                        <div className="search_group">
                            <label className="title">검색</label>
                            <div className="box_search">
                                <input type="text" placeholder="이름, 아이디, 연락처" value={search_text} onChange={(e)=>setSearchText(e.target.value)}/>
                            </div>
                        </div>
                        <div className="search_group">
                            <label className="title">상태</label>
                            <div className="box_search">
                                <label className="radiobox"><input type="radio" checked={search_status==-1 ? true : false} onClick={(e)=>setSearchStatus(-1)} /><span>전체</span></label>
                                <label className="radiobox"><input type="radio" checked={search_status==1 ? true : false} onClick={(e)=>setSearchStatus(1)}/><span>미처리</span></label>
                                <label className="radiobox"><input type="radio" checked={search_status==0 ? true : false} onClick={(e)=>setSearchStatus(0)}/><span>처리완료</span></label>
                            </div>
                        </div>
                        <div className="search_group">
                            <label className="title">문의유형</label>
                            <div className="box_search">
                                <select onChange={(e)=>setSearchStatus(e.target.value)}>
                                    <option value="문의 유형 선택">문의 유형 선택</option>
                                    <option value="교과서">교과서</option>
                                    <option value="참고서">참고서</option>
                                    <option value="이벤트">이벤트</option>
                                    <option value="오류신고">오류신고</option>
                                    <option value="제안 및 개선사항">제안 및 개선사항</option>
                                    <option value="기타">기타</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <button type="reset" className="btn-off" onClick={(e)=>searchReset()}>초기화</button>
                        <button type="button" className="btn-primary" onClick={(e)=>onList(1)}>검색</button>
                    </div>            
                </form>
                <h1 className="head">목록<span>검색결과<strong className="text-danger">{total}</strong>개</span></h1>        
                <table>
                    
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>등록일</th>
                            <th>작성자</th>
                            <th>연락처</th>
                            <th>문의유형</th>
                            <th>문의내용</th>
                            <th>처리</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((val,index)=>                     
                        <tr>
                            <td>{(total-index-(page*limit))}</td>
                            <td>{val.registDate}</td>
                            <td>{api.b64DecodeUnicode(val.memberName)}</td>
                            <td>{api.b64DecodeUnicode(val.memberPhone)}</td>
                            <td>{val.type}</td>
                            <td><Link to={"/customer/qna/"+val.qnaSeq} state={val}>{val.title}</Link></td>
                            <td className={val.answerStatus==1 ? 'text-success' : 'text-danger'}>{val.answerStatus==1 ? '처리완료' : '미처리'}</td>
                        </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="7">
                                <div className="paging">
                                    <Pagination
                                        activePage={page+1}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={10}
                                        prevPageText={"◀"}
                                        nextPageText={"▶"}
                                        onChange={onList}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className={id>=0 ? "show" : "hide"}>
                <h1 className="head">문의 상세보기
                    {id > 0 && (
                        <button type="text" className="btn-danger btn-small" onClick={()=>onRemove()}>삭제하기</button>
                    )}
                </h1>
                <table className="detail">
                    <colgroup>
                        <col width="15%"/>
                        <col width="85%"/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>등록일시</th>
                            <td>{location?.state?.registDate}</td>
                        </tr>
                        <tr>
                            <th>작성자</th>
                            <td>{api.b64DecodeUnicode(form_member?.name)}</td>
                        </tr>
                        <tr>
                            <th>이메일</th>
                            <td>{form_member?.email}</td>
                        </tr>
                        <tr>
                            <th>연락처</th>
                            <td>{api.b64DecodeUnicode(form_member?.phone)}</td>
                        </tr>
                        <tr>
                            <th>문의유형</th>
                            <td>{location?.state?.type}</td>
                        </tr>
                        <tr>
                            <th>문의내용</th>
                            <td>{location?.state?.qnaMemo ? location?.state?.qnaMemo : ''}</td>
                        </tr>
                       <tr>
                            <th>첨부파일</th>
                            <td className="attach">
                            {location?.state?.oriFile1 && (<button onClick={(e)=>FileDownload(location?.state?.file1, location?.state?.oriFile1)} type="button" className="btn-primary btn-small">{location?.state?.oriFile1}</button>)}
                            {location?.state?.oriFile2 && (<button onClick={(e)=>FileDownload(location?.state?.file2, location?.state?.oriFile2)} type="button" className="btn-primary btn-small">{location?.state?.oriFile2}</button>)}
                            {location?.state?.oriFile3 && (<button onClick={(e)=>FileDownload(location?.state?.file3, location?.state?.oriFile3)}  type="button" className="btn-primary btn-small">{location?.state?.oriFile3}</button>)}
                            
                            </td>
                        </tr>
                    </tbody>  
                </table>

                <h1 className="head">문의 답변하기</h1>                
                <table className="detail">      
                    <colgroup>
                        <col width="15%"/>
                        <col width="85%"/>
                    </colgroup>               
                    <tbody>                    
                       <tr>
                            <th>문의답변</th>
                            <td>
                                <textarea placeholder="답변을 입력하세요." onChange={(e)=>setFormAnswer(e.target.value)} value={form_answer}>{form_answer}</textarea>
                            </td>
                        </tr>
                       <tr>
                            <th>파일첨부</th>
                            <td>
                                <FileAdd form_file={form_file1} setFormFile={setFormFile1} form_orifile={form_orifile1} /><br/>
                                <FileAdd form_file={form_file2} setFormFile={setFormFile2} form_orifile={form_orifile2} /><br/>
                                <FileAdd form_file={form_file3} setFormFile={setFormFile3} form_orifile={form_orifile3} /><br/>
                            </td>
                        </tr>
                       <tr>
                            <th>처리상태</th>
                            <td>
                                <label className="radiobox"><input type="radio" checked={form_status ? false : true} onClick={(e)=>setFormStatus(0)}/><span>미처리</span></label>
                                <label className="radiobox"><input type="radio" checked={form_status ? true : false} onClick={(e)=>setFormStatus(1)}/><span>처리완료</span></label>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2"><button type="button" className="btn-primary" onClick={(e)=>onSubmit()}>저장</button></td>
                        </tr>
                    </tfoot>                    
                </table>
            </div>
        </div>
    );
}

export default Qna;