import React, {useState, useEffect} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import * as api from "../../config/api"
import Pagination from 'react-js-pagination';
import Editor from "../../component/Editor";

function Faq() {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    //유저정보
    const user = useSelector((state) => state.user);

    //리스트 
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(20);
    const [category_list, setCategoryList] = useState([]);
    let [search, setSearch] = useState("");

    //등록
    const [form_date, setFormDate] = useState("");
    const [form_title, setFormTitle] = useState("");
    const [form_content, setFormContent] = useState("");
    const [form_category, setFormCategory] = useState();

    //검색
    const [search_category, setSearchCategory] = useState();
    

    const  onList = (page) => {
        api.getData("/faq",{
            category:search
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res?.data?.status==='SUCCESS'){
                setList(res.data.data);
            }
        });
    }

    const onSubmit = () => {
        if(id > 0 ){
            api.putData("/admin/customer/faq",{
                title:form_title,
                faqCategorySeq:form_category,
                contents:form_content,
            },{id:id},user.token).then(res=>{
                //console.log(res);
                if(res && res?.data?.status == "SUCCESS"){
                    window.alert("수정되었습니다.")
                    goList();
                }
            });
        }else{
            api.postData("/admin/customer/faq",{
                title:form_title,
                faqCategorySeq:form_category,
                contents:form_content,
            },user.token).then(res=>{
                //console.log(res);
                if(res && res?.data?.status == "SUCCESS"){
                    window.alert("등록되었습니다.")
                    goList();
                }
            });
        }
    }
    const onRemove = () =>{
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/customer/faq",{
                id:id
            },user.token).then(res=>{
                if(res && res?.data?.status == "SUCCESS"){
                    window.alert("삭제되었습니다.")
                    goList();
                }
            });
        }
    }
    const goList = () => {
        navigate("/customer/faq"); 
    }
    useEffect(() => {    
        api.getData("/faq/category",{},user.token).then(res=>{
            if(res && res?.data!=null && res?.data?.status==='SUCCESS'){
                setCategoryList(res.data.data);
            }
        });


        if(id == 0){
            setFormDate("");
            setFormTitle("");
            setFormContent("");
            setFormCategory("");
        }
        if(location?.state?.faqSeq > 0){

            setFormDate(location.state.registDate);
            setFormTitle(location.state.title);
            setFormContent(location.state.contents ? location.state.contents : '');
            setFormCategory(location.state.faqCategorySeq);
        }else{
            onList(1);
        }
    }, [id]);

    const searchReset = () => {
        setSearch("");          
        search="";
        onList(1);
    }

    return (
        <div className="normal">
            
            <div className={id==null ? "show" : "hide"}>
            <h1 className="head">FAQ
                    <Link to="/customer/faq/0" className="btn-primary btn-small">등록하기</Link></h1> 
                <form className="search" name="searc" method="get">

                    <div className="wrap_search">
                        <div className="search_group">
                            <label className="title">분류</label>
                            <div className="box_search">
                                <select onChange={(e)=>setSearch(e.target.value)}>
                                    <option value="">분류명</option>
                                    {category_list.map((val)=>             
                                        <option value={val.faqCategorySeq}>{val?.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <button type="reset" className="btn-off" onClick={(e)=>searchReset()}>초기화</button>
                        <button type="button" className="btn-primary" onClick={(e)=>onList(1)}>검색</button>
                    </div>            
                </form>
                <h1 className="head">목록<span>검색결과<strong className="text-danger">234</strong>개</span></h1>    
                <table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>분류</th>
                            <th>질문</th>
                            <th>조회수</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((val,index)=>                     
                        <tr>
                            <td>{(list.length-index-(page*limit))}</td>
                            <td>{category_list.find(par=>par.faqCategorySeq == val.faqCategorySeq)?.name}</td>
                            <td><Link to={"/customer/faq/"+val.faqSeq} state={val}>{val.title}</Link></td>
                            <td>{val.hit}</td>
                        </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className={id>=0 ? "show" : "hide"}>
                <h1 className="head">FAQ등록/수정
                    {id > 0 && (
                        <button type="text" className="btn-danger btn-small" onClick={()=>onRemove()}>삭제하기</button>
                    )}
                </h1>
                <table className="detail">
                    <colgroup>
                        <col width="15%"/>
                        <col width="85%"/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>질문</th>
                            <td><input type="text" placeholder="제목을 입력하세요" value={form_title} onChange={(e)=>setFormTitle(e.target.value)}/></td>
                        </tr>
                        <tr>
                            <th>븐류</th>
                            <td className="flex">
                                <select value={form_category} onChange={(e)=>setFormCategory(e.target.value)}>
                                    <option value="">분류명을 선택해주세요.</option>
                                    {category_list.map((val)=>             
                                        <option value={val.faqCategorySeq}>{val?.name}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>상세내용</th>
                            <td> 
                                <Editor 
                                    placeholder="내용을 입력하세요."
                                    value={form_content}
                                    setFormContent={setFormContent}
                                    folder="/board"
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2"><button type="button" className="btn-primary" onClick={(e)=>onSubmit()}>저장</button></td>
                        </tr>
                    </tfoot>                    
                </table>
            </div>
        </div>
    );
}

export default Faq;