import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import Editor from "../../component/Editor";
import * as api from "../../config/api"

function Agree3() {
    const user = useSelector((state) => state.user);
    const [tab, setTab] = useState(0);
    const navigate = useNavigate();

    const [view, setView] = useState(
        {
            agree0:"이용약관",
            agree1:"개인정보수집및이용동의",
            agree2:"개인정보제3자제공",
            agree3:"회원탈퇴약관",
            agree4:"",
            agree5:"",
        }
    );
    
    const [agree0, setAgree0] = useState("");

    const onContents = (e,t) => {
       setView({...view, ["agree"+t]:e})
    }
    const onSave = () => {
        api.postData("/admin/setting/agree",{
            id:3,
            text:agree0
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res.data.status == "SUCCESS"){

            }else{
                //window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                //navigate("/")
                return;
            }
        });
    }
    useEffect(() => {
        api.getData("/agree",{},user.token).then(res=>{

            if(res && res.data.status==='SUCCESS'){
                setAgree0(res.data.data.agree3);
            }
        });
    }, []);
    return (
        <div>
            <h1 className="head">약관</h1>
            <div className="tab">
                <button type="button" className=""  onClick={()=>navigate("/setting/agree")}>이용약관</button>
                <button type="button" className="" onClick={()=>navigate("/setting/agree1")}>개인정보수집및이용동의</button>
                <button type="button" className=""  onClick={()=>navigate("/setting/agree2")}>개인정보제3자제공</button>
                <button type="button" className="active"  onClick={()=>navigate("/setting/agree3")}>회원탈퇴약관</button>
                <button type="button" className=""  onClick={()=>navigate("/setting/agree4")}>강연신청링크</button>
                <button type="button" className=""  onClick={()=>navigate("/setting/agree5")}>빌딩대관링크</button>
            </div>
            <div className="agree">
                {tab === 4 ? 
                <div className="box_search">
                    <input type="text" value={view?.agree4} onChange={(e)=>setView({...view, agree4:e.target.value})}/>
                </div>
                : tab === 5 ? 
                <div className="box_search">
                    <input type="text" value={view?.agree5} onChange={(e)=>setView({...view, agree5:e.target.value})}/>
                </div>
                :
                <Editor 
                    placeholder="내용을 입력하세요."
                    value={agree0}
                    setFormContent={setAgree0}
                    folder="/board"
                />
                }
            </div>
            <div className="text-center">
                <button type="button" className="btn-primary" onClick={(e)=>onSave()}>저장</button>
            </div>
        </div>
    );
}

export default Agree3;