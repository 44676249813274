import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import FileAdd from "../../component/FileAdd";
import * as api from "../../config/api"
import ExcelDownload from '../../component/ExcelDownload'
import Pagination from 'react-js-pagination';

function Data(props) {
    
    const { type, id } = useParams();
    let title={native:"국어",society:"사회",art:"미술"};
    //유저정보
    const user = useSelector((state) => state.user);
    //리스트 
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(20);

    //검색
    const today = new Date();    

    //등록
    let [detail, setDetail] = useState({page:1,limit:20,view:9});

    const [year_list, setYearList] = useState([]);   
    const [subject_list, setSubjectList] = useState([]);
    const [unit_list, setUnitList] = useState([]);
    const [unit_level_list, setUnitLevelList] = useState([]);
    const [type_list, setTypeList] = useState([]);
    const [type_level_list, setTypeLevelList] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();

    const onSchool = (val) => {
       setDetail({...detail, school:val})
    } 
    const onYear = (val) => {
        setDetail({...detail, yearSeq:val})
    } 
    const onSubject = (val) => {
        setDetail({...detail, subjectSeq:val})
    } 
    const onUnit = (val) => {
        setDetail({...detail, unitSeq:val})
        api.getData("/admin/curriculum/unit/level", {
            id:val
        },user.token).then(res=>{
            if(res && res.data.status == "SUCCESS"){
                setUnitLevelList(res.data.data); 
            }
        });        
    }
    const onType = (val) => {
        setDetail({...detail, typeSeq:val})
        api.getData("/admin/curriculum/type/level", {
            id:val
        },user.token).then(res=>{
            if(res && res.data.status == "SUCCESS"){
                setTypeLevelList(res.data.data); 
            }
        });        
    }

    const onStartDate = (date) => {
        setDetail({...detail, sdate:date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : ''});

    }
    const onEndDate = (date) => {
        setDetail({...detail, edate:date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : ''});
    }
    const onBoard = () => {
        navigate("/curriculum/"+type); 
    } 
    const searchReset = (page) => {
        detail.sdate = "";
        detail.edate = "";
        detail.text = "";
        detail.view = 9;
        detail.school = "";
        detail.yearSeq = "";
        detail.subjectSeq = "";
        detail.unitSeq = "";
        detail.typeSeq = "";
        detail.unitLevelSeq = "";
        detail.typeLevelSeq = "";
        onList(page);
    }
    
    const onListSearch = () => {
        onList(1);
        localStorage.setItem("search_data", JSON.stringify(detail));
    }

    const  onList = (page) => {
        //같은페이지 있다가 한번이상 본 경우는 검색 남기기
        const current = JSON.parse(localStorage.getItem("current"));
        if(location.pathname.indexOf(current?.pagename) >= 0 && current?.counting > 1){
            const search_load = JSON.parse(localStorage.getItem("search_data"));

            if(search_load){
                detail = search_load;
                setDetail(detail);
            }
        }
        detail.page = page - 1;
        detail.limit = 20;
        detail.view = (detail.view === 9 ? '' : detail.view);        

        api.getData("/admin/curriculum",detail,user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);   
            }
        });
    }

    useEffect(() => {
        api.getData("/curriculum/year", {
        }).then(res=>{
            if(res && res.data.status==='SUCCESS'){
                setYearList(res.data.data);
            }
        });
        api.getData("/admin/curriculum/subject", {
        },user.token).then(res=>{
            if(res && res.data.status==='SUCCESS'){
                setSubjectList(res.data.data);
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });
        api.getData("/admin/curriculum/unit", {
        },user.token).then(res=>{
            if(res && res.data.status==='SUCCESS'){
                setUnitList(res.data.data);
            }
        });
        api.getData("/curriculum/type", {
        },user.token).then(res=>{
            if(res && res.data.status==='SUCCESS'){
                setTypeList(res.data.data);
            }
        });

        if(type === "native"){
            detail.subject = "국어";
        } else if(type === "society"){
            detail.subject = "사회";
        } else if(type === "art"){
            detail.subject = "미술";
        }
        searchReset(location?.state?.page ? location?.state?.page : 1);
        
    }, [type]);

    return (
        <div className="normal">
            <div>
                <h1 className="head">{title[type]}자료<Link to={"/curriculum/"+type+"/0"} className="btn-small btn-info">등록하기</Link></h1>        
                <div className="search" name="searc" method="get">
                    <form>
                        <div className="wrap_search">
           
                            <div className="search_group">
                                <label className="title">등록일</label>
                                <div className="box_search">
                                    <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={detail?.sdate} onChange={(date) => onStartDate(date)} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>
                                    <span className="box_wave">~</span>
                                    <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={detail?.edate} onChange={(date) => onEndDate(date)} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>                                
                                </div>
                            </div>
                            <div className="search_group">
                                <label className="title">검색</label>
                                <div className="box_search"><input type="text" placeholder="자료명, 단원명" value={detail?.text} onChange={(e)=>setDetail({...detail, text:e.target.value})}/></div>
                            </div>
                            <div className="search_group">
                                <label className="title">구분</label>
                                <div className="box_search">
                                    <select onChange={(e)=>onSchool(e.target.value)} value={detail?.school}>
                                        <option value="">학교급</option>
                                        <option value="초등">초등</option>
                                        <option value="중학">중학</option>
                                        <option value="고등">고등</option>
                                        <option value="인정">인정</option>
                                    </select>
                                    <select onChange={(e)=>onYear(e.target.value)} value={detail?.yearSeq}>
                                        <option value="">개정연도</option>
                                        {year_list.map((val)=>(
                                            (val.subject == detail?.subject && val.school == detail?.school) && (
                                            <option value={val.seq}>{val.year}년</option>
                                            )
                                        ))}
                                    </select>
                                    <select onChange={(e)=>onSubject(e.target.value)} value={detail?.subjectSeq}>
                                        <option value="">교과</option>
                                        {subject_list.map((val)=>(
                                            (val.subject == detail?.subject && val.school == detail?.school && val.yearSeq == detail?.yearSeq) && (
                                            <option value={val.subjectSeq} >{val.name}</option>
                                            )
                                        ))}                                
                                    </select>
                                </div>
                            </div>

                            <div className="search_group">
                                <label className="title">단원</label>
                                <div className="box_search">
                                    <select onChange={(e)=>onUnit(e.target.value)} value={detail?.unitSeq}>
                                        <option value="">단원</option>
                                        {unit_list.map((val)=>(
                                            (val.subject == detail?.subject && val.school == detail?.school && val.yearSeq == detail?.yearSeq && val.subjectSeq == detail?.subjectSeq) && (
                                                <option value={val.unitSeq}>{val.name}</option>
                                            )
                                        ))}
                                    </select>
                                    <select onChange={(e)=>setDetail({...detail, unitLevelSeq: e.target.value})} value={detail?.unitLevelSeq}>
                                        <option value="">소단원</option>
                                        {unit_level_list.map((val)=>(
                                            <option value={val.unitLevelSeq}>{val.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="search_group">
                                <label className="title">자료유형</label>
                                <div className="box_search">
                                    <select onChange={(e)=>onType(e.target.value)} value={detail?.typeSeq}>
                                        <option value="">수업자료</option>
                                        {type_list.map((val)=>(
                                            <option value={val.typeSeq}>{val.name}</option>
                                        ))}
                                    </select>
                                    <select onChange={(e)=>setDetail({...detail, typeLevelSeq: e.target.value})} value={detail?.typeLevelSeq}>
                                        <option value="">교과서</option>
                                        {type_level_list.map((val)=>(
                                            <option value={val.typeLevelSeq}>{val.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>


                            <div className="search_group">
                                <label className="title">게재여부</label>
                                <div className="box_search">
                                    
                                    <label className="radiobox"><input type="radio" checked={detail?.view===9} onChange={(e)=>setDetail({...detail, view:9})} /><span>전체</span></label>
                                    <label className="radiobox"><input type="radio" checked={detail?.view===1} onChange={(e)=>setDetail({...detail, view:1})} /><span>노출</span></label>
                                    <label className="radiobox"><input type="radio" checked={detail?.view===0} onChange={(e)=>setDetail({...detail, view:0})} /><span>비노출</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="reset" className="btn-off" onClick={(e)=>searchReset(1)}>초기화</button>
                            <button type="button" className="btn-primary" onClick={(e)=>onListSearch()}>검색</button>
                        </div>   
                    </form>         
                </div>        
                <h1 className="head">{title[type]}자료 목록<span>검색결과<strong className="text-danger">{list.length}</strong>개</span>
                <ExcelDownload  
                    thead={['No','등록일','학교급','개정년도','교과','단원','자료유형','자료명','노출여부']} 
                    api="/curriculum/excel" 
                    name="교과자료" 
                    get={{status:0}} 
                />
                </h1>
                <table>
                    
                    <thead>
                        <tr>
                            <th width="80">No</th>
                            <th width="160">등록일</th>
                            <th width="90">학교급</th>
                            <th width="100">개정연도</th>
                            <th width="150">교과</th>
                            <th width="260">단원</th>
                            <th width="150">자료유형</th>
                            <th>자료명</th>
                            <th width="100">게재여부</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((val,index)=>  
                        <tr>
                            <td>{(total-index-(page*limit))}</td>
                            <td>{val.registDate?.substr(0,16)}</td>
                            <td>{val.school}</td>
                            <td>{val.yearText}</td>
                            <td>{val.subjectText}</td>
                            <td>
                                {val.unitText == 'undefined' ? '-' :
                                <>
                                {val.unitText} {val.unitText && val.unitLevelText ? <><br/>&gt;</> : <>-</>} {val.unitLevelText}
                                </>
                                }
                            </td>
                            <td>
                                {val.typeText == 'undefined' ? '-' :
                                <>
                                {val.typeText} {val.typeText && val.typeLevelText ? <><br/>&gt;</> : <>-</>} {val.typeLevelText}
                                </>
                                }
                            </td>
                            <td><Link to={"/curriculum/"+type+"/"+val.curriculumSeq+"?page="+(page+1)} state={val}>{val.name}</Link></td>
                            <td>{val.view ? <span className="o">●</span> : <span className="x">x</span>}</td>
                        </tr>
                        )}
                        {list.length <= 0 && <tr><td colspan="9">내용이 없습니다.</td></tr>}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="10">
                                <div className="paging">
                                    <Pagination
                                        activePage={page+1}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={10}
                                        prevPageText={"◀"}
                                        nextPageText={"▶"}
                                        onChange={onList}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>

        </div>
    );
}

export default Data;