import React, {useEffect, useState, useRef} from 'react';
import { useSelector } from "react-redux";
import {  useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpDown } from "@fortawesome/free-solid-svg-icons";

import * as api from "../config/api"

function Category(props) {
    //유저정보
    const user = useSelector((state) => state.user);
    const {category_type } = useParams();
    const [inputTitle, setInputTitle] = useState();
    const [addShow, setShow] = useState(1);

    
    const dragItem = useRef(); // 드래그할 아이템의 인덱스
    const dragOverItem = useRef(); // 드랍할 위치의 아이템의 인덱스

    // 드래그 시작될 때 실행
    const dragStart = (e, position,seq) => {
        dragItem.current = position;

    };

    // 드래그중인 대상이 위로 포개졌을 때
    const dragEnter = (e, position,seq) => {
        dragOverItem.current = position;
   
    };

    // 드랍 (커서 뗐을 때)
    const drop = (par,seq) => {
       const startSort = dragOverItem.current;
        const endSort = dragItem.current;
        const newList = [...props.submenu];
        const dragItemValue = newList[dragItem.current];
        newList.splice(dragItem.current, 1);       
        newList.splice(dragOverItem.current, 0, dragItemValue);
        dragItem.current = null;
        dragOverItem.current = null;
        if(startSort != endSort){
            props.setSubMenu(
                newList.map(val =>
                    val.seq === seq ? { ...val, seq: "move" } : val
                )
            );
            //드래그 순간 name이 같아져서 셀렉트 박스 체크 풀림 이동시 move처리 놓는 순간 다시 자기 번호로 변경
            setTimeout(()=>{
                props.setSubMenu(
                    newList.map(val =>
                        val.categorySeq === seq ? { ...val, categorySeq: seq } : val
                    )
                );
                let seqs = '';
                newList.map(val => {
                    seqs += ","+val.categorySeq;
                })
                api.putData("/admin/board/categorySort", {},{
                    seqs:seqs
                }).then(res=>{
                    if(res){

                    }
                });
            },100)
        }
    };

    

    useEffect(() => {

    }, []);


    const onAdd = () => {        
        if(!inputTitle){
            window.alert("카테고리명을 입력하세요");
            return;
        }

        api.postData("/admin/board/category", {
            type:category_type,
            name:inputTitle,
            view:addShow,
            sort:(props?.submenu?.length+1)
        },user.token).then(res=>{
            if(res && res?.data?.statue === "SUCCESS"){
                props.setSubMenu([...props?.submenu, res?.data?.data]);
                setInputTitle("");
            }
        });

    }
    const changeRadio = (seq,chk) => {        
        props.setSubMenu(
            props?.submenu?.map(val =>
                val.categorySeq === seq ? { ...val, view: chk } : val
            )
        );

    }
    const changeValue = (e,seq) => {
        props.setSubMenu(
            props?.submenu?.map(val =>
                val.categorySeq === seq ? { ...val, name: e.target.value } : val
            )
        );
    }
    const onModify = (val,seq) => {
        api.putData("/admin/board/category", {
            type:val.type,
            name:val.name,
            view:val.view,
            sort:val.sort
        },{
            id:seq
        },user.token).then(res=>{
            if(res?.data?.status == "SUCCESS"){
                window.alert("수정되었습니다.");
            }   
        });
    }

    const onRemove = (seq) => {
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/board/category", {
                id:seq
            },user.token).then(res=>{
                if(res?.data?.status == "SUCCESS"){
                    props.setSubMenu(props.submenu.filter(val => val.categorySeq !== seq));
                    window.alert("삭제되었습니다.");
                }                 
            });
        }
    }
  
    return (
        <div>
            <h1 className="head">카테고리 분류{/*<button type="button" className="btn-primary btn-small" onClick={()=>onSave()} >저장</button>*/}</h1>

            <div className="tab_box">
                <div className="tab_category dragndrop">
                    <dl>
                        <dt>
                            카테고리
                        </dt>

                        {props?.submenu?.map((val, index) => 
                            (val.type == category_type) &&
                            (<dd 
                                className='show'
                                key={index}
                                draggable
                                onDragStart={(e) => dragStart(e, index,val.categorySeq)}
                                onDragEnter={(e) => dragEnter(e, index,val.categorySeq)}
                                onDragEnd={(e) => drop(val, val.categorySeq)}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <i><FontAwesomeIcon icon={faUpDown} /></i>
                                <div className="radio">
                                <input type="text" value={val.name}  onChange={(e)=>changeValue(e,val.categorySeq)} />
                                <label className="radiobox"><input type="radio" name={"list"+val.categorySeq} value="1" onClick={()=>changeRadio(val.categorySeq,1)} checked={val.view} /><span>노출</span></label>
                                <label className="radiobox"><input type="radio" name={"list"+val.categorySeq} value="0" onClick={()=>changeRadio(val.categorySeq,0)} checked={!val.view} /><span>비노출</span></label>                       
                                <button type="button" className="btn-info" onClick={()=>onModify(val,val.categorySeq)}>수정</button>       
                                <button type="button" className={!val.del ? "btn-danger" : "btn-warning"} onClick={()=>onRemove(val.categorySeq)}>{val.del ? '취소' : '삭제'}</button>       
                                </div>
                            </dd>)
                        )}

                        <dd className="show">
                            <i className="bean"></i>
                            <div className="radio">
                            <input type="text" value={inputTitle} onChange={(e)=>setInputTitle(e.target.value)} />
                            <label className="radiobox"><input type="radio" name="add" onClick={()=>setShow(1)} value="1" defaultChecked/><span>노출</span></label>
                            <label className="radiobox"><input type="radio" name="add" onClick={()=>setShow(0)} value="0" /><span>비노출</span></label>                                              
                            <button type="button" className="btn-primary" onClick={()=>onAdd()}> 추가</button>
                            </div>
                        </dd>
                    </dl>
                </div>                
            </div>


        </div>
    );
}

export default Category;