import React, {useState, useEffect} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import * as api from "../../config/api"
import Pagination from 'react-js-pagination';
import Editor from "../../component/Editor";
import ImageAdd from "../../component/ImageAdd";

import ExcelDownload from '../../component/ExcelDownload'

function Event() {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    //유저정보
    const user = useSelector((state) => state.user);

    //리스트 
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(20);
    const [reply_list, setReplyList] = useState([]);

    //등록
    const [form_title, setFormTitle] = useState("");
    const [form_content, setFormContent] = useState("");
    const [form_thumbnail, setFormThumbnail] = useState("");
    const [form_orithumbnail, setFormOriThumbnail] = useState("");
    const [form_view, setFormView] = useState(1);
    const [form_reply_status, setFormReplyStatus] = useState(1);
    const [form_ing, setFormIng] = useState(1);
    const [form_date, setFormDate] = useState();

    const  onList = (page) => {

        api.getData("/admin/customer/event",{
            page:page-1,
            size:limit
        },user.token).then(res=>{
            if(res && res?.data?.status==='SUCCESS'){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);   
            }
        });

    }

    const onSubmit = () => {
        const formData = new FormData();
        if(id > 0 ){
            formData.append("eventSeq",id);
        }
        formData.append("title",form_title);
        formData.append("ing",(form_ing ? 1 : 0));
        formData.append("view",(form_view ? 1 : 0));
        formData.append("replyStatus",(form_reply_status ? 1 : 0));
        formData.append("contents",form_content);
        formData.append("thumbnails",form_thumbnail);
        api.fileUpload("/admin/customer/event",formData,user.token).then(res=>{
            console.log(res);
            goList();
        });
    
    }
    const onRemove = () =>{
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/customer/event",{
                id:id
            },user.token).then(res=>{
                if(res && res?.data?.status == "SUCCESS"){
                    goList();
                }
            });
        }
    }
    const goList = () => {
        navigate("/customer/event"); 
    }
    const onReplyRemove = (seq) => {

        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/customer/event/reply",{
                seq:seq
            },user.token).then(res=>{

                if(res?.data?.status == "SUCCESS"){
                    setReplyList(reply_list.filter(val =>
                        val.eventReplySeq !== seq
                    ));            
                } 
            });
        }
    }
    const onReplyChange = (par) => {
        let msg = "노출로 변경하시겠습니까?";
        if(par.view){
            msg = "미노출로 변경하시겠습니까?";
        }
        if(window.confirm(msg)){
            api.putData("/admin/customer/event/reply",{
                view:(par.view ? 0 : 1)
            },{
                id:par.eventReplySeq
            },user.token).then(res=>{
                if(res?.data?.status == "SUCCESS"){
                    setReplyList(reply_list.map(val =>
                        val.eventReplySeq === par.eventReplySeq ? {...val, view:!val.view} : val
                    ));            
                }
            });
        }
    }
    useEffect(() => {    
        if(id == 0){
            setFormDate("");
            setFormTitle("");
            setFormContent("");
            setFormThumbnail("");
            setFormOriThumbnail("");
            setFormView(1);
            setFormReplyStatus(1);
            setFormIng(1);
        }
        if(id > 0){
            setFormDate(location.state.registDate);
            setFormTitle(location.state.title);
            setFormContent(location.state.contents);
            setFormThumbnail(location.state.thumbnail);
            setFormOriThumbnail(location.state.oriThumbnail);
            setFormView(location.state.view);
            setFormReplyStatus(location.state.replyStatus);
            setFormIng(location.state.ing);
            
            api.getData("/admin/customer/event/reply",{
                eventSeq:id
            },user.token).then(res=>{
                if(res && res?.data?.status==='SUCCESS'){
                    setReplyList(res?.data?.data);
                }
            });

        }else{
            onList(1)
        }
    }, [id]);
    return (
        <div className="normal">
            <div className={id==null ? "show" : "hide"}>
                <h1 className="head">이벤트<Link to="/customer/event/0" className="btn-info btn-small">등록하기</Link></h1> 
                <table>
                    
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>등록일</th>
                            <th>제목</th>
                            <th>참여자</th>
                            <th>조회수</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((val,index)=>                     
                        <tr>
                            <td>{(total-index-(page*limit))}</td>
                            <td>{val.registDate.substr(0,16)}</td>
                            <td><Link to={"/customer/event/"+val.eventSeq} state={val}>{val.title ? val.title : '제목없음'}</Link></td>
                            <td>{val.reply}</td>
                            <td>{val.hit}</td>
                        </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="5">
                                <div className="paging">
                                    <Pagination
                                        activePage={page+1}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={10}
                                        prevPageText={"◀"}
                                        nextPageText={"▶"}
                                        onChange={onList}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className={id>=0 ? "show" : "hide"}>
                <h1 className="head">이벤트 상세보기
                    {id > 0 && (
                        <button type="text" className="btn-danger btn-small" onClick={()=>onRemove()}>삭제하기</button>
                    )}
                </h1>
                <table className="detail">
                    <colgroup>
                        <col width="15%"/>
                        <col width="85%"/>
                    </colgroup>
                    <tbody>
                        {id > 0 && 
                        <tr>
                            <th>등록일시</th>
                            <td>{form_date}</td>
                        </tr>
                        }
                        <tr>
                            <th>제목</th>
                            <td><input type="text" placeholder="제목을 입력하세요" value={form_title} onChange={(e)=>setFormTitle(e.target.value)}/></td>
                        </tr>
                        <tr>
                            <th>썸네일 이미지<br/><small>800px * 800px</small></th>
                            <td>
                                <ImageAdd folder="board" form_thumbnail={form_thumbnail} setFormThumbnail={setFormThumbnail} form_orithumbnail={form_orithumbnail}  width={800} height={800} resize={3} id={id ? id : -1}/>
                            </td>
                        </tr>
                        <tr>
                            <th>진행 상태</th>
                            <td>
                                <label className="radiobox"><input type="radio" checked={form_ing ? true : false} onClick={(e)=>setFormIng(1)} /><span>진행</span></label>
                                <label className="radiobox"><input type="radio" checked={form_ing ? false : true} onClick={(e)=>setFormIng(0)} /><span>종료</span></label>
                            </td>
                        </tr>
                        <tr>
                            <th>게재 여부</th>
                            <td>
                                <label className="radiobox"><input type="radio" checked={form_view ? true : false} onClick={(e)=>setFormView(1)}/><span>노출</span></label>
                                <label className="radiobox"><input type="radio" checked={form_view ? false : true} onClick={(e)=>setFormView(0)}/><span>비노출</span></label>
                            </td>
                        </tr>
                        <tr>
                            <th>댓글 여부</th>
                            <td>
                                <label className="radiobox"><input type="radio" checked={form_reply_status ? true : false} onClick={(e)=>setFormReplyStatus(1)}/><span>노출</span></label>
                                <label className="radiobox"><input type="radio" checked={form_reply_status ? false : true} onClick={(e)=>setFormReplyStatus(0)}/><span>비노출</span></label>
                            </td>
                        </tr>
                        <tr>
                            <th>상세내용</th>
                            <td>
                                <Editor 
                                    placeholder="내용을 입력하세요."
                                    value={form_content}
                                    setFormContent={setFormContent}
                                    folder="/board"
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2">
                                <button type="button" className="btn-primary" onClick={()=>onSubmit()}>{id > 0 ? '수정' : '저장'}</button>
                            </td>
                        </tr>
                    </tfoot>                    
                </table>
                {form_reply_status ? 
                <div style={{marginTop:"70px"}}>
                    <h1 className="head">이벤트 참여자 댓글 <span><strong className="text-danger">{reply_list.length}</strong>건</span>
                        <ExcelDownload  
                            thead={['No','작성일','계정','이름','핸드폰','회원등급','학교명','댓글내용','노출여부']} 
                            api="/customer/event/reply" 
                            name="이벤트 댓글 리스트" 
                            get={{eventSeq:id}} 
                        />

                    </h1>
                    <table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>작성일</th>
                                <th>계정</th>
                                <th>이름</th>
                                <th>핸드폰</th>
                                <th>회원등급</th>
                                <th>학교명</th>
                                <th>댓글내용</th>
                                <th>노출여부</th>
                                <th>삭제</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reply_list.map((val,index)=>         
                            <tr>
                                <td>{index+1}</td>
                                <td>{val.registDate}</td>
                                <td>{val.account}</td>
                                <td>{val.name}</td>
                                <td>{val.phone}</td>
                                <td>{api.memberGrade[val.grade]}</td>
                                <td>{val.school ? val.school : '-'}</td>
                                <td>{val.reply}</td>
                                <td><button type="button" className={val.view ? "btn btn-small btn-info" : "btn btn-small btn-warning"} onClick={(e)=>onReplyChange(val)}>{val.view ? '노출' : '미노출'}</button></td>
                                <td><button type="button" className="btn btn-small btn-danger" onClick={(e)=>onReplyRemove(val.eventReplySeq)}>삭제</button></td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                : 
                    <></>
                }
            </div>
        </div>
    );
}

export default Event;