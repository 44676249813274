import React, {useEffect, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";

import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpDown } from "@fortawesome/free-solid-svg-icons";

import ImageAdd from "../../component/ImageAdd";
import FileAdd from "../../component/FileAdd";
import * as api from "../../config/api"
function Datatype() {
    //유저정보
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();


    const [tab, setTab] = useState('국어');
    const [school, setSchool] = useState('초등');
    const [detail, setDetail] = useState({});
    const [year, setYear] = useState("");
    const [year_list, setYearList] = useState([]);    
    const [subject, setSubject] = useState();    
    const [subject_list, setSubjectList] = useState([]);    
    const [newSmallTitle, setNewSmallTitle] = useState("");       
    


    const [list, setList] = useState([]);
    const [small_list, setSmallList] = useState([]); 

    const dragItem = useRef(); // 드래그할 아이템의 인덱스
    const dragOverItem = useRef(); // 드랍할 위치의 아이템의 인덱스

    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    // 드랍 (커서 뗐을 때)
    const drop = (par) => {
        const startSort = dragOverItem.current;
        const endSort = dragItem.current;
        const newList = [...list];
        const dragItemValue = newList[dragItem.current];
        newList.splice(dragItem.current, 1);       
        newList.splice(dragOverItem.current, 0, dragItemValue);
        dragItem.current = null;
        dragOverItem.current = null;
        
        if(startSort != endSort){
            setList(
                newList.map(val =>
                    val.typeSeq === par.typeSeq ? { ...val, typeSeq: "move" } : val
                )
            );
            //드래그 순간 name이 같아져서 셀렉트 박스 체크 풀림 이동시 move처리 놓는 순간 다시 자기 번호로 변경
            setTimeout(()=>{
                setList(
                    newList.map(val =>
                        val.typeSeq === par.typeSeq ? { ...val, typeSeq: par.typeSeq } : val
                    )
                );
                               
                let seqs = '';
                newList.map(val => {
                    seqs += ","+val.typeSeq;
                })
                api.putData("/admin/curriculum/type/sort", {},{
                    seqs:seqs
                },user.token).then(res=>{
                    if(res){

                    }
                });
            },100)
        }
    };


    

    const dragItemSmall = useRef(); // 드래그할 아이템의 인덱스
    const dragOverItemSmall= useRef(); // 드랍할 위치의 아이템의 인덱스

    const dragStartSmall = (e, position) => {
        dragItemSmall.current = position;
    };

    const dragEnterSmall = (e, position) => {
        dragOverItemSmall.current = position;
    };

    // 드랍 (커서 뗐을 때)
    const dropSmall = (par) => {
        const startSort = dragOverItemSmall.current;
        const endSort = dragItemSmall.current;
        const newList = [...small_list];
        const dragItemValue = newList[dragItemSmall.current];
        newList.splice(dragItemSmall.current, 1);       
        newList.splice(dragOverItemSmall.current, 0, dragItemValue);
        dragItemSmall.current = null;
        dragOverItemSmall.current = null;
        if(startSort != endSort){

            setSmallList(
                newList.map(val =>
                    val.typeLevelSeq === par.typeLevelSeq ? { ...val, typeLevelSeq: "move" } : val
                )
            );
            setTimeout(()=>{
                setSmallList(
                    newList.map(val =>
                        val.typeLevelSeq === par.typeLevelSeq ? { ...val, typeLevelSeq: par.typeLevelSeq } : val
                    )
                );
                       
                let seqs = '';
                newList.map(val => {
                    seqs += ","+val.typeLevelSeq;
                })
                api.putData("/admin/curriculum/type/level/sort", {},{
                    seqs:seqs
                },user.token).then(res=>{
                    if(res){

                    }
                });
            },100)
        }
    };


    useEffect(() => {

        api.getData("/curriculum/type", {
        },user.token).then(res=>{
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data);
            }
        });

    }, []);


    const onSave = () => {

        if(detail.typeSeq > 0 ){
            api.putData("/admin/curriculum/type",{
                name:detail.name
            }, {
                id:detail.typeSeq
            },user.token).then(res=>{
                setList(
                    list.map(val => 
                        val.typeSeq === detail.typeSeq ? {...val, name: detail.name} : val
                    )
                );
            });  
        } else{
            api.postData("/admin/curriculum/type", {
                subject:tab,
                school:school,
                yearSeq:year,
                subjectSeq:subject,
                name:detail.name,
                sort:(list.length+1)
            },user.token).then(res=>{
                if(res.data.status == "SUCCESS"){
                    setList([...list, res.data.data]);
                    setDetail(res.data.data);
                }
            });
        }
    }



    const onRemove = (seq) => {
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/curriculum/type", {
                id:seq
            },user.token).then(res=>{
                if(res && res.data.status == "SUCCESS"){
                    setList(list.filter(val => val.typeSeq !== seq));
                    onSetDetail(-1);
                }
            });
        }
    }
    const onSetDetail = (seq) => {
        setDetail({typeSeq:seq,view:1,sort:0,name:""});
    }
    const onGetDetail = (val) => {
        setDetail(val);
        api.getData("/curriculum/type/level", {
            id:val.typeSeq
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status == "SUCCESS"){
                setSmallList(res.data.data); 
            }
        });
    }
    const onTab = (txt) => {
        setTab(txt);
        setYear("");
        setSubject("");
        onSetDetail(-1);
    }

    const onSchool = (value) => {
        setSchool(value);
        setYear("");
        setSubject("");
        onSetDetail(-1);
    }

    const onYear = (value) => {
        setYear(value);
        setSubject("");
        onSetDetail(-1);
    }

    
    const changeSmallTitle = (e,seq) => {
        setSmallList(
            small_list.map(val => 
                val.typeLevelSeq === seq ? {...val, name: e.target.value} : val
            )
        );
    }

    //소단원 저장
    const smallSave = (e) => {

        api.postData("/admin/curriculum/type/level", {
            typeSeq:detail.typeSeq,
            name:newSmallTitle,
            sort:(small_list.length+1)
        },user.token).then(res=>{
            if(res.data.status == "SUCCESS"){
                const ret = res.data.data;
                setSmallList([...small_list, ret]);
                setNewSmallTitle("");
            }
        });
    }
    const smallModify = (e,val) => {
        api.putData("/admin/curriculum/type/level",{name:val.name}, {
            id:val.typeLevelSeq
        },user.token).then(res=>{

        });  
    }
    const smallRemove = (e,val) => {
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/curriculum/type/level", {
                id:val.typeLevelSeq
            },user.token).then(res=>{
                setSmallList(small_list.filter(par => par.typeLevelSeq !== val.typeLevelSeq));
            }); 
        }
    }
    return (
        <div>
            <h1 className="head">자료유형 설정</h1>
 
            <div className="tab_box">
                <div className="tab_category dragndrop">                   
                    <dl>
                        <dt>
                            자료유형                           
                            <button type="button" className="btn-primary" onClick={(e)=>onSetDetail(0)}>추가</button>
                        </dt>
                        {list.map((val, index) => (
                            
                            <dd 
                                className="show"
                                key={index}
                                draggable
                                onDragStart={(e) => dragStart(e, index)}
                                onDragEnter={(e) => dragEnter(e, index)}
                                onDragEnd={(e) => drop(val)}
                                onDragOver={(e) => e.preventDefault()}
                            >   
                                <span>[{val.typeSeq}]</span>
                                <i><FontAwesomeIcon icon={faUpDown} /></i>
                                <div><span class="click" onClick={(e)=>onGetDetail(val)}>{val.name}</span></div>
                            </dd>
                           
                        ))}
                    </dl>
                </div>
                <div className="tab_detail">
                    <table className={detail.typeSeq >= 0 ? 'detail' : 'detail hide'}>
                        <tbody>
                            <tr>
                                <th>단원명</th>
                                <td><input type="text" value={detail.name} onChange={(e)=>setDetail({...detail, name:e.target.value})} /></td>
                            </tr>
                            <tr>
                                <th>소단원</th>
                                <td>
                                    <div className="dragndrop">                   
                                        <dl>
                                            <dt>소단원명</dt>
                                            {small_list.map((val, index) => (
                                                <dd
                                                    className="show"
                                                    key={index}
                                                    draggable
                                                    onDragStart={(e) => dragStartSmall(e, index)}
                                                    onDragEnter={(e) => dragEnterSmall(e, index)}
                                                    onDragEnd={(e) => dropSmall(val)}
                                                    onDragOver={(e) => e.preventDefault()}                                                
                                                >
                                                    <span>[{val.typeLevelSeq}]</span>
                                                    <i><FontAwesomeIcon icon={faUpDown} /></i>
                                                    <div>
                                                        <input type="text" placeholder="소단원명을 입력하세요." value={val.name} onChange={(e)=>changeSmallTitle(e,val.typeLevelSeq)}/>
                                                        <button type="button" onClick={(e)=>smallModify(e,val)} className="btn-info" >수정</button>
                                                        <button type="button" onClick={(e)=>smallRemove(e,val)} className="btn-danger" >삭제</button>
                                                    </div>
                                                </dd>
                                            ))}
                                            {detail.typeSeq > 0 &&
                                            <dd className="show">
                                                
                                                <div>
                                                    <input type="text" placeholder="소단원명을 입력하세요." value={newSmallTitle} onChange={(e)=>(setNewSmallTitle(e.target.value))}/>
                                                    <button type="button" onClick={(e)=>smallSave(e)} className="btn-primary" >저장</button>
                                                </div>
                                            </dd>
                                            }
                                        </dl>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                            <td colSpan="2">
                                    { detail.typeSeq == 0 ?
                                    <button type="button" className="btn-warning" onClick={(e)=>setDetail({...detail, typeSeq:-1})}>취소</button>
                                    :
                                    <button type="button" className="btn-danger" onClick={(e)=>onRemove(detail.typeSeq)}>삭제</button>
                                    }

                                    <button type="button" className={detail.typeSeq > 0 ? "btn-info": "btn-primary"} onClick={(e)=>onSave()}>{detail.typeSeq > 0 ? '수정': '저장'}</button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Datatype;