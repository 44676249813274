import React, {useEffect, useState} from 'react';
import { Link,useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { clearUser } from "../redux/userSlice.js";

import { faHouse, faUserLarge, faBookOpen, faSchool,faRobot, faPersonChalkboard, faHeadset, faGears, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import * as config from './Config'
import logo from '../asset/image/menu_logo.png';
import * as api from "../config/api"

function Menu(props) {
    const user = useSelector((state) => state.user);
    const [showitem, setShowitem] = useState(0);  // 메뉴의 초기값을 false로 설정
    const [idx, setIdx] = useState(0);  // 메뉴의 초기값을 false로 설정
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const toggleItem = (no,url) => {
        setShowitem(no);
        navigate(url)
    } 
    const logout = () => {
        localStorage.clear();
        dispatch(clearUser(user));
        navigate("/");
    }

    useEffect(()=>{
        console.log("======")
        console.log(localStorage.getItem("grade"));
        api.getData("/admin/token",{
            
        },user.token).then(res=>{
            if(res?.headers?.get("New-Access-Token")){
                localStorage.setItem("access", res.headers.get("New-Access-Token"));
            }            
            if(res?.code == "ERR_BAD_REQUEST"){
                dispatch(clearUser(user));
                navigate("/");            
            }
        })

                
        if(location.pathname.indexOf("curriculum") >= 0){
            const page = location.pathname.split("/");

            const current = JSON.parse(localStorage.getItem("current"));
            if(location.pathname.indexOf(current?.pagename) >= 0 ){
                const count = current?.counting ? current?.counting : 0;
                localStorage.setItem("current", JSON.stringify({pagename:(page[1]+"/"+page[2]),counting:(count + 1)}));
            } else {
                localStorage.setItem("current", JSON.stringify({pagename:(page[1]+"/"+page[2]),counting:1}));
            }
        }
 

    },[location.pathname]);


    return (
        <div>
            <div className="header">
                <header>
                    <ul>
                        {localStorage.getItem("grade") == '0' ? 
                        <li className={location.pathname.indexOf("/member") >= 0 ? "show" : "hide"}>
                            <button type="button" onClick={()=>toggleItem(0,"/member/0")}><FontAwesomeIcon icon={faUserLarge} /> 회원관리</button>             
                        </li>
                        :
                        <></>
                        }
                        <li className={location.pathname.indexOf("/curriculum") >= 0 ? "show" : "hide"}>
                            <button type="button" onClick={()=>toggleItem(1,"/curriculum/native")}><FontAwesomeIcon icon={faBookOpen} /> 교과관리</button>              
                        </li>
                        <li className={location.pathname.indexOf("/class")>=0 || location.pathname.indexOf("/banner/class") >= 0 ? "show" : "hide"}>
                            <button type="button" onClick={()=>toggleItem(2,"/banner/class")}><FontAwesomeIcon icon={faSchool} /> 수업 혁신</button>             
                        </li>
                        <li className={location.pathname == "/banner/creative" || location.pathname=="/category/0" || location.pathname.indexOf("/board/0") >= 0 ? "show" : "hide"}>
                            <button type="button" onClick={()=>toggleItem(3,"/banner/creative")}><FontAwesomeIcon icon={faRobot} /> 창의적 체험 활동</button>             
                        </li>
                        <li className={ location.pathname == "/category/1" || location.pathname.indexOf("/board/1") >= 0 ? "show" : "hide"}>
                            <button type="button" onClick={()=>toggleItem(4,props?.link_class)}><FontAwesomeIcon icon={faPersonChalkboard} /> 수업연계도서</button>          
                        </li>
                        <li className={location.pathname == "/banner/teacher" || location.pathname == "/category/2" || location.pathname.indexOf("/board/2") >= 0 ? "show" : "hide"}>
                            <button type="button" onClick={()=>toggleItem(5,"/banner/teacher")}><FontAwesomeIcon icon={faPersonChalkboard} /> 선생님 맞춤 자료</button>             
                        </li>
                        <li className={location.pathname.indexOf("/customer")>=0 ? "show" : "hide"}>
                            <button type="button" onClick={()=>toggleItem(6,"/customer/notice")}><FontAwesomeIcon icon={faHeadset} /> 고객센터</button>            
                        </li>
                        <li className={location.pathname.indexOf("/setting")>=0 ? "show" : "hide"}>
                            <button type="button" onClick={()=>toggleItem(7,"/setting/top")}><FontAwesomeIcon icon={faGears} /> 운영관리</button>
                        </li>
                    </ul>                
                    <button type="button" onClick={()=>logout()} className="logout"><FontAwesomeIcon icon={faRightFromBracket} /> 로그아웃</button>
                </header>        
            </div>                
            <div className="menu">
                <h1><img src={logo} height="40"/></h1>
                
                <dl>
                    {localStorage.getItem("grade") == '0' ? 
                    <>
                    <dt>회원</dt>
                    <dd className={location.pathname.indexOf("/member") >= 0 ? "show" : "hide"}>
                        <button type="button" ><FontAwesomeIcon icon={faUserLarge} /> 회원관리</button>
                        <ul>
                            <li className={location.pathname.indexOf("/member/0") >= 0 ? 'active' : ''}><Link to="/member/0">회원목록</Link></li>
                            <li className={location.pathname.indexOf("/member/1") >= 0 ? 'active' : ''}><Link to="/member/1">탈퇴회원</Link></li>
                            <li className={location.pathname.indexOf("/member_prev/1") >= 0 ? 'active' : ''}><Link to="/member_prev/1">이전회원</Link></li>
                        </ul>               
                    </dd>
                    </>
                    :
                    <></>
                    }
                    <dt>교과/수업</dt>
                    <dd className={location.pathname.indexOf("/curriculum") >= 0 ? "show" : "hide"}>
                        <button type="button" ><FontAwesomeIcon icon={faBookOpen} /> 교과관리</button>
                        <ul>
                            <li className={location.pathname.indexOf("/curriculum/native") >= 0 ? 'active' : ''}><Link to="/curriculum/native">국어자료</Link></li>
                            <li className={location.pathname.indexOf("/curriculum/society") >= 0 ? 'active' : ''}><Link to="/curriculum/society">사회자료</Link></li>
                            <li className={location.pathname.indexOf("/curriculum/art") >= 0 ? 'active' : ''}><Link to="/curriculum/art">미술자료</Link></li>
                            <li className={location.pathname == '/curriculum/set1' ? 'active' : ''}><Link to="/curriculum/set1">개정연도설정</Link></li>
                            <li className={location.pathname == '/curriculum/set2' ? 'active' : ''}><Link to="/curriculum/set2">교과설정</Link></li>
                            <li className={location.pathname == '/curriculum/set3' ? 'active' : ''}><Link to="/curriculum/set3">단원설정</Link></li>
                            <li className={location.pathname == '/curriculum/datatype' ? 'active' : ''}><Link to="/curriculum/datatype">자료유형설정</Link></li>
                            <li className={location.pathname == '/curriculum/excel' ? 'active' : ''}><Link to="/curriculum/excel">엑셀 업로드</Link></li>
                        </ul>               
                    </dd>
                    <dd className={location.pathname.indexOf("/class")>=0 || location.pathname.indexOf("/banner/class") >= 0 ? "show" : "hide"}>
                        <button type="button" ><FontAwesomeIcon icon={faSchool} /> 수업 혁신</button>
                        <ul>
                            <li className={location.pathname == ("/banner/class")  ? 'active' : ''}><Link to="/banner/class">메인 배너 관리</Link></li>
                            <li className={location.pathname.indexOf("/class/month") >= 0 ? 'active' : ''}><Link to="/class/month">이달의 수업</Link></li>
                            <li className={location.pathname.indexOf("/class/dictionary") >= 0 ? 'active' : ''}><Link to="/class/dictionary">수업고민사전</Link></li>
                            <li className={location.pathname == ("/class/pdf")  ? 'active' : ''}><Link to="/class/pdf">수업고민사전 PDF관리</Link></li>
                            <li className={location.pathname == ("/class/unit")  ? 'active' : ''}><Link to="/class/unit">수업고민사전 단원관리</Link></li>
                            <li className={location.pathname == '/class/excel' ? 'active' : ''}><Link to="/class/excel">수업고민사전 엑셀 업로드</Link></li>
                        </ul>               
                    </dd>
                    <dd className={location.pathname == ("/banner/creative") || location.pathname.indexOf("/category/0") >= 0 || location.pathname.indexOf("/board/0") >= 0 ? "show" : "hide"}>
                        <button type="button" ><FontAwesomeIcon icon={faRobot} /> 창의적 체험 활동</button>
                        <ul>
                            <li className={location.pathname.indexOf("/banner/creative") >= 0 ? 'active' : ''}><Link to="/banner/creative">메인 배너 관리</Link></li>
                            {props?.submenu?.map((val, index) => 
                                (val.type == 0) && (<li className={location.pathname.indexOf("/board/0/"+val.categorySeq+"/") >= 0 ? 'active' : ''}><Link to={"/board/0/"+val.categorySeq+"/"}>{val.name}</Link></li>)
                            )}
             
                            <li className={location.pathname == ("/category/0")  ? 'active' : ''}><Link to="/category/0">카테고리 관리</Link></li>
                        </ul>               
                    </dd>
                    <dd className={location.pathname == ("/category/1")  || location.pathname.indexOf("/board/1") >= 0  ? "show" : "hide"}>
                        <button type="button" ><FontAwesomeIcon icon={faPersonChalkboard} /> 수업연계도서</button>
                        <ul>
                            {props?.submenu?.map((val, index) => 
                                (val.type == 1) && (<li className={location.pathname.indexOf("/board/1/"+val.categorySeq+"/") >= 0 ? 'active' : ''}><Link to={"/board/1/"+val.categorySeq+"/"}>{val.name}</Link></li>)
                            )}
                            <li className={location.pathname == ("/category/1")  ? 'active' : ''}><Link to="/category/1">카테고리 관리</Link></li>
                        </ul>               
                    </dd>
                    <dd className={location.pathname==("/banner/teacher") || location.pathname==("/category/2")  || location.pathname.indexOf("/board/2") >= 0   ? "show" : "hide"}>
                        <button type="button" ><FontAwesomeIcon icon={faPersonChalkboard} /> 선생님 맞춤 자료</button>
                        <ul>
                            <li className={location.pathname==("/banner/teacher") ? 'active' : ''}><Link to="/banner/teacher">메인 배너 관리</Link></li>
                            {props?.submenu?.map((val, index) => 
                                (val.type == 2) && (<li className={location.pathname.indexOf("/board/2/"+val.categorySeq+"/") >= 0 ? 'active' : ''}><Link to={"/board/2/"+val.categorySeq+"/"}>{val.name}</Link></li>)
                            )}
                            <li className={location.pathname==("/category/2")  ? 'active' : ''}><Link to="/category/2">카테고리 관리</Link></li>
                        </ul>               
                    </dd>
                    <dt>운영/관리</dt>
                    <dd className={ location.pathname.indexOf("/customer")>=0  ? "show" : "hide"}>
                        <button type="button" ><FontAwesomeIcon icon={faHeadset} /> 고객센터</button>
                        <ul>
                            <li className={location.pathname.indexOf("/customer/notice") >= 0 ? 'active' : ''}><Link to="/customer/notice">공지사항</Link></li>
                            <li className={location.pathname.indexOf("/customer/alarm") >= 0 ? 'active' : ''}><Link to="/customer/alarm">업데이트 알림</Link></li>
                            <li className={location.pathname.indexOf("/customer/event") >= 0 ? 'active' : ''}><Link to="/customer/event">이벤트</Link></li>
                            <li className={location.pathname.indexOf("/customer/qna") >= 0 ? 'active' : ''}><Link to="/customer/qna">문의관리<small>{props?.qna_count }</small></Link></li>
                            <li className={location.pathname.indexOf("/customer/faq") >= 0 ? 'active' : ''}><Link to="/customer/faq">FAQ</Link></li>
                            <li className={location.pathname=="/customer/category" ? 'active' : ''}><Link to="/customer/category">FAQ분류관리</Link></li>
                        </ul>               
                    </dd>
                    <dd className={location.pathname.indexOf("/setting")>=0 ? "show" : "hide"}>
                        <button type="button" ><FontAwesomeIcon icon={faGears} /> 운영관리</button>.
                        <ul>
                            <li className={location.pathname=="/setting/top" ? 'active' : ''}><Link to="/setting/top">최상단띠배너</Link></li>
                            <li className={location.pathname=="/setting/main" ? 'active' : ''}><Link to="/setting/main">메인 배너 관리</Link></li>
                            <li className={location.pathname=="/setting/bottom" ? 'active' : ''}><Link to="/setting/bottom">하단 배너 관리</Link></li>
                            <li className={location.pathname=="/setting/popup" ? 'active' : ''}><Link to="/setting/popup">팝업관리</Link></li>
                            <li className={location.pathname=="/setting/agree" ? 'active' : ''}><Link to="/setting/agree">약관/강연,대관링크</Link></li>
                            <li className={location.pathname=="/setting/manager" ? 'active' : ''}><Link to="/setting/manager">관리자비밀번호</Link></li>
                        </ul>               
                    </dd>
                </dl>
            </div>
        </div>
    );
  }
  
  export default Menu;
  