import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";

import * as api from "../../config/api"

function DictionaryExcel() {
  
    const user = useSelector((state) => state.user);

    const [file, setFile] = useState("");
    const [upfile, setUpFile] = useState([]);
    const [list, setList] = useState([]);
    const onFileUpload = (e) => {

        const {files} = e.target;
        const uploadFile = files[0];

        setFile(uploadFile.name);
        setUpFile(uploadFile);
        const formData = new FormData();

        formData.append("excel",uploadFile);
        formData.append("type",0);

        api.fileUpload("/admin/classexcel/upload",formData,user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){

            } else {
                if(res?.data){
                    setList(res.data);
                }
            }
        });
    
    }
    const onSave = () => {
        const formData = new FormData();
        formData.append("excel",upfile);
        formData.append("type",1);

        api.fileUpload("/admin/classexcel/upload",formData,user.token).then(res=>{
            setList([]);
            setFile("");
            setUpFile([]);
        });
    }
    
    const onFileChange = (e) => {
        setList([]);
        setFile("");
        setUpFile([]);
        e.target.value=null;
    }



    return (
        <div className="normal">
           
            <div>
                <h1 className="head _02">
                    <div className="filebox">
                        <label>
                            <input type="file" onChange={(e)=>{onFileUpload(e)}} onClick={(e)=>onFileChange(e)} />
                            <span>엑셀파일 업로드</span>
                        </label>
                        <p>{file}</p>
                    </div>                     
                </h1>    
                <div className="excel_scroll">
                    <table className="detail">
                        <thead>
                            <tr>
                                <th rowspan="2">용어명</th>
                                <th rowspan="2">교과이름</th>
                                <th rowspan="2">파일명</th>
                                <th rowspan="2">노출여부</th>
                                <th className="left" colspan="7">과목선택</th>
                                <th className="left" colspan="5">영역</th>
                            </tr>
                            <tr>
                                <th className="left">국어 : 중학교1<br/>사회 : 통합사회<br/>미술 : </th>
                                <th className="left">국어 : 중학교2<br/>사회 : 사회와문화<br/>미술 : </th>
                                <th className="left">국어 : 중학교3<br/>사회 : <br/>미술 : </th>
                                <th className="left">국어 : 공통국어<br/>사회 : <br/>미술 : </th>
                                <th className="left">국어 : 문학<br/>사회 : <br/>미술 : </th>
                                <th className="left">국어 : 독서와작문<br/>사회 : <br/>미술 : </th>
                                <th className="left">국어 : 화법과언어<br/>사회 : <br/>미술 : </th>
                                <th className="left">국어 : 문학<br/>사회 : 일반사회<br/>미술 : 인물</th>
                                <th className="left">국어 : 문법<br/>사회 : 윤리<br/>미술 : 용어</th>
                                <th className="left">국어 : 듣기,말하기<br/>사회 : 지리<br/>미술 : </th>
                                <th className="left">국어 : 읽기,쓰기<br/>사회 : <br/>미술 : </th>
                                <th className="left">국어 : 매체<br/>사회 : <br/>미술 : </th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map(val=>
                            <tr>
                                <td>{val.name}</td>
                                <td>{val.subject}</td>
                                <td>{val.add_file}</td>
                                <td>{val.view=='1' ? '노출' : '미노출'}</td>
                                <td>{val.subject0=='1' ? <span className="o">●</span> : ''}</td>
                                <td>{val.subject1=='1' ? <span className="o">●</span> : ''}</td>
                                <td>{val.subject2=='1' ? <span className="o">●</span> : ''}</td>
                                <td>{val.subject3=='1' ? <span className="o">●</span> : ''}</td>
                                <td>{val.subject4=='1' ? <span className="o">●</span> : ''}</td>
                                <td>{val.subject5=='1' ? <span className="o">●</span> : ''}</td>
                                <td>{val.subject6=='1' ? <span className="o">●</span> : ''}</td>
                                <td>{val.type0=='1' ? <span className="o">●</span> : ''}</td>
                                <td>{val.type1=='1' ? <span className="o">●</span> : ''}</td>
                                <td>{val.type2=='1' ? <span className="o">●</span> : ''}</td>
                                <td>{val.type3=='1' ? <span className="o">●</span> : ''}</td>
                                <td>{val.type4=='1' ? <span className="o">●</span> : ''}</td>
                            </tr>
                            )}

                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="20">
                                    <button type="button" className="btn-primary" onClick={()=>onSave()}>저장</button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>   
                </div>              
            </div>
        </div>
    );
}

export default DictionaryExcel;