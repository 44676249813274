import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { clearUser } from "../redux/userSlice.js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpDown } from "@fortawesome/free-solid-svg-icons";

import ImageAdd from "./ImageAdd";
import * as api from "../config/api"

function Banner(props) {
    const { type } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    //유저정보
    const user = useSelector((state) => state.user);    


    const [list, setList] = useState([ ]);
    const [detail, setDetail] = useState([ ]);


    
    const dragItem = useRef(); // 드래그할 아이템의 인덱스
    const dragOverItem = useRef(); // 드랍할 위치의 아이템의 인덱스

    // 드래그 시작될 때 실행
    const dragStart = (e, position,seq) => {
        dragItem.current = position;

    };

    // 드래그중인 대상이 위로 포개졌을 때
    const dragEnter = (e, position,seq) => {
        dragOverItem.current = position;
   
    };

    // 드랍 (커서 뗐을 때)
    const drop = (val,seq) => {
       const startSort = dragOverItem.current;
        const endSort = dragItem.current;
        const newList = [...list];
        const dragItemValue = newList[dragItem.current];
        newList.splice(dragItem.current, 1);       
        newList.splice(dragOverItem.current, 0, dragItemValue);
        dragItem.current = null;
        dragOverItem.current = null;
        if(startSort != endSort){
            setList(
                newList.map(val =>
                    val.seq === seq ? { ...val, seq: "move" } : val
                )
            );
            //드래그 순간 name이 같아져서 셀렉트 박스 체크 풀림 이동시 move처리 놓는 순간 다시 자기 번호로 변경
            setTimeout(()=>{
                setList(
                    newList.map(val =>
                        val.seq === seq ? { ...val, seq: seq } : val
                    )
                );
            },100)
        }
    };

    let title = [
        {
            seq:3,
            type:"class",
            title:"수업 혁신"
        },
        {
            seq:0,
            type:"creative",
            title:"창의적 체험 활동"

        },
        {
            seq:2,
            type:"teacher",
            title:"선생님 맞춤 자료"
        }
    ];

    const find = title.find(par=> par.type == type);

    useEffect(()=>{

        api.getData("/admin/banner",{
            type:find.seq
        },user.token).then(res=>{
     

            if(res && res?.data?.status==='SUCCESS'){
                console.log("aaaa");
                const ret = res.data.data
                setDetail(ret);
                setList([{
                    seq:1,
                    url:ret.url1,
                    banner:ret.banner1,
                    bannerOri:ret.banner1Ori,
                    width:920,
                    height:640
                },{
                    seq:2,
                    url:ret.url2,
                    banner:ret.banner2,
                    bannerOri:ret.banner2Ori,
                    width:920,
                    height:640
                },{
                    seq:3,
                    url:ret.url3,
                    banner:ret.banner3,
                    bannerOri:ret.banner3Ori,
                    width:800,
                    height:800
                },{
                    seq:4,
                    url:ret.url4,
                    banner:ret.banner4,
                    bannerOri:ret.banner4Ori,
                    width:800,
                    height:800
                },{
                    seq:5,
                    url:ret.url5,
                    banner:ret.banner5,
                    bannerOri:ret.banner5Ori,
                    width:800,
                    height:800
                }])
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });
    },[type]);

    const onSave = () => {
        const formData = new FormData();

        formData.append("bannerSeq",detail?.bannerSeq);
        formData.append("type",detail?.type);
        formData.append("banner_img1",list[0].banner_file);
        formData.append("banner_img2",list[1].banner_file);
        formData.append("banner_img3",list[2].banner_file);
        formData.append("banner_img4",list[3].banner_file);
        formData.append("banner_img5",list[4].banner_file);

        formData.append("banner1",list[0].banner);
        formData.append("banner2",list[1].banner);
        formData.append("banner3",list[2].banner);
        formData.append("banner4",list[3].banner);
        formData.append("banner5",list[4].banner);
        
        formData.append("url1",list[0].url);
        formData.append("url2",list[1].url);
        formData.append("url3",list[2].url);
        formData.append("url4",list[3].url);
        formData.append("url5",list[4].url);
        formData.append("banner1Ori",list[0].bannerOri);
        formData.append("banner2Ori",list[1].bannerOri);
        formData.append("banner3Ori",list[2].bannerOri);
        formData.append("banner4Ori",list[3].bannerOri);
        formData.append("banner5Ori",list[4].bannerOri);

        api.fileUpload("/admin/banner",formData,user.token).then(res=>{
            window.alert("저장이 완료되었습니다.");
        });

    }

    const changeValue = (e,seq) => {
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, url: e.target.value } : val
            )
        );
    }

    const oncImageUpload = (e,seq) => {
  
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, banner_file: e } : val
            )
        );  
    }
    const onRemove = (seq) =>{
        setList(
            list.map(val =>
                val.seq === seq ? { ...val, banner: '',url:'',bannerOri:'' } : val
            )
        );  
    }
    return (
        <div className="banner" >
            <h1 className="head">{find.title} 배너 관리<button type="button" className="btn-primary" onClick={(e)=>onSave()}>저장</button></h1>
            <ul>
                {list.map((val, index) => (
                <li
                    key={index}
                    draggable
                    onDragStart={(e) => dragStart(e, index)}
                    onDragEnter={(e) => dragEnter(e, index)}
                    onDragEnd={(e) => drop(val, val.seq)}
                    onDragOver={(e) => e.preventDefault()}
                >
                    <i><FontAwesomeIcon icon={faUpDown} /></i>
                  
                    <ImageAdd
                        form_thumbnail={val.banner}
                        setFormThumbnail={(e)=>{oncImageUpload(e,val.seq)}} 
                        form_orithumbnail={val.bannerOri} 
                        width={val.width} 
                        height={val.height}
                        resize={val.width == 920 ? 5 : 6}
                        id={type ? type : -1}
                        folder="banner"
                    />
                    <p>
                        <h2>배너 {val.width} * {val.height}</h2>
                        <input type="text" placeholder="연결할 URL을 입력하세요." value={val.url} onChange={(e)=>changeValue(e,val.seq)}/>
                        <div>
                           <button type="button" className="btn-danger" onClick={(e)=>onRemove(val.seq)}>삭제</button>
                        </div>
                    </p>
                </li>
                ))}
            </ul>
        </div>
    );
}

export default Banner;