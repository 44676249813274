import React, {useState, useEffect} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import * as api from "../../config/api"
import Pagination from 'react-js-pagination';
import FileAdd from "../../component/FileAdd";

function Alarm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    //유저정보
    const user = useSelector((state) => state.user);

    //리스트 
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(20);

    //등록
    const [form_category, setFormCategory] = useState("");
    const [form_school, setFormSchool] = useState("중학");
    const [form_comment, setFormComment] = useState("");
    const [form_title, setFormTitle] = useState("");
    const [form_link, setFormLink] = useState("");
    const [form_file, setFormFile] = useState("");
    const [form_date, setFomDate] = useState("");
    
    const  onList = (page) => {

        api.getData("/update",{
            page:page-1,
            size:limit,
            school:""
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);   
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });

    }

    const onSubmit = () => {
        if(!form_school){
            window.alert("학급을 선택해주세요");
        }
        const formData = new FormData();
        if(id > 0 ){
            formData.append("updateSeq",id);
        }
        formData.append("school",form_school);
        formData.append("category",form_category);
        formData.append("title",form_title);
        formData.append("comment",form_comment);
        formData.append("link",form_link);
        formData.append("files",form_file);


        api.fileUpload("/admin/customer/update",formData,user.token).then(res=>{
            if(res && res.data.status==="SUCCESS"){
                window.alert("저장이 완료되었습니다.");
                goList();
            }
        });


    
    }
    const onRemove = () =>{
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/customer/update",{
                id:id
            },user.token).then(res=>{
                if(res.data.status == "SUCCESS"){
                    goList();
                }
            });
        }
    }
    const goList = () => {
        navigate("/customer/alarm"); 
    }
    useEffect(() => {    
        if(id == 0){            
            setFormCategory("");
            setFormSchool("");
            setFormComment("");
            setFormTitle("");
            setFormFile("");
            setFormLink("");
            setFomDate("");
        }
        if(location?.state?.updateSeq > 0){
            setFormTitle(location.state.title);
            setFormLink(location.state.link);
            setFormCategory(location.state.category);
            setFormSchool(location.state.school);
            setFormFile(location.state.file);
            setFormComment(location.state.comment);
            setFomDate(location.state.registDate);
        }else{
            onList(1)
        }
    }, [id]);

    return (
        <div className="normal">
            <div className={id==null ? "show" : "hide"}>
                <h1 className="head">업데이트 알림<Link to="/customer/alarm/0" className="btn-info btn-small">등록하기</Link></h1>          
                  <table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>등록일</th>
                            <th>학급</th>
                            <th>제목</th>
                            <th>링크</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((val,index)=>                     
                        <tr>
                            <td>{(total-index-(page*limit))}</td>
                            <td>{val.registDate.substr(0,16)}</td>
                            <td>{val.school}</td>
                            <td><Link to={"/customer/alarm/"+val.updateSeq} state={val}>{val.title}</Link></td>
                            <td><a className="link" href={val.link} target="_blank">{val.link}</a></td>
                        </tr>
                        )}

                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4">
                            <div className="paging">
                                    <Pagination
                                        activePage={page+1}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={10}
                                        prevPageText={"◀"}
                                        nextPageText={"▶"}
                                        onChange={onList}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className={id>=0 ? "show" : "hide"}>
                <h1 className="head">업데이트 알림 상세보기
                    {id > 0 && (
                        <button type="text" className="btn-danger btn-small" onClick={()=>onRemove()}>삭제하기</button>
                    )}
                </h1>
                <table className="detail">
                    <colgroup>
                        <col width="15%"/>
                        <col width="85%"/>
                    </colgroup>
                    <tbody>
                        {id > 0 && 
                        <tr>
                            <th>등록일시</th>
                            <td>{form_date}</td>
                        </tr>
                        }
                        <tr>
                            <th>학급</th>
                            <td>
                                <select onChange={(e)=>setFormSchool(e.target.value)}>
                                    <option value="" selected={form_school===''}>학급을 선택해주세요.</option>
                                    <option value="중학" selected={form_school==='중학'}>중학</option>
                                    <option value="고등" selected={form_school==='고등'}>고등</option>
                                </select>
                            </td>
                        </tr>
                        {/*
                        <tr>
                            <th>카테고리</th>
                            <td><input type="text" placeholder="카테고리를 입력하세요" value={form_category} onChange={(e)=>setFormCategory(e.target.value)}/></td>
                        </tr>
                        */}
                        <tr>
                            <th>제목</th>
                            <td><input type="text" placeholder="제목을 입력하세요" value={form_title} onChange={(e)=>setFormTitle(e.target.value)}/></td>
                        </tr>
                        {/*
                        <tr>
                            <th>메모</th>
                            <td><input type="text" placeholder="메모를 입력하세요" value={form_comment} onChange={(e)=>setFormComment(e.target.value)}/></td>
                        </tr>
                        */}
                        <tr>
                            <th>링크</th>
                            <td><input type="text" placeholder="링크를 입력하세요" value={form_link} onChange={(e)=>setFormLink(e.target.value)}/></td>
                        </tr>
                       
                        <tr>
                            <th>청부파일</th>
                            <td><FileAdd form_file={form_file} setFormFile={setFormFile} form_orifile={location?.state?.fileOri} id={id ? id : -1} /></td>
                        </tr>
                  
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2"><button type="button" className="btn-primary" onClick={()=>onSubmit()}>{id > 0 ? '수정' : '저장'}</button></td>
                        </tr>
                    </tfoot>                    
                </table>
            </div>
        </div>
    );
}

export default Alarm;