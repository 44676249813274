import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import FileAdd from "../../component/FileAdd";
import * as api from "../../config/api"
import Pagination from 'react-js-pagination';

function DataForm(props) {
    
    const { type, id } = useParams();
    let title={native:"국어",society:"사회",art:"미술"};
    //유저정보
    const user = useSelector((state) => state.user);
    //리스트 
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(20);

    //검색
    const today = new Date();    
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [search_text, setSearchText] = useState();
    const [search_view, setSearchView] = useState(-1);

    //등록
    const [detail, setDetail] = useState();

    const [year_list, setYearList] = useState([]);   
    const [subject_list, setSubjectList] = useState([]);
    const [unit_list, setUnitList] = useState([]);
    const [unit_level_list, setUnitLevelList] = useState([]);
    const [type_list, setTypeList] = useState([]);
    const [type_level_list, setTypeLevelList] = useState([]);


    const navigate = useNavigate();
    const location = useLocation();



    const onRemove = (seq) => {
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/curriculum", {
                id:seq
            },user.token).then(res=>{
                //setList(list.filter(val => val.seq !== seq));
                navigate(-1);

                
            });
        }
    } 

    const onSave = (seq) => {
        
            const formData = new FormData();
    
            if(detail?.curriculumSeq > 0 ){
                formData.append("curriculumSeq",detail?.curriculumSeq);
            }
            formData.append("subject",detail.subject);
            formData.append("school",detail.school);
            formData.append("subjectSeq",detail.subjectSeq);
            formData.append("yearSeq",detail.yearSeq ? detail.yearSeq : 0);
            formData.append("unitSeq",detail.unitSeq ? detail.unitSeq : 0);
            formData.append("unitLevelSeq",detail.unitLevelSeq ? detail.unitLevelSeq : 0);
            formData.append("typeSeq",detail.typeSeq ? detail.typeSeq : 0);
            formData.append("typeLevelSeq",detail.typeLevelSeq ? detail.typeLevelSeq : 0);
            formData.append("view",detail.view);
            formData.append("files",detail.file);
            formData.append("name",detail.name);
            formData.append("type1",(detail.type1 ? 1 : 0));
            formData.append("type2",(detail.type2 ? 1 : 0));
            formData.append("type3",(detail.type3 ? 1 : 0));
            formData.append("type4",(detail.type4 ? 1 : 0));

            formData.append("yearText",year_list.find(par => par.seq==detail?.yearSeq)?.year);
            formData.append("subjectText",subject_list.find(par => par.subjectSeq==detail?.subjectSeq)?.name);
            formData.append("unitText",unit_list.find(par => par.unitSeq==detail?.unitSeq)?.name);
            formData.append("unitLevelText",unit_level_list.find(par => par.unitLevelSeq==detail?.unitLevelSeq)?.name);
            formData.append("typeText",type_list.find(par => par.typeSeq==detail?.typeSeq)?.name);
            formData.append("typeLevelText",type_level_list.find(par => par.typeLevelSeq==detail?.typeLevelSeq)?.name);

            api.fileUpload("/admin/curriculum",formData,user.token).then(res=>{
                if(res && res?.code === 'ERR_NETWORK'){
                    window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                    navigate("/")
                    return;
                }
                navigate("/curriculum/"+type); 
            });
           
        
    } 
        
    const onSchool = (val) => {
       setDetail({...detail, school:val})
    } 
    const onYear = (val) => {
        setDetail({...detail, yearSeq:val})
    } 
    const onSubject = (val) => {
        setDetail({...detail, subjectSeq:val})
    } 
    const onUnit = (val) => {
        setDetail({...detail, unitSeq:val})
        api.getData("/admin/curriculum/unit/level", {
            id:val
        },user.token).then(res=>{
            if(res && res.data.status == "SUCCESS"){
                setUnitLevelList(res.data.data); 
            }
        });        
    }
    const onType = (val) => {
        setDetail({...detail, typeSeq:val})
        api.getData("/admin/curriculum/type/level", {
            id:val
        },user.token).then(res=>{
            if(res && res.data.status == "SUCCESS"){
                setTypeLevelList(res.data.data); 
            }
        });        
    }


    const params = new URLSearchParams(location.search);
    const onBoard = () => {
        //navigate(-1);
        navigate("/curriculum/"+type , {state:{page:params.get("page")}}); 
    } 

    useEffect(() => {
        
        setStartDate("");
        setEndDate("");
        setSearchText("");
        setSearchView("");

        api.getData("/curriculum/year", {
        }).then(res=>{
            if(res && res.data.status==='SUCCESS'){
                setYearList(res.data.data);
            }
        });
        api.getData("/admin/curriculum/subject", {
        },user.token).then(res=>{
            if(res && res.data.status==='SUCCESS'){
                setSubjectList(res.data.data);
            }
        });
        api.getData("/admin/curriculum/unit", {
        },user.token).then(res=>{
            if(res && res.data.status==='SUCCESS'){
                setUnitList(res.data.data);
            }
        });
        api.getData("/curriculum/type", {
        },user.token).then(res=>{
            if(res && res.data.status==='SUCCESS'){
                setTypeList(res.data.data);
            }
        });

   
        if(id > 0){
            onType(location?.state.typeSeq);
            onUnit(location?.state.unitSeq);
            setDetail(location?.state);

        }else{
            if(type === "native"){
                setDetail({...detail, subject:"국어"})
            } else if(type === "society"){
                setDetail({...detail, subject:"사회"})
            } else if(type === "art"){
                setDetail({...detail, subject:"미술"})
            }
        }
    }, [type, id]);

    return (
        <div className="normal">
           
            <div >
                <h1 className="head">{title[type]}자료 상세보기
                    {id > 0 && (<button type="text" className="btn-danger btn-small" onClick={()=>onRemove(id)}>삭제하기</button>)}
                </h1>                
                <table className="detail">
                    <colgroup>
                        <col width="15%"/>
                        <col width="85%"/>
                    </colgroup>
                    <tbody>
                        {detail?.curriculumSeq > 0 && 
                        <tr>
                            <th>등록일시</th>
                            <td>{detail?.registDate}</td>
                        </tr>
                        }
                        <tr>
                            <th>학교급/교과</th>
                            <td className="flex">
                                <select onChange={(e)=>onSchool(e.target.value)} value={detail?.school}>
                                    <option value="">학교급을 선택해주세요</option>
                                    <option value="초등">초등</option>
                                    <option value="중학">중학</option>
                                    <option value="고등">고등</option>
                                    <option value="인정">인정</option>
                                </select>
                                <select onChange={(e)=>onYear(e.target.value)} value={detail?.yearSeq}>
                                    <option value="">개정연도를 선택해주세요</option>
                                    {year_list.map((val)=>(
                                        ( val.subject == detail?.subject && val.school == detail?.school) && (
                                        <option value={val.seq}>{val.year}년</option>
                                        )
                                    ))}
                                </select>
                                <select onChange={(e)=>onSubject(e.target.value)} value={detail?.subjectSeq}>
                                    <option value="">교과를 선택해주세요</option>
                                    {subject_list.map((val)=>(
                                        (val.subject == detail?.subject && val.school == detail?.school && val.yearSeq == detail?.yearSeq) && (
                                        <option value={val.subjectSeq} >{val.name}</option>
                                        )
                                    ))}                                
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>단원</th>
                            <td  className="flex">
                                <select onChange={(e)=>onUnit(e.target.value)} value={detail?.unitSeq}>
                                    <option value="">단원을 선택해주세요</option>
                                    {unit_list.map((val)=>(
                                        (val.subject == detail?.subject && val.school == detail?.school && val.yearSeq == detail?.yearSeq && val.subjectSeq == detail?.subjectSeq) && (
                                            <option value={val.unitSeq}>{val.name}</option>
                                        )
                                    ))}
                                </select>
                                <select onChange={(e)=>setDetail({...detail, unitLevelSeq: e.target.value})} value={detail?.unitLevelSeq}>
                                    <option value="">소단원을 선택해주세요</option>
                                    {unit_level_list.map((val)=>(
                                        <option value={val.unitLevelSeq}>{val.name}</option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>자료유형</th>
                            <td  className="flex">
                                <select onChange={(e)=>onType(e.target.value)} value={detail?.typeSeq}>
                                    <option value="">수업자료를 선택해주세요</option>
                                    {type_list.map((val)=>(
                                        <option value={val.typeSeq}>{val.name}</option>
                                    ))}
                                </select>
                                <select onChange={(e)=>setDetail({...detail, typeLevelSeq: e.target.value})} value={detail?.typeLevelSeq}>
                                    <option value="">교과서를 선택해주세요</option>
                                    {type_level_list.map((val)=>(
                                        <option value={val.typeLevelSeq}>{val.name}</option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>자료명</th>
                            <td><input type="text" onChange={(e)=>setDetail({...detail, name:e.target.value})} value={detail?.name}/></td>
                        </tr>
                        <tr>
                            <th>자료 업로드</th>
                            <td>
                                <div className="filebox">
                                    <FileAdd form_file={detail?.file} setFormFile={(e)=>setDetail({...detail, file:e})} form_orifile={detail?.fileOri}  id={id ? id : -1}/> 
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>자료 구분</th>
                            <td>
                                <label className="checkbox"><input type="checkbox" onClick={(e)=>setDetail({...detail, type1:e.target.checked})} checked={detail?.type1} /><span>단원공통자료</span></label>
                                <label className="checkbox"><input type="checkbox" onClick={(e)=>setDetail({...detail, type2:e.target.checked})} checked={detail?.type2}  /><span>교과서</span></label>
                                <label className="checkbox"><input type="checkbox" onClick={(e)=>setDetail({...detail, type3:e.target.checked})} checked={detail?.type3}  /><span>지도서</span></label>
                                <label className="checkbox"><input type="checkbox" onClick={(e)=>setDetail({...detail, type4:e.target.checked})} checked={detail?.type4}  /><span>중간/기말평가</span></label>
                            </td>
                        </tr>
                        <tr>
                            <th>게재 여부</th>
                            <td>
                                <label className="radiobox"><input type="radio" name="a" onChange={(e)=>setDetail({...detail, view:1})} checked={detail?.view===1} /><span>노출</span></label>
                                <label className="radiobox"><input type="radio" name="a" onChange={(e)=>setDetail({...detail, view:0})} checked={detail?.view===0} /><span>비노출</span></label>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2">
                                <button type="button" className="btn-off" onClick={()=>onBoard()}>목록보기</button>
                                <button type="button" className="btn-primary" onClick={()=>onSave(id)}>저장</button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

export default DataForm;