import React, {Component,useEffect, useMemo, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import Editor from "../../component/Editor";
import ImageAdd from "../../component/ImageAdd";
import FileAdd from "../../component/FileAdd";
import * as api from "../../config/api"
import Pagination from 'react-js-pagination';
import ExcelDownload from '../../component/ExcelDownload'

function Month() {
    const { id } = useParams();
    //유저정보
    const user = useSelector((state) => state.user);
    const location = useLocation();
    const navigate = useNavigate();
    //리스트 
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(1);
    const [limit, setLimit] = useState(20);

    //검색
    let [search, setSearch] = useState({startDate:"",endDate:"",view:-1,school:"",subject:"",month:""});
    

    const onStartDate = (date) => {
        setSearch(
            {
                ...search, startDate: (date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : '')
            }
        );
    }
    const onEndDate = (date) => {
        setSearch(
            {
                ...search, endDate: (date ? date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + date.getDate()).slice(-2) : '')
            }
        );
    }


    const searchReset = () => {
        setSearch({startDate:"",endDate:"",view:-1,school:"",subject:"",month:""});
        search.month = "";
        search.subject = "";
        search.startDate = "";
        search.endDate = "";
        search.school = "";
        search.view=-1;
        onList(1);
    }

    const  onList = (page) => {
        api.getData("/admin/innovation",{
            page:page-1,
            size:limit,
            sdate:search.startDate,
            edate:search.endDate,
            view:(search.view == -1 ? '' : search.view),
            school:search.school,
            subject:search.subject,
            month:search.month
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }

            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data.content);
                setPage(page-1);
                setTotal(res.data.data.totalElements);   
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });
    }

    
    const params = new URLSearchParams(location.search);
    const goList = () => {
        navigate("/class/month")
    }
    useEffect(() => {
        onList(location?.state?.page ? location?.state?.page : 1);            
    }, []);


    return (
        <div className="normal">
            <div >
                <h1 className="head">이달의 수업<Link to="/class/month/0" state={null} className="btn-info btn-small">등록하기</Link></h1>        
                <div className="search" name="searc" method="get">
                    <form>
                        <div className="wrap_search">
                            <div className="search_group">
                                <label className="title">등록일</label>
                                <div className="box_search">
                                    <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={search.startDate} onChange={(date) => onStartDate(date)} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>
                                    <span className="box_wave">~</span>
                                    <div className="date"><DatePicker dateFormat="yyyy-MM-dd" selected={search.endDate} onChange={(date) => onEndDate(date)} /><i><FontAwesomeIcon icon={faCalendarDays} /></i></div>                                
                                </div>
                            </div>
                            <div className="search_group">
                                <label className="title">과목</label>
                                <div className="box_search">
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, subject:""})} checked={search.subject==""} /><span>전체</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, subject:"국어"})} checked={search.subject=="국어"}/><span>국어</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, subject:"사회"})} checked={search.subject=="사회"}/><span>사회</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, subject:"미술"})} checked={search.subject=="미술"}/><span>미술</span></label>
                                </div>
                            </div>
                            <div className="search_group">
                                <label className="title">학년</label>
                                <div className="box_search">
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, school:""})} checked={search.school==""} /><span>전체</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, school:"중학"})} checked={search.school=="중학"}  /><span>중학</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, school:"고등"})} checked={search.school=="고등"}  /><span>고등</span></label>
                                </div>
                            </div>
                            <div className="search_group">
                                <label className="title">월별</label>
                                <div className="box_search">
                                    <select onChange={(e)=>setSearch({...search, month:e.target.value})} value={search.month}>
                                        <option value="">전체</option>
                                        <option value="1">1월</option>
                                        <option value="2">2월</option>
                                        <option value="3">3월</option>
                                        <option value="4">4월</option>
                                        <option value="5">5월</option>
                                        <option value="6">6월</option>
                                        <option value="7">7월</option>
                                        <option value="8">8월</option>
                                        <option value="9">9월</option>
                                        <option value="10">10월</option>
                                        <option value="11">11월</option>
                                        <option value="12">12월</option>
                                    </select>
                                </div>
                            </div>
                            <div className="search_group">
                                <label className="title">게재여부</label>
                                <div className="box_search">
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, view:-1})} checked={search.view==-1} /><span>전체</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, view:1})} checked={search.view==1}  /><span>노출</span></label>
                                    <label className="radiobox"><input type="radio" onClick={(e)=>setSearch({...search, view:0})} checked={search.view==0}  /><span>비노출</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="reset" className="btn-off" onClick={(e)=>searchReset()}>초기화</button>
                            <button type="button" className="btn-primary" onClick={(e)=>onList(1)}>검색</button>
                        </div>            
                    </form>
                </div>
                <h1 className="head">목록<span>검색결과<strong className="text-danger">{list.length}</strong>개</span>
                {/*
                <ExcelDownload  
                    thead={['고유번호','등록일','과목','학년','월','제목']} 
                    api="/innovation/excel" 
                    name="이달의 수업" 
                    get={{status:0}} 
                />  */}               
                </h1>        
                <table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>등록일</th>
                            <th>과목</th>
                            <th>학년</th>
                            <th>월</th>
                            <th>제목</th>
                            <th>게재여부</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((val,index)=>                     
                        <tr>
                            <td>{(total-index-(page*limit))}</td>
                            <td>{val.registDate?.substr(0,16)}</td>
                            <td>{val.subject}</td>
                            <td>{val.school}</td>
                            <td>{val.month}월</td>
                            <td><Link to={"/class/month/"+val.innovationSeq+"?page="+(page+1)} state={val}>{val.title ? val.title : '제목 작성 안함'}</Link></td>
                            <td><strong className={val.view ? 'o' : 'x'}>{val.view ? '●' : 'x'}</strong></td>
                        </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="10">
                                <div className="paging">
                                    <Pagination
                                        activePage={page+1}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={10}
                                        prevPageText={"◀"}
                                        nextPageText={"▶"}
                                        onChange={onList}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
   
        </div>
    );
}

export default Month;